import React from 'react';
import i18n from 'i18next';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import PeopleIcon from '@material-ui/icons/People';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import EventNoteIcon from '@material-ui/icons/EventNote';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ErrorIcon from '@material-ui/icons/Error';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import TodayIcon from '@material-ui/icons/Today';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import SettingsIcon from '@material-ui/icons/Settings';
import BarChartIcon from '@material-ui/icons/BarChart';
import ElderTable from 'components/ElderTable';
import ElderDetails from 'components/ElderDetails';
import RestaurantTable from 'components/RestaurantTable';
import RestaurantDetails from 'components/RestaurantDetails';
import Order from 'views/Admin/Order/Order';
import IssueCenterBadge from 'components/IssueCenterBadge';
import OrderBadge from 'components/OrderBadge';
import AbandonedOrderBadge from 'components/AbandonedOrderBadge';
import TodayOrders from 'views/Admin/TodayOrders/TodayOrders';
// import RestaurantOwnerTable from 'components/RestaurantOwnerTable';
// import RestaurantOwnerDetails from 'components/RestaurantOwnerDetails';
import RestaurantMeals from 'views/Admin/RestaurantMeals/RestaurantMeals';
import Dashboard from 'views/FacilityAdmin/Dashboard/Dashboard';
import MealStatistics from 'views/FacilityAdmin/MealStatistics/MealStatistics';
import Reports from 'views/Admin/Reports/Reports';
import AuditTrailTable from 'components/AuditTrailTable';

// custom
import DeliveryStaffTable from './components/DeliveryStaffTable';
import DeliveryStaffDetails from './components/DeliveryStaffDetails';
import OrderTable from './components/OrderTable';
import RecurringOrderTable from 'components/RecurringOrderTable';
import FormTable from './components/Forms/FormTable';
import Schedule from './components/Schedule';
import DeliveryDiary from 'views/Admin/DeliveryDiary/DeliveryDiary';
import IssueCenter from './components/IssueCenter';
import FacilitySettings from './components/FacilitySettings';
import ElderDeliveryGroupBoard from 'components/DeliveryGroup/ElderDeliveryGroupBoard';
import cache from 'utilities/cache';
// import ApiDocs from 'views/Admin/ApiDocs/ApiDocs';

export default [
  {
    title: i18n.t('總覽'),
    icon: DashboardIcon,
    paths: [{ path: '/dashboard', exact: true }],
    component: Dashboard,
  },
  {
    title: i18n.t('今日訂單'),
    icon: TodayIcon,
    paths: [{ path: '/todayOrders', exact: true }],
    component: TodayOrders,
  },
  {
    title: i18n.t('送餐大使'),
    icon: MotorcycleIcon,
    paths: [{ path: '/deliveryStaffs', exact: true }],
    component: DeliveryStaffTable,
  },
  {
    title: `${i18n.t('送餐大使')}頁面`,
    paths: [{ path: '/deliveryStaff/:id', exact: true }],
    component: DeliveryStaffDetails,
    hideFromMenu: true,
  },
  {
    title: i18n.t('送餐對象'),
    icon: PeopleIcon,
    paths: [{ path: '/elders', exact: true }],
    component: ElderTable,
  },
  {
    title: `${i18n.t('送餐對象')}頁面`,
    paths: [{ path: '/elder/:id', exact: true }],
    component: ElderDetails,
    hideFromMenu: true,
  },
  {
    title: i18n.t('送餐群組'),
    icon: FormatListNumberedIcon,
    paths: [{ path: '/deliveryGroupBoard', exact: true }],
    component: ElderDeliveryGroupBoard,
  },
  {
    title: i18n.t('餐廳'),
    icon: RestaurantIcon,
    paths: [{ path: '/restaurants', exact: true }],
    component: RestaurantTable,
  },
  {
    title: `${i18n.t('餐廳')}頁面`,
    paths: [{ path: '/restaurant/:id', exact: true }],
    component: RestaurantDetails,
    hideFromMenu: true,
  },
  // {
  //   title: '餐廳負責人',
  //   icon: AssignmentIndIcon,
  //   paths: [{ path: '/restaurantOwners', exact: true }],
  //   component: RestaurantOwnerTable,
  // },
  // {
  //   title: '餐廳負責人頁面',
  //   paths: [{ path: '/restaurantOwner/:id', exact: true }],
  //   component: RestaurantOwnerDetails,
  //   hideFromMenu: true,
  // },
  {
    title: i18n.t('餐點'),
    icon: EmojiFoodBeverageIcon,
    paths: [{ path: '/restaurantMeals', exact: true }],
    component: RestaurantMeals,
  },
  {
    title: '訂單',
    icon: ListAltIcon,
    paths: [{ path: '/orders', exact: true }],
    component: OrderTable,
  },
  {
    title: '餐點統計',
    icon: BarChartIcon,
    paths: [{ path: '/mealStatistics', exact: true }],
    component: MealStatistics,
    demoOnly: false,
  },
  {
    title: '週期性排單',
    icon: LibraryAddIcon,
    paths: [{ path: '/recurringOrders', exact: true }],
    component: RecurringOrderTable,
  },
  {
    title: '訂單頁面',
    icon: ListAltIcon,
    paths: [{ path: '/order/:id', exact: true }],
    component: Order,
    hideFromMenu: true,
  },
  {
    title: i18n.t('行程表'),
    icon: EventNoteIcon,
    paths: [{ path: '/schedule', exact: true }],
    component: Schedule,
  },
  {
    title: i18n.t('送餐關懷'),
    icon: FavoriteBorderIcon,
    paths: [{ path: '/deliveryDiary', exact: true }],
    component(...args) {
      return <DeliveryDiary {...args} clientId={cache.get('app:facilityId')} />;
    },
  },
  {
    title: '應變中心',
    icon: ErrorIcon,
    paths: [{ path: '/issueCenter', exact: true }],
    component: IssueCenter,
    badge: IssueCenterBadge,
  },
  {
    title: '新訂單',
    icon: ListAltIcon,
    paths: [{ path: '/newOrders', exact: true }],
    component: () => <OrderTable queryStatus={'waitingForDeliveryStaff'} defaultIndex={1} />,
    badge: OrderBadge,
  },
  {
    title: '棄單',
    icon: SmsFailedIcon,
    paths: [{ path: '/abandonedOrders', exact: true }],
    component: () => <OrderTable queryStatus={'reMatchingDeliveryStaff'} defaultIndex={1} />,
    badge: AbandonedOrderBadge,
  },
  {
    title: '報表',
    icon: AssessmentIcon,
    paths: [{ path: '/reports', exact: true }],
    component: Reports,
  },
  {
    title: '表單',
    icon: AssignmentTurnedInIcon,
    paths: [{ path: '/forms', exact: true }],
    component: FormTable,
  },
  {
    title: '使用紀錄',
    icon: RemoveFromQueueIcon,
    paths: [{ path: '/auditTrails', exact: true }],
    component: AuditTrailTable,
  },
  {
    title: '帳號設定',
    icon: SettingsIcon,
    paths: [{ path: '/settings', exact: true }],
    component: FacilitySettings,
  },
];
