const schema = {
  'type': 'object',
  'required': [
    'status', 'name', 'category',
  ],
  'properties': {
    'status': {
      'type': 'string',
      'title': '狀態',
      'enum': ['使用中', '已停用'],
      'enumNames': ['使用中', '已停用'],
      'default': '使用中',
    },
    'category': {
      'type': 'string',
      'title': '類別',
      'enum': ['送餐對象'],
      'enumNames': ['送餐對象'],
      'default': '送餐對象',
    },
    'name': {
      'type': 'string',
      'title': '名稱',
    },
    'note': {
      'type': ['string', 'null'],
      'title': '備註',
    },
  },
};

export default schema;
