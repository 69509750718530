import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toastr } from 'react-redux-toastr';

export default function FileUploader({
  s3Prefix,
  s3Key: inS3Key,
  onUpdateS3Key,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    async onDrop(newFiles) {
      global.logger.debug('onDrop', newFiles);

      if (newFiles.length !== 1) return;
      setIsLoading(true);
      const file = newFiles[0];

      const s3Key = `${s3Prefix}/${file.name}`;
      try {
        await Storage.put(s3Key, file, {
          contentType: file.type,
        });
        setFile({
          s3Key,
          url: URL.createObjectURL(file),
        });
        onUpdateS3Key && onUpdateS3Key(s3Key);
      } catch (e) {
        console.error(e);
        toastr.error('檔案上傳失敗');
      }
      setIsLoading(false);
    },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024,
    accept: 'application/pdf',
  });

  const onRemoveFile = async (s3Key) => {
    setIsLoading(true);
    await Storage.remove(s3Key);
    setFile();
    onUpdateS3Key && onUpdateS3Key();
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (inS3Key) {
        const file = {
          s3Key: inS3Key,
          url: await Storage.get(inS3Key),
        };
        setFile(file);
      }
    })();
  }, [inS3Key]);

  const fileName = file?.s3Key?.split('/').pop();

  return (
    <Grid container spacing={2}>
      {!file &&
      <Grid item xs={12}>
        <section style={{ border: '1px dotted black', paddingLeft: 16, marginTop: 16, cursor: 'pointer', borderRadius: 5, textAlign: 'center' }}>
          <div {...getRootProps()}>
            <input {...getInputProps()} multiple={false} disabled={isLoading} />
            {isLoading && <p><CircularProgress color="primary" size={24} /></p>}
            {!isLoading && <p>點此或拖曳上傳檔案(10MB以內)</p>}
          </div>
        </section>
      </Grid>}
      {fileName &&
      <Grid item xs={12} style={{ marginLeft: 10 }}>
        {fileName}
        <IconButton
          aria-label="close"
          onClick={() => onRemoveFile(file.s3Key)}
          disabled={isLoading}
        >
          <HighlightOffIcon />
        </IconButton>
      </Grid>}
    </Grid>
  );
}

FileUploader.propTypes = {
  s3Prefix: PropTypes.string,
  s3Key: PropTypes.string,
  onUpdateS3Key: PropTypes.func,
};
