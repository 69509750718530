import JSZip from 'jszip';
import { download } from 'utilities/file';
import ExcelJS from 'exceljs';
import { sortBy } from 'utilities/sorting';
import { formatAddress } from 'utilities/format';
import { buildColumnsArray, getROCYear, convertDateToZhFormat } from './helpers';
import { toastr } from 'react-redux-toastr';
import moment from 'moment-timezone';

const COUNTY = '彰化縣';

const deliveryStaffFeePerOrder = 125;

export default async function writeChanghua({
  elders: allElders,
  orders: allOrders,
  deliveryStaffs,
  selectedMonth: selectedYearMonth = 'YYYY-MM',
  clientName,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
  applyDefaultDeliveryStaff = false,
  weeksOfMonth,
}) {
  const elders = allElders.filter(({ address }) => address.county === COUNTY);
  // const elders = allElders; // test
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let periodOrders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    periodOrders = periodOrders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }
  const orders = periodOrders.filter(({ date }) => date.startsWith(selectedYearMonth));

  const [selectedYear, selectedMonth] = selectedYearMonth.split('-');
  const ROCYear = parseInt(selectedYear) - 1911;

  const zip = new JSZip();

  const workbookDeliveryStaff = new ExcelJS.Workbook();
  const worksheet = workbookDeliveryStaff.addWorksheet(`${ROCYear}.${selectedMonth}`);
  getWorksheetDeliveryStaff(worksheet, deliveryStaffs, orders, elders, selectedYearMonth, selectedMonth, applyDefaultDeliveryStaff);
  try {
    if (workbookDeliveryStaff && workbookDeliveryStaff.worksheets && workbookDeliveryStaff.worksheets.length > 0) {
      const bufferDataByDistrict = await workbookDeliveryStaff.xlsx.writeBuffer();
      zip.file(`${ROCYear}${selectedMonth}送餐人員配對名冊.xlsx`, bufferDataByDistrict, { binary: true });
    }
  } catch (e) {
    console.error(e);
  }

  const workbookElderStatistics = new ExcelJS.Workbook();
  const worksheetElderStatistics = workbookElderStatistics.addWorksheet(`${ROCYear}${selectedMonth}人數人次統計表`);
  const firstDayOfMonth = moment(selectedYearMonth, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
  const lastDayOfMonth = moment(selectedYearMonth, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
  getWorksheetElderStatistics(worksheetElderStatistics, orders, elders, firstDayOfMonth, lastDayOfMonth);
  weeksOfMonth.forEach(({ start, end }) => {
    const [startYear, startMonth, startDay] = start.split('-');
    const [, endMonth, endDay] = end.split('-');
    const ROCYear = parseInt(startYear) - 1911;
    const ordersInPeriod = periodOrders.filter(({ date }) => date >= start && date <= end);
    const worksheetElderStatistics = workbookElderStatistics.addWorksheet(`${ROCYear}${startMonth}${startDay}-${endMonth}${endDay}人數人次統計表`);
    getWorksheetElderStatistics(worksheetElderStatistics, ordersInPeriod, elders, start, end);
  });
  try {
    if (workbookElderStatistics && workbookElderStatistics.worksheets && workbookElderStatistics.worksheets.length > 0) {
      const bufferDataByDistrict = await workbookElderStatistics.xlsx.writeBuffer();
      zip.file(`${ROCYear}${selectedMonth}人數人次統計表.xlsx`, bufferDataByDistrict, { binary: true });
    }
  } catch (e) {
    console.error(e);
  }

  const files = Object.keys(zip.files);
  if (files.length !== 0) {
    const zipContent = zip.generate({ type: 'blob' });
    download(`${clientName}__${selectedYearMonth}核銷報表.zip`, zipContent);
  }
}

function getWorksheetDeliveryStaff(worksheet, deliveryStaffs = [], orders = [], elders, yearMonth, selectedMonth, applyDefaultDeliveryStaff) {
  const deliveryStaffRows = [];
  deliveryStaffs
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(({
      id,
      name,
      identificationCardId,
      address,
      birthday,
    }) => {
      const deliveryStaffOrders = orders
        .filter(({ deliveryStaffId, elderId }) => {
          if (applyDefaultDeliveryStaff) {
            const elder = elders.find(({ id }) => id === elderId);
            if (elder.defaultDeliveryStaffId) {
              return elder.defaultDeliveryStaffId === id;
            }
          }
          return deliveryStaffId === id;
        });
      if (deliveryStaffOrders.length === 0) {
        return;
      }
      const lunchs = [];
      const dinners = [];
      const elderNames = [];
      deliveryStaffOrders.sort(sortBy('deliveryBy')).forEach(({ mealSlot, date, elderId }) => {
        const elder = elders.find(({ id }) => id === elderId);
        const day = date.split('-')[2];
        if (mealSlot === 'lunch') {
          if (!lunchs.includes(day)) {
            lunchs.push(day);
          }
        } else {
          if (!dinners.includes(day)) {
            dinners.push(day);
          }
        }
        if (!elderNames.includes(elder.name)) {
          elderNames.push(elder.name);
        }
      });
      deliveryStaffRows.push({
        姓名: name,
        身分證字號: identificationCardId,
        生日: birthday ? convertDateToZhFormat(birthday) : '',
        戶籍地址: formatAddress(address, { includeZipCode: false }),
        服務日期: { lunchs, dinners },
        送餐個案: elderNames.sort((a, b) => a > b ? 1 : -1),
      });
    });

  const fontName = 'Calibri';
  const range = 'A:Z';
  const columnArray = buildColumnsArray(range);

  const ROCYear = getROCYear(yearMonth);

  let rowIndex = 1;

  const columnWidth = [9, 10, 16, 14, 30, 40, 40, 9, 9, 9, 16, 16, 30];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  const defaultBorder = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

  let cell;
  worksheet.mergeCells(`A${rowIndex}:M${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = `${ROCYear}年度營養餐飲服務-----營養餐飲人員配對名冊 ${selectedMonth} 月份-午、晚餐`;
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 12, name: fontName };
  rowIndex += 1;

  ['編號', '送餐人員\n姓名', '送餐人員\n身分證字號', '送餐人員\n出生年月日', '送餐人員\n戶籍地址', '送餐日期(午餐)', '送餐日期(晚餐)',
    '(午餐次數)', '(晚餐次數)', '午餐+晚餐次數', `補助當月\n次數*${deliveryStaffFeePerOrder}元`, '送餐人員用印', '送餐個案']
    .forEach((header, index) => {
      cell = worksheet.getCell(`${columnArray[index]}${rowIndex}`);
      cell.value = header;
      cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
      cell.font = { size: 12, name: fontName };
      cell.border = defaultBorder;
      worksheet.getRow(rowIndex).height = 80;
    });
  rowIndex += 1;

  deliveryStaffRows.forEach((deliveryStaff, index) => {
    cell = worksheet.getCell(`A${rowIndex}`);
    cell.value = index + 1;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`B${rowIndex}`);
    cell.value = deliveryStaff['姓名'];
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`C${rowIndex}`);
    cell.value = deliveryStaff['身分證字號'];
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`D${rowIndex}`);
    cell.value = deliveryStaff['生日'];
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`E${rowIndex}`);
    cell.value = deliveryStaff['戶籍地址'];
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`F${rowIndex}`);
    cell.value = deliveryStaff['服務日期'].lunchs.join('.');
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`G${rowIndex}`);
    cell.value = deliveryStaff['服務日期'].dinners.join('.');
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`H${rowIndex}`);
    cell.value = deliveryStaff['服務日期'].lunchs.length;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`I${rowIndex}`);
    cell.value = deliveryStaff['服務日期'].dinners.length;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`J${rowIndex}`);
    cell.value = { formula: `=SUM(H${rowIndex}:I${rowIndex})` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`K${rowIndex}`);
    cell.value = { formula: `=J${rowIndex}*${deliveryStaffFeePerOrder}` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`L${rowIndex}`);
    cell.value = '';
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`M${rowIndex}`);
    cell.value = deliveryStaff['送餐個案'].join('、');
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    worksheet.getRow(rowIndex).height = 80;
    rowIndex += 1;
  });

  worksheet.mergeCells(`A${rowIndex}:G${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = '總計';
  cell.alignment = { horizontal: 'right', vertical: 'middle' };
  cell.font = { size: 28, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`H${rowIndex}`);
  cell.value = deliveryStaffRows.length ? { formula: `=SUM(H3:H${deliveryStaffRows.length + 2})` } : '';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`I${rowIndex}`);
  cell.value = deliveryStaffRows.length ? { formula: `=SUM(I3:I${deliveryStaffRows.length + 2})` } : '';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`J${rowIndex}`);
  cell.value = deliveryStaffRows.length ? { formula: `=SUM(J3:J${deliveryStaffRows.length + 2})` } : '';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`K${rowIndex}`);
  cell.value = deliveryStaffRows.length ? { formula: `=SUM(K3:K${deliveryStaffRows.length + 2})` } : '';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`L${rowIndex}`);
  cell.value = '';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`M${rowIndex}`);
  cell.value = '';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:D${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = `製表人:`;
  cell.alignment = { horizontal: 'left', vertical: 'middle' };
  cell.font = { size: 12, name: fontName, color: { argb: 'F72020' } };
  cell.border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  worksheet.mergeCells(`E${rowIndex}:F${rowIndex}`);
  cell = worksheet.getCell(`E${rowIndex}`);
  cell.value = `會計:`;
  cell.alignment = { horizontal: 'left', vertical: 'middle' };
  cell.font = { size: 12, name: fontName, color: { argb: 'F72020' } };
  cell.border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  worksheet.mergeCells(`G${rowIndex}:J${rowIndex}`);
  cell = worksheet.getCell(`G${rowIndex}`);
  cell.value = `單位負責人:`;
  cell.alignment = { horizontal: 'left', vertical: 'middle' };
  cell.font = { size: 12, name: fontName, color: { argb: 'F72020' } };
  cell.border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  worksheet.mergeCells(`K${rowIndex}:M${rowIndex}`);
  cell = worksheet.getCell(`K${rowIndex}`);
  cell.value = `團體名稱:`;
  cell.alignment = { horizontal: 'left', vertical: 'middle' };
  cell.font = { size: 12, name: fontName, color: { argb: 'F72020' } };
  cell.border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  worksheet.getRow(rowIndex).height = 80;

  return worksheet;
}

function getWorksheetElderStatistics(worksheet, orders = [], elders, start, end) {
  const disadvantagedType = ['低收入戶', '中低收', '一般戶'];
  const cmsLevels = ['2級', '3級', '4級', '5級', '6級', '7級', '8級'];
  const ordersByDisadvantaged = {
    '低收入戶': [],
    '中低收': [],
    '一般戶': [],
  };
  const districts = [];
  orders.forEach((order) => {
    let type = '一般戶';
    const elder = elders.find((elder) => elder.id === order.elderId);
    if (!elder) {
      console.log(`can not find elder ${order.elderId} for order ${order.id}`);
      return;
    }
    if (!districts.includes(elder.address.district)) {
      districts.push(elder.address.district);
    }

    const { disadvantagedTypesSlot } = elder;
    if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
      const slot = disadvantagedTypesSlot.find(({ start, end }) => order.date >= start && order.date < end);
      if (slot) {
        type = slot.type;
      }
    }
    // TODO: 先容錯，之後加設定
    if (['低收', '長照低收'].includes(type)) type = '低收入戶';
    if (['長照中低收'].includes(type)) type = '中低收';
    if (['一般'].includes(type)) type = '一般戶';
    if (!ordersByDisadvantaged[type]) {
      toastr.error(`${elder.name} 的身份別 "${type}" 無法辨識`);
      return;
    }
    ordersByDisadvantaged[type].push({ ...order, elder });
  });

  const fontName = 'Calibri';
  const range = 'A:BZ';
  const columnArray = buildColumnsArray(range);

  let rowIndex = 1;

  const columnWidth = [9, 7,
    3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 6, 6,
    3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 6, 6,
    3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 6, 6,
    12, 12];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  const defaultBorder = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

  let cell;
  worksheet.mergeCells(`A${rowIndex}:K${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  const [startYear, startMonth, startDay] = start.split('-');
  const [endYear, endMonth, endDay] = end.split('-');
  const ROCStartYear = parseInt(startYear) - 1911;
  const ROCEndYear = parseInt(endYear) - 1911;
  cell.value = `時間：${ROCStartYear}年${startMonth}月${startDay}日-${ROCEndYear}年${endMonth}月${endDay}日`;
  cell.alignment = { horizontal: 'left', vertical: 'middle' };
  cell.font = { size: 12, name: fontName };

  rowIndex = 3;
  worksheet.mergeCells(`A${rowIndex}:A${rowIndex + 3}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = '鄉鎮';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 12, name: fontName };
  cell.border = defaultBorder;
  worksheet.mergeCells(`B${rowIndex}:B${rowIndex + 3}`);
  cell = worksheet.getCell(`B${rowIndex}`);
  cell.value = '項目';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 12, name: fontName };
  cell.border = defaultBorder;
  let columnIndex = 2;
  disadvantagedType.forEach((type) => {
    let currentRowIndex = rowIndex;
    worksheet.mergeCells(`${columnArray[columnIndex]}${currentRowIndex}:${columnArray[columnIndex + 13]}${currentRowIndex}`);
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = type;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`${columnArray[columnIndex + 14]}${currentRowIndex}`);
    cell.border = defaultBorder;
    cell = worksheet.getCell(`${columnArray[columnIndex + 15]}${currentRowIndex}`);
    cell.border = defaultBorder;
    currentRowIndex += 1;

    cmsLevels.forEach((cmsLevel, index) => {
      const offset = index * 2;
      worksheet.mergeCells(`${columnArray[columnIndex + offset]}${currentRowIndex}:${columnArray[columnIndex + offset + 1]}${currentRowIndex}`);
      cell = worksheet.getCell(`${columnArray[columnIndex + offset]}${currentRowIndex}`);
      cell.value = cmsLevel;
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 12, name: fontName };
      cell.border = defaultBorder;
    });
    cell = worksheet.getCell(`${columnArray[columnIndex + 14]}${currentRowIndex}`);
    cell.border = defaultBorder;
    cell = worksheet.getCell(`${columnArray[columnIndex + 15]}${currentRowIndex}`);
    cell.border = defaultBorder;
    currentRowIndex += 1;

    let currentColumnIndex = columnIndex;
    [{ label: '輕度', width: 4 }, { label: '中度', width: 6 }, { label: '重度', width: 4 }].forEach(({ label, width }) => {
      worksheet.mergeCells(`${columnArray[currentColumnIndex]}${currentRowIndex}:${columnArray[currentColumnIndex + width - 1]}${currentRowIndex}`);
      cell = worksheet.getCell(`${columnArray[currentColumnIndex]}${currentRowIndex}`);
      cell.value = label;
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 12, name: fontName };
      cell.border = defaultBorder;
      currentColumnIndex += width;
    });
    cell = worksheet.getCell(`${columnArray[columnIndex + 14]}${currentRowIndex}`);
    cell.border = defaultBorder;
    cell = worksheet.getCell(`${columnArray[columnIndex + 15]}${currentRowIndex}`);
    cell.border = defaultBorder;
    currentRowIndex += 1;

    currentColumnIndex = columnIndex;
    [
      { label: '男', width: 2 }, { label: '女', width: 2 },
      { label: '男', width: 3 }, { label: '女', width: 3 },
      { label: '男', width: 2 }, { label: '女', width: 2 },
      { label: '男', width: 1 }, { label: '女', width: 1 },
    ].forEach(({ label, width }) => {
      if (width > 1) {
        worksheet.mergeCells(`${columnArray[currentColumnIndex]}${currentRowIndex}:${columnArray[currentColumnIndex + width - 1]}${currentRowIndex}`);
      }
      cell = worksheet.getCell(`${columnArray[currentColumnIndex]}${currentRowIndex}`);
      cell.value = label;
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 12, name: fontName };
      cell.border = defaultBorder;
      currentColumnIndex += width;
    });
    currentRowIndex += 1;

    columnIndex += 16;
  });

  let currentRowIndex = rowIndex;
  cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
  cell.border = defaultBorder;
  cell = worksheet.getCell(`${columnArray[columnIndex + 1]}${currentRowIndex}`);
  cell.border = defaultBorder;
  currentRowIndex += 1;
  cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
  cell.border = defaultBorder;
  cell = worksheet.getCell(`${columnArray[columnIndex + 1]}${currentRowIndex}`);
  cell.border = defaultBorder;
  currentRowIndex += 1;
  cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
  cell.border = defaultBorder;
  cell = worksheet.getCell(`${columnArray[columnIndex + 1]}${currentRowIndex}`);
  cell.border = defaultBorder;
  currentRowIndex += 1;
  cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
  cell.value = '總和';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 12, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`${columnArray[columnIndex + 1]}${currentRowIndex}`);
  cell.border = defaultBorder;
  rowIndex += 4;

  const total = {
    '人數': [],
    '人次': [],
    '午餐': [],
    '晚餐': [],
  };
  districts.sort().forEach((district) => {
    currentRowIndex = rowIndex;
    worksheet.mergeCells(`A${currentRowIndex}:A${currentRowIndex + 3}`);
    cell = worksheet.getCell(`A${currentRowIndex}`);
    cell.value = district;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    ['人數', '人次', '午餐', '晚餐'].forEach((label, index) => {
      cell = worksheet.getCell(`B${currentRowIndex + index}`);
      cell.value = label;
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 12, name: fontName };
      cell.border = defaultBorder;
    });
    columnIndex = 2;
    const cmsLevelSeverity = [['2級', '3級'], ['4級', '5級', '6級'], ['7級', '8級']];
    const mergedCells = [2, 2, 3, 3, 2, 2];
    disadvantagedType.forEach((type) => {
      let index = 0;
      cmsLevelSeverity.forEach((cmsLevels) => {
        ['male', 'female'].forEach((gender) => {
          const orders = ordersByDisadvantaged[type].filter(({ elder }) => {
            return elder.address.district === district &&
              cmsLevels.includes(elder.cmsLevel) &&
              elder.gender === gender;
          });
          let currentRowIndex = rowIndex;
          ['人數', '人次', '午餐', '晚餐'].forEach((content) => {
            worksheet.mergeCells(`${columnArray[columnIndex]}${currentRowIndex}:${columnArray[columnIndex + mergedCells[index] - 1]}${currentRowIndex}`);
            cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
            cell.value = undefined;
            if (orders.length !== 0) {
              switch (content) {
                case '人數': {
                  const uniqueElderIdsSet = new Set();
                  orders.forEach(({ elderId }) => uniqueElderIdsSet.add(elderId));
                  cell.value = uniqueElderIdsSet.size || undefined;
                  break;
                }
                case '人次':
                  cell.value = orders.length;
                  break;
                case '午餐':
                  cell.value = orders.filter(({ mealSlot }) => mealSlot === 'lunch').length || undefined;
                  break;
                case '晚餐':
                  cell.value = orders.filter(({ mealSlot }) => mealSlot === 'dinner').length || undefined;
                  break;
              }
            }
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
            cell.font = { size: 12, name: fontName };
            cell.border = defaultBorder;
            currentRowIndex += 1;
          });
          columnIndex += mergedCells[index];
          index += 1;
        });
      });

      currentRowIndex = rowIndex;
      ['人數', '人次', '午餐', '晚餐'].forEach((content) => {
        // 男總和
        cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
        cell.value = {
          formula: `=${columnArray[columnIndex - 14]}${currentRowIndex} + \
${columnArray[columnIndex - 10]}${currentRowIndex} + \
${columnArray[columnIndex - 4]}${currentRowIndex}`,
        };
        cell.border = defaultBorder;
        total[content].push(`${columnArray[columnIndex]}${currentRowIndex}`);
        // 女總和
        cell = worksheet.getCell(`${columnArray[columnIndex + 1]}${currentRowIndex}`);
        cell.value = {
          formula: `=${columnArray[columnIndex - 12]}${currentRowIndex} + \
${columnArray[columnIndex - 7]}${currentRowIndex} + \
${columnArray[columnIndex - 2]}${currentRowIndex}`,
        };
        cell.border = defaultBorder;
        total[content].push(`${columnArray[columnIndex + 1]}${currentRowIndex}`);
        currentRowIndex += 1;
      });
      columnIndex += 2;
    });

    rowIndex += 4;
  });

  if (districts.length > 0) {
    // column AY and AZ
    rowIndex = 7;

    cell = worksheet.getCell(`AY${rowIndex}`);
    cell.value = '人數';
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`AZ${rowIndex}`);
    cell.value = { formula: `=${total['人數'].join('+')}` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    rowIndex += 1;

    cell = worksheet.getCell(`AY${rowIndex}`);
    cell.value = '人次';
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`AZ${rowIndex}`);
    cell.value = { formula: `=${total['人次'].join('+')}` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    rowIndex += 1;

    cell = worksheet.getCell(`AY${rowIndex}`);
    cell.value = '午餐人次';
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`AZ${rowIndex}`);
    cell.value = { formula: `=${total['午餐'].join('+')}` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    rowIndex += 1;

    cell = worksheet.getCell(`AY${rowIndex}`);
    cell.value = '晚餐人次';
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`AZ${rowIndex}`);
    cell.value = { formula: `=${total['晚餐'].join('+')}` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    rowIndex += 1;

    let restCellRowCount = (districts.length - 1) * 4;
    while (restCellRowCount > 0) {
      cell = worksheet.getCell(`AY${rowIndex}`);
      cell.border = defaultBorder;
      cell = worksheet.getCell(`AZ${rowIndex}`);
      cell.border = defaultBorder;
      rowIndex += 1;
      restCellRowCount -= 1;
    }
  }

  return worksheet;
}
