import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import { Document, Page } from 'react-pdf';
import { PDFDocument } from 'pdf-lib';
import fontkit from 'pdf-fontkit'; // https://github.com/Hopding/pdf-lib/issues/494#issuecomment-695818400

import {
  formatAddress,
  formatGender,
  formatBirthday,
} from 'utilities/format';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { toastr } from 'react-redux-toastr';

export default function InteractivePdfViewer({ fileS3Key, onSaveForm, elder }) {
  const [fileUrl, setFileUrl] = useState();
  const [annotations, setAnnotations] = useState({});
  const [pageDimensions, setPageDimensions] = useState({});
  const [numPages, setNumPages] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const renderPdf = async () => {
      if (!fileS3Key) return;

      const url = await Storage.get(fileS3Key);
      setFileUrl(url);
    };

    renderPdf();
  }, [fileS3Key]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPageLoadSuccess = (pageNumber, { width, height }) => {
    setPageDimensions((prev) => ({
      ...prev,
      [pageNumber]: { width, height },
    }));
  };

  const onGetAnnotationsSuccess = (pageNumber, annotationsOnPage) => {
    const filteredAnnotations = annotationsOnPage.filter(
      (annotation) => annotation.fieldType === 'Btn' || annotation.fieldType === 'Tx',
    );
    let initialValueMapping =[];
    if (elder) {
      initialValueMapping = [
        {
          fieldName: 'elderName',
          value: elder.name,
        },
        {
          fieldName: 'elderIdentificationCardId',
          value: elder.identificationCardId,
        },
        {
          fieldName: 'elderBirthday',
          value: formatBirthday(elder.birthday),
        },
        {
          fieldName: 'elderGender',
          value: formatGender(elder.gender),
        },
        {
          fieldName: 'elderPhoneNumber',
          value: elder.phoneNumber || '',
        },
        {
          fieldName: 'elderAddress',
          value: formatAddress(elder.address) || '',
        },
        {
          fieldName: 'elderEmergencyContactName',
          value: elder.emergencyContact?.name || '',
        },
        {
          fieldName: 'elderEmergencyContactRelationship',
          value: elder.emergencyContact?.relationship || '',
        },
        {
          fieldName: 'elderEmergencyContactPhoneNumber',
          value: elder.emergencyContact?.phoneNumber || '',
        },
      ];
    }

    const initializedAnnotations = filteredAnnotations.map((annotation) => {
      if (annotation.fieldType === 'Tx') {
        let value = annotation.fieldValue || '';
        if (elder) {
          const mapping = initialValueMapping.find(({ fieldName }) => fieldName === annotation.fieldName);
          if (mapping) {
            value = mapping.value;
          }
        }
        return {
          ...annotation,
          value,
        };
      } else {
        return {
          ...annotation,
          value: annotation.fieldValue !== 'Off',
        };
      }
    });
    setAnnotations((prevAnnotations) => ({
      ...prevAnnotations,
      [pageNumber]: initializedAnnotations,
    }));
  };

  const handleCheckboxChange = (pageNumber, id) => {
    setAnnotations((prev) => ({
      ...prev,
      [pageNumber]: prev[pageNumber].map((annotation) =>
        annotation.id === id ? { ...annotation, value: !annotation.value } : annotation,
      ),
    }));
  };

  const handleTextChange = (pageNumber, id, newValue) => {
    setAnnotations((prev) => ({
      ...prev,
      [pageNumber]: prev[pageNumber].map((annotation) =>
        annotation.id === id ? { ...annotation, value: newValue } : annotation,
      ),
    }));
  };

  const saveForm = async () => {
    setIsLoading(true);
    const existingPdfBytes = await fetch(fileUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();

    let fontBytes;
    try {
      const response = await fetch('/fonts/NotoSansTC-Regular.ttf');
      if (!response.ok) {
        throw new Error(`Failed to fetch font: ${response.status} ${response.statusText}`);
      }
      fontBytes = await response.arrayBuffer();
    } catch (e) {
      console.log(e);
      toastr.error('檔案儲存失敗，請檢查網路連線後再重新嘗試');
      setIsLoading(false);
      return;
    }
    pdfDoc.registerFontkit(fontkit);
    const font = await pdfDoc.embedFont(fontBytes, { subset: true });

    // https://github.com/Hopding/pdf-lib/issues/1152  workaround:  WinAnsi cannot encode....
    const rawUpdateFieldAppearances = form.updateFieldAppearances.bind(form);
    form.updateFieldAppearances = function () {
      return rawUpdateFieldAppearances(font);
    };

    Object.keys(annotations).forEach((key) => annotations[key].forEach((annotation) => {
      if (annotation.fieldType === 'Btn') {
        const field = form.getCheckBox(annotation.fieldName);
        if (annotation.value) {
          field.check();
        } else {
          field.uncheck();
        }
      } else if (annotation.fieldType === 'Tx') {
        const field = form.getTextField(annotation.fieldName);
        if (field) {
          field.setText(annotation.value || '');
        }
      }
    }));

    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    onSaveForm && await onSaveForm(blob);
    setIsLoading(false);
  };

  if (!fileUrl) {
    return <></>;
  }

  return (
    <div style={{
      marginTop: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      overflowX: 'auto',
    }}>
      <div style={{
        marginBottom: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
        <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {numPages &&
            Array.from({ length: numPages }, (_, index) => {
              const pageNumber = index + 1;
              const pageDimension = pageDimensions[pageNumber];
              return (
                <div key={pageNumber} style={{ position: "relative", margin: "20px 0", border: '1px solid black' }}>
                  <Page
                    pageNumber={pageNumber}
                    onLoadSuccess={(page) => onPageLoadSuccess(pageNumber, page)}
                    onGetAnnotationsSuccess={(annotationsOnPage) =>
                      onGetAnnotationsSuccess(pageNumber, annotationsOnPage)
                    }
                  />
                  {pageDimension && annotations[pageNumber]?.map((annotation) => {
                    const height = annotation.rect[3] - annotation.rect[1];
                    return (
                      <div
                        key={annotation.id}
                        style={{
                          position: 'absolute',
                          top: `${pageDimension.height - annotation.rect[3]}px`,
                          left: `${annotation.rect[0]}px`,
                          width: `${annotation.rect[2] - annotation.rect[0]}px`,
                          height: `${annotation.rect[3] - annotation.rect[1]}px`,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          zIndex: 100,
                        }}
                      >
                        {annotation.fieldType === 'Btn' ? (
                          // Checkbox
                          <input
                            type="checkbox"
                            checked={annotation.value}
                            onChange={() => handleCheckboxChange(pageNumber, annotation.id)}
                            style={{ width: '100%' }}
                          />
                        ) : annotation.fieldType === 'Tx' ? (
                          // Text input
                          <input
                            type="text"
                            value={annotation.value || ''}
                            onChange={(e) => handleTextChange(pageNumber, annotation.id, e.target.value)}
                            style={{
                              width: '100%',
                              height: '100%',
                              fontSize: height < 14 ? '8px' : (height < 16 ? '10px' : '12px'),
                              padding: '2px',
                            }}
                          />
                        ) : null}
                      </div>);
                  })}
                </div>
              );
            })}
        </Document>
      </div>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        style={{ marginTop: 16 }}
        onClick={saveForm}
        disabled={isLoading}
      >
        送出
      </Button>
    </div>
  );
}

InteractivePdfViewer.propTypes = {
  fileS3Key: PropTypes.string,
  onSaveForm: PropTypes.func,
  elder: PropTypes.object,
};
