import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { v1 as uuidv1 } from 'uuid';
import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import {
  createForm,
  updateForm,
} from 'graphql/mutations';
import uiSchema from './uiSchema';
import FileUploader from './FileUploader';
import cache from 'utilities/cache';
import { toastr } from 'react-redux-toastr';
import moment from 'moment-timezone';

export default function FormForm({ formData: inFormData, onComplete, ...props }) {
  // load here for translation purpose
  const { default: schema } = useMemo(() => require('./schema.js'), []);
  const [currentFileS3Key, setCurrentFileS3Key] = useState();
  const [newFileS3Key, setNewFileS3Key] = useState();
  const clientId = cache.get('app:facilityId');

  const createFunc = async (data) => {
    if (!newFileS3Key) {
      toastr.error('請選擇要上傳的檔案');
      throw new Error('abort');
    }

    data.id = uuidv1();
    data.clientId = clientId;
    data.fileS3Key = newFileS3Key;

    await request(createForm, { input: data });

    await onComplete(data);
  };

  const updateFunc = async (data) => {
    if (!newFileS3Key) {
      toastr.error('請選擇要上傳的檔案');
      throw new Error('abort');
    }

    data.fileS3Key = newFileS3Key;

    await request(updateForm, { input: data });

    await onComplete(data);
  };

  useEffect(() => {
    if (inFormData && inFormData.id) {
      setCurrentFileS3Key(inFormData.fileS3Key);
      setNewFileS3Key(inFormData.fileS3Key);
    }
  }, [inFormData]);

  const now = moment().toISOString();

  return (
    <div>
      <DataForm
        schema={schema}
        uiSchema={uiSchema}
        createFunc={createFunc}
        updateFunc={updateFunc}
        formData={inFormData}
        dirty={true}
        {...props}
      >
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          <FileUploader
            s3Prefix={`forms/${clientId}/${now}`}
            s3Key={currentFileS3Key}
            onUpdateS3Key={setNewFileS3Key}
          />
        </Grid>
      </DataForm>
    </div>
  );
}

FormForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
