import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import MohwPrsReport from './MohwPrsReport';
import RestaurantReport from './RestaurantReport';
import RestaurantReportByOrg from './RestaurantReportByOrg';
import PandagoReport from './PandagoReport';
import UberReport from './UberReport';
import LalamoveReport from './LalamoveReport';
import CountyPrsReport from './CountyPrsReport/CountyPrsReport';
import VulnerableReport from './VulnerableReport';
import YearlyReport from './YearlyReport/YearlyReport';
import ElderReceipt from './ElderReceipt/ElderReceipt';
import SelfPayingReceipt from './SelfPayingReceipt/SelfPayingReceipt';
import DeliveryStaffSalary from './DeliveryStaffSalary/DeliveryStaffSalary';
import AccountingSMS from './AccountingSMS';
import { sortBy } from 'utilities/sorting';
import cache from 'utilities/cache';
import { request, asyncListAll } from 'utilities/graph';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: '100%',
  },
}));

const reportFunctions = [
  {
    value: 'mohwPrsReport',
    component: MohwPrsReport,
  },
  {
    value: 'countyPrsReport',
    component: CountyPrsReport,
  },
  {
    value: 'vulnerableReport',
    component: VulnerableReport,
  },
  {
    value: 'pandagoReport',
    component: PandagoReport,
  },
  {
    value: 'uberReport',
    component: UberReport,
  },
  {
    value: 'lalamoveReport',
    component: LalamoveReport,
  },
  {
    value: 'restaurantReport',
    component: RestaurantReport,
  },
  {
    value: 'restaurantReportByOrg',
    component: RestaurantReportByOrg,
  },
  {
    value: 'yearlyReport',
    component: YearlyReport,
  },
  {
    value: 'elderReceipt',
    component: ElderReceipt,
  },
  {
    value: 'selfPayingReceipt',
    component: SelfPayingReceipt,
  },
  {
    value: 'deliveryStaffSalary',
    component: DeliveryStaffSalary,
  },
  {
    value: 'accountingSMS',
    component: AccountingSMS,
  },
];

export default function Reports() {
  const classes = useStyles();
  const [clientOptions, setClientOptions] = useState([]);
  const [enabledReportFunctions, setEnabledReportFunctions] = useState(reportFunctions);

  useEffect(() => {
    (async () => {
      const clientId = cache.get('app:facilityId');
      // const county = cache.get('app:location');
      if (clientId) {
        const { data: { getClient: client } } = await request( /* GraphQL */ `
          query GetClient($id: ID!) {
            getClient(id: $id) {
              id
              county
              name
              facilitySettings {
                dba
                govQualifiedPrice
                lunchStartTime
                lunchEndTime
                dinnerStartTime
                dinnerEndTime
                receiptSettings {
                  originalPrice
                  copaymentSettings {
                    disadvantagedType
                    disadvantagedLabel
                    copayment
                  }
                }
              }
              featureConfiguration {
                reportTypes
              }
            }
          }
        `, { id: clientId });
        if (client) {
          setEnabledReportFunctions(reportFunctions.filter((f) => {
            return !!client.featureConfiguration.reportTypes.find((reportType) => reportType === f.value);
          }));
          setClientOptions([client]);
        }
      } else {
        const clients = await asyncListAll(/* GraphQL */ `
          query GetClientsByTypeByCounty(
            $type: ClientType
            $county: ModelStringKeyConditionInput
            $sortDirection: ModelSortDirection
            $filter: ModelClientFilterInput
            $limit: Int
            $nextToken: String
          ) {
            getClientsByTypeByCounty(
              type: $type
              county: $county
              sortDirection: $sortDirection
              filter: $filter
              limit: $limit
              nextToken: $nextToken
            ) {
              items {
                id
                county
                name
                facilitySettings {
                  dba
                  govQualifiedPrice
                  lunchStartTime
                  lunchEndTime
                  dinnerStartTime
                  dinnerEndTime
                  receiptSettings {
                    originalPrice
                    copaymentSettings {
                      disadvantagedType
                      disadvantagedLabel
                      copayment
                    }
                  }
                }
              }
              nextToken
            }
          }
        `, {
          type: 'facility',
        });
        setClientOptions(clients.sort(sortBy('name')));
      }
    })();
  }, []);

  return (
    <Container component="main" maxWidth={false}>
      <Grid container spacing={2}>
        {clientOptions.length !== 0 && enabledReportFunctions.map((x, index)=>(
          <Grid item xs={12} md={6} xl={4} key={index}>
            <Paper className={classes.container}>
              {/* For fast-refresh use */}
              <x.component clientOptions={clientOptions} />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
