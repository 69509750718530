import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import { tag as allTags } from '@silvergatedelivery/constants';
import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import {
  getElder,
  getOrdersByElderByDate,
} from 'graphql/queries';
import {
  createElder,
  createTag,
  createElderTag,
  deleteElderTag,
  updateElder,
  adminUpdateOrder,
} from 'graphql/mutations';
import DataJoinEditorInput from 'components/DataJoinEditor/DataJoinEditorInput';
import i18n from 'i18next';
import uiSchema from './uiSchema';
import {
  getClientIdSchema,
  getDeliveryStaffIdSchema,
} from 'forms/schemas';
import cache from 'utilities/cache';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import { changeClientId } from './changeClientId';
import { Semaphore } from 'async-mutex';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import { toastr } from 'react-redux-toastr';
import { useCache } from 'CacheProvider';

export default function ElderForm({ ...props }) {
  // load here for translation purpose
  const { default: defaultSchema } = useMemo(() => require('./schema.js'), []);
  const [tags, setTags] = useState({ items: [] });
  const [newTagMappings, setNewTagMappings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openCancelOrderDialog, setOpenCancelOrderDialog] = useState(false);
  const [openChangeClientIdDialog, setOpenChangeClientIdDialog] = useState(false);
  const [progress, setProgress] = useState(0);
  const { appGroup, selectedUserGroupParams } = useCache();

  const schema = JSON.parse(JSON.stringify(defaultSchema));
  let receiptInfo = {};
  if (props.formData && props.formData.id) {
    if (appGroup === 'Admins') {
      schema.properties.clientId.description = `更換所屬機構系統會同時移動訂單與${i18n.t('送餐關懷')}(費時)。如找不到資料請重新整理頁面。`;
    } else if (appGroup === 'OrgAdmins') {
      schema.properties.clientId.description = '更換所屬機構會複製送餐對象資料到新的機構';
    }
    receiptInfo = props.formData.client?.facilitySettings?.receiptInfo || {};
  } else if (appGroup === 'FacilityAdmins') {
    const { clientData } = selectedUserGroupParams;
    receiptInfo = clientData.facilitySettings?.receiptInfo || {};
  }
  if (receiptInfo.bankName) {
    schema.properties.paymentBankIndex.enumNames[0] = `1 - ${receiptInfo.bankName}`;
  }
  if (receiptInfo.bankName2) {
    schema.properties.paymentBankIndex.enumNames[1] = `2 - ${receiptInfo.bankName2}`;
  }
  if (receiptInfo.bankName3) {
    schema.properties.paymentBankIndex.enumNames[2] = `3 - ${receiptInfo.bankName3}`;
  }

  const onChangeTags = (key, tags) => {
    newTagMappings[key] = tags;
    setNewTagMappings({ ...newTagMappings });
  };

  const handleTags = async (elderId) => {
    const now = moment().toISOString();
    const username = cache.get('app:username');

    const toRemoveJoinData = [];
    tags.items.forEach(({ id, tagId, tag }) => {
      const { subcategory } = tag;
      const matched = newTagMappings[subcategory].find(({ id }) => id === tagId);
      if (!matched) {
        toRemoveJoinData.push({ id });
      }
    });

    await Promise.all([
      ...Object.keys(newTagMappings).map((subcategory) => {
        return Promise.all(newTagMappings[subcategory].map(async (item) => {
          let tag = item;

          if (!tag.id) {
            const tagData = {
              category: '送餐對象',
              subcategory,
              label: tag.label,
              createdAt: now,
              createdBy: username,
              updatedAt: now,
              updatedBy: username,
            };
            const { data: { createTag: createdTag } } = await request(createTag, { input: tagData });
            tag = createdTag;
          }

          const matched = tags.items.find(({ tagId }) => tagId === tag.id);
          if (!matched) {
            const joinData = {
              tagId: tag.id,
              elderId,
              createdBy: username,
              updatedBy: username,
            };

            await request(createElderTag, { input: joinData });
          }
        }));
      }),
      ...toRemoveJoinData.map((item) => {
        return request(deleteElderTag, { input: item });
      }),
    ]);
  };

  const getElderUpdated = async (elderId) => {
    const { data: { getElder: data } } = await request(getElder, { id: elderId });
    return data;
  };

  const createFunc = async (data) => {
    setIsLoading(true);
    data.county = data.address.county;

    // serviceCode 是 secondary index, 不能填 null
    if (data.serviceCode === null) {
      delete data.serviceCode;
    }

    const { data: { createElder: { id: elderId } } } = await request(createElder, { input: data });

    await handleTags(elderId);

    const elder = await getElderUpdated(elderId);

    setIsLoading(false);

    return elder;
  };

  const cancellAllInCompleteOrders = async (elder) => {
    const lastWeek = moment().tz(TIME_ZONE).add(-7, 'days').format('YYYY-MM-DD');
    const queryParams = {
      elderId: elder.id,
      date: {
        gt: lastWeek,
      },
      filter: {
        and: [
          {
            status: {
              ne: 'completed',
            },
          },
          {
            status: {
              ne: 'delivered',
            },
          },
          {
            status: {
              ne: 'cancelled',
            },
          },
        ],
      },
    };

    const { data: { getOrdersByElderByDate: { items: incompleteOrders } } } = await request(getOrdersByElderByDate, queryParams);

    if (incompleteOrders.length !== 0) {
      let orderProcessed = 0;
      const toUpdateData = [];

      if (window.confirm(`${elder.name} 尙有${incompleteOrders.length}筆訂單, 取消訂單?`)) {
        setOpenCancelOrderDialog(true);
        const s = new Semaphore(10);
        const orderGrouped = {};
        incompleteOrders.forEach((order) => {
          const groupId = order.orderGroupId || order.id;
          orderGrouped[groupId] = orderGrouped[groupId] || [];
          orderGrouped[groupId].push(order);
        });

        await Promise.all(Object.keys(orderGrouped).map((groupId) =>
          s.runExclusive(async () => {
            const data = orderGrouped[groupId].map((order) => (
              {
                id: order.id,
                restaurantId: order.restaurantId,
                deliveryBy: order.deliveryBy,
                status: 'cancelled',
                tier: order.tier,
                cancellationReason: 'serviceStop',
              }
            ));
            try {
              await request(adminUpdateOrder, { input: { orders: data } });
              toUpdateData.push(...data);
            } catch (e) {
              console.log(e);
              if (e.errors && e.errors[0] && e.errors[0].message) {
                toastr.error(e.errors[0].message);
              }
            }
            orderProcessed += 1;
            setProgress(orderProcessed * 100 / incompleteOrders.length);
          }),
        ));
        setOpenCancelOrderDialog(false);
      }
    }
  };

  const updateFunc = async (data) => {
    setIsLoading(true);

    const {
      id,
      clientId,
      client: { id: oldClientId },
    } = data;
    let elderId = id;
    const isClientChange = clientId !== oldClientId;

    data.county = data.address.county;
    let copyElder = false;

    if (appGroup === 'OrgAdmins' && isClientChange) {
      if (!window.confirm('確認複製送餐對象到新的機構嗎？')) {
        setIsLoading(false);
        throw new Error('abort');
      }
      copyElder = true;
    }
    delete data.client;
    delete data.tags;

    if (appGroup === 'Admins' && isClientChange) {
      if (!window.confirm('要移動送餐對象到新的機構請按確認，或按取消複製送餐對象到新機構？')) {
        if (!window.confirm('確認複製送餐對象到新的機構嗎？')) {
          setIsLoading(false);
          throw new Error('abort');
        }
        copyElder = true;
      }
    }

    if (copyElder) {
      const now = moment().toISOString();
      const username = cache.get('app:username');
      // 複製client到新機構
      delete data.deliveryGroupId;
      delete data.sortOrder;
      delete data.id;
      data.createdAt = now;
      data.createdBy = username;
      data.updatedAt = now;
      data.updatedBy = username;

      const cleanedData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== null),
      );

      const { data: { createElder: { id: newElderId } } } = await request(createElder, { input: cleanedData });
      elderId = newElderId;
      await handleTags(elderId);
    } else {
      const promises = [
        request(updateElder, { input: data }),
        handleTags(elderId),
      ];

      await Promise.all(promises);

      if (props.formData.status !== '已停用' && data.status === '已停用') {
        await cancellAllInCompleteOrders(data);
      }

      if (isClientChange) {
        setOpenChangeClientIdDialog(true);
        await changeClientId(elderId, clientId);
        setOpenChangeClientIdDialog(false);
      }
    }

    const elderUpdated = await getElderUpdated(elderId);
    setIsLoading(false);

    return elderUpdated;
  };

  useEffect(() => {
    if (props.formData && props.formData.id && props.formData.tags) {
      setTags(props.formData.tags);
      setNewTagMappings(props.formData.tags.items.reduce((obj, { tag }) => {
        obj[tag.subcategory] = obj[tag.subcategory] || [];
        obj[tag.subcategory].push(tag);
        return obj;
      }, {}));
    }
  }, [props.formData]);

  // workaround:
  // lunchRepeatOn和dinnderRepeatOn backend有可能回null, rjsf會把null放進xxxRepeatOn的array中
  // 造成validation不會過, 無法修改elder
  if (props.formData && !props.formData.lunchRepeatOn) {
    props.formData.lunchRepeatOn = [];
  }
  if (props.formData && !props.formData.dinnerRepeatOn) {
    props.formData.dinnerRepeatOn = [];
  }
  return (
    <>
      <DataForm
        schema={schema}
        uiSchema={uiSchema}
        createFunc={createFunc}
        updateFunc={updateFunc}
        dirty={true}
        extMappings={[{
          key: 'clientId',
          func: (clientId) => {
            if (appGroup === 'OrgAdmins') {
              const { organizationData } = selectedUserGroupParams;
              const records = [];
              if (organizationData?.clients?.items) {
                organizationData.clients.items.forEach(({ client: { id, name }, elderSetting }) => {
                  if (elderSetting) {
                    records.push({
                      id,
                      name,
                    });
                  }
                });
                if (records.length !== 0) {
                  return {
                    enum: records.map(({ id }) => id),
                    enumNames: records.map(({ name }) => name),
                  };
                }
              }
              return undefined;
            } else {
              const adminMode = appGroup === 'Admins';
              return getClientIdSchema(clientId, '子女(客戶)或機構', false, adminMode, appGroup);
            }
          },
        }, {
          key: 'defaultDeliveryStaffId',
          func: (defaultDeliveryStaffId) => {
            const clientId = cache.get('app:facilityId');
            return getDeliveryStaffIdSchema(defaultDeliveryStaffId, null, clientId, false, false, false, false, false);
          },
        }]}
        {...props}
      >
        <Typography variant="h5">
          標籤
        </Typography>
        <Divider />
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          {
            Object.keys(allTags['送餐對象']).map((key)=>(
              <Grid item xs={12} key={key}>
                <DataJoinEditorInput
                  title={`${key}標籤`}
                  mode={`送餐對象-${key}`}
                  joinData={tags ? tags.items.filter(({ tag }) => tag.subcategory === key) : []}
                  defaultValues={tags ? tags.items.filter(({ tag }) => tag.subcategory === key).map(({ tag }) => tag.label) : []}
                  onChange={(newTags) => onChangeTags(key, newTags)}
                  onUpdateOptions={() => {}}
                  disabled={isLoading}
                  showHelperText={false}
                />
              </Grid>
            ))
          }
        </Grid>
      </DataForm>
      <Dialog
        maxWidth='sm'
        fullWidth
        open={openCancelOrderDialog}
      >
        <DialogTitle id="dialog-title-cancel-order">
          取消訂單中
        </DialogTitle>
        <DialogContent style={{ marginBottom: 30 }}>
          <LinearProgressWithLabel value={progress}/>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth='sm'
        fullWidth
        open={openChangeClientIdDialog}
      >
        <DialogTitle id="dialog-title-change-client-id">
          更換所屬機構中，請勿關閉此視窗
        </DialogTitle>
        <DialogContent style={{ marginBottom: 30 }}>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </>
  );
}

ElderForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
