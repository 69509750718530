import JSZip from 'jszip';
import { download } from 'utilities/file';
import ExcelJS from 'exceljs';
import { sortBy } from 'utilities/sorting';
import { formatAddress } from 'utilities/format';
import { request } from 'utilities/graph';
import { getClientMonthlyStatementsByClientIdByMonth } from 'graphql/queries';
import { buildColumnsArray, convertDateToZhFormat, getROCYear, getMonthDates } from './helpers';

const COUNTY = '臺北市';

const typeMapping = {
  '低收': 1,
  '中低收': 2,
  '中低': 2,
  '近貧': 3,
};

const mealUnitPrice = 100;
const deliveryStaffFeePerTrip = 300;

export default async function writeTaipei({
  elders: allElders,
  orders: allOrders,
  deliveryStaffs,
  selectedMonth: selectedYearMonth = 'YYYY-MM',
  clientId,
  clientName,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
}) {
  const elders = allElders.filter(({ address }) => address.county === COUNTY);
  // const elders = allElders; // test
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }

  const [selectedYear, selectedMonth] = selectedYearMonth.split('-');
  const ROCYear = parseInt(selectedYear) - 1911;

  const months = Array(12).fill('').map((_, i) => {
    const month = i + 1 < 10 ? '0' + (i + 1) : i + 1;
    return {
      monthLabel: `${i + 1}月`,
      yearMonth: `${selectedYear}-${month}`,
    };
  });
  const clientStatements = [].concat(...await Promise.all(months.map(async ({ yearMonth }) => {
    const { data: { getClientMonthlyStatementsByClientIdByMonth: { items } } } = await request(getClientMonthlyStatementsByClientIdByMonth, {
      clientId,
      month: {
        eq: yearMonth,
      },
    });
    return items;
  })));

  const statementsFilterdByCounty = clientStatements.filter(({ county }) => county === COUNTY);
  // const statementsFilterdByCounty = clientStatements; // test

  const zip = new JSZip();

  const workbookElder = new ExcelJS.Workbook();
  const worksheetElder = workbookElder.addWorksheet(`${ROCYear}.${selectedMonth}`);
  getWorksheetElder(worksheetElder, elders, orders, selectedYearMonth, clientName);
  try {
    if (workbookElder && workbookElder.worksheets && workbookElder.worksheets.length > 0) {
      const bufferDataByDistrict = await workbookElder.xlsx.writeBuffer();
      zip.file(`${selectedYearMonth}膳費請領清冊.xlsx`, bufferDataByDistrict, { binary: true });
    }
  } catch (e) {
    console.error(e);
  }

  if (['03', '06', '09', '12'].includes(selectedMonth)) {
    const workbookElder = new ExcelJS.Workbook();
    const month = parseInt(selectedMonth);
    const worksheetElder = workbookElder.addWorksheet(`${month - 2}-${month}月`);
    getWorksheetElderBySeason(worksheetElder, elders, orders, statementsFilterdByCounty, selectedYearMonth, clientName);
    try {
      if (workbookElder && workbookElder.worksheets && workbookElder.worksheets.length > 0) {
        const bufferDataByDistrict = await workbookElder.xlsx.writeBuffer();
        zip.file(`${selectedYearMonth}膳費請領清冊(季).xlsx`, bufferDataByDistrict, { binary: true });
      }
    } catch (e) {
      console.error(e);
    }
  }

  const workbookElder2 = new ExcelJS.Workbook();
  const worksheetElder2 = workbookElder2.addWorksheet(`${selectedMonth}月`);
  getWorksheetElder2(worksheetElder2, elders, orders, selectedYearMonth, clientName);
  try {
    if (workbookElder2 && workbookElder2.worksheets && workbookElder2.worksheets.length > 0) {
      const bufferDataByDistrict = await workbookElder2.xlsx.writeBuffer();
      zip.file(`${selectedYearMonth}逐月明細表.xlsx`, bufferDataByDistrict, { binary: true });
    }
  } catch (e) {
    console.error(e);
  }

  const workbookMonthly = new ExcelJS.Workbook();
  const worksheetMonthly = workbookMonthly.addWorksheet('月報表');
  getWorksheetYear(worksheetMonthly, selectedYearMonth, clientName);
  months.forEach(({ monthLabel, yearMonth }) => {
    const worksheet = workbookMonthly.addWorksheet(monthLabel);
    const clientStatement = statementsFilterdByCounty.find(({ month }) => month === yearMonth) || {};
    getWorksheetElderByMonth(worksheet, clientStatement, clientName, yearMonth, monthLabel);
  });
  try {
    if (workbookMonthly && workbookMonthly.worksheets && workbookMonthly.worksheets.length > 0) {
      const bufferDataByDistrict = await workbookMonthly.xlsx.writeBuffer();
      zip.file(`${selectedYearMonth}月報表.xlsx`, bufferDataByDistrict, { binary: true });
    }
  } catch (e) {
    console.error(e);
  }

  const workbookDeliveryStaff = new ExcelJS.Workbook();
  const worksheet = workbookDeliveryStaff.addWorksheet(selectedMonth);
  getWorksheetDeliveryStaff(worksheet, deliveryStaffs, orders, clientName, selectedYearMonth, `${selectedMonth}月`);
  // months.forEach(({ monthLabel, yearMonth }, index) => {
  //   getWorksheetDeliveryStaff(worksheet, deliveryStaffs, orders, clientName, yearMonth, monthLabel);
  // });
  try {
    if (workbookDeliveryStaff && workbookDeliveryStaff.worksheets && workbookDeliveryStaff.worksheets.length > 0) {
      const bufferDataByDistrict = await workbookDeliveryStaff.xlsx.writeBuffer();
      zip.file(`${selectedYearMonth}送餐人員誤餐交通費請領清冊.xlsx`, bufferDataByDistrict, { binary: true });
    }
  } catch (e) {
    console.error(e);
  }

  const files = Object.keys(zip.files);
  if (files.length !== 0) {
    const zipContent = zip.generate({ type: 'blob' });
    download(`${clientName}__${selectedYearMonth}核銷報表.zip`, zipContent);
  }
}

function getWorksheetElder(worksheet, elders = [], orders = [], selectedYearMonth, clientName) {
  const elderRows = [];
  elders
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(({
      id,
      name,
      identificationCardId,
      birthday,
      disadvantagedTypesSlot,
    }) => {
      const elderOrders = orders
        .filter(({ elderId }) => elderId === id);

      if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
        disadvantagedTypesSlot.forEach(({ start, end, type }) => {
          if (type === '一般') {
            return;
          }
          const filteredOrders = elderOrders.filter(({ date }) => date >= start && date < end);
          if (filteredOrders.length === 0) {
            return;
          }
          const lunchs = [];
          const dinners = [];
          filteredOrders.sort(sortBy('deliveryBy')).forEach(({ mealSlot, date }) => {
            const day = date.split('-')[2];
            if (mealSlot === 'lunch') {
              lunchs.push(day);
            } else {
              dinners.push(day);
            }
          });
          const serviceDates = '午:\n' + lunchs.join(',') + '\n晚:\n' + dinners.join(',');
          elderRows.push({
            姓名: name,
            身分證字號: identificationCardId,
            出生年月日: convertDateToZhFormat(birthday),
            身份別: typeMapping[type],
            供餐次數: filteredOrders.length,
            服務日期: serviceDates,
          });
        });
      }
    });
  const fontName = 'Calibri';
  const range = 'A:AZ';
  const columnArray = buildColumnsArray(range);

  const [year, month] = selectedYearMonth.split('-');
  const ROCYear = parseInt(year) - 1911;

  let rowIndex = 1;

  const columnWidth = [5.83, 17.83, 22.67, 14.67, 76, 10, 13, 7, 13, 38, 22.33];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  const defaultBorder = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

  worksheet.mergeCells(`A${rowIndex}:K${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `${clientName}`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 18, name: fontName, underline: true };
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:K${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `「臺北市政府社會局  ${ROCYear}  年度補助辦理失能者營養餐飲服務計畫」`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 18, name: fontName };
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:I${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `                                                          ${ROCYear}  年 ${month} 月份請領清冊`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 18, name: fontName };
  worksheet.mergeCells(`J${rowIndex}:K${rowIndex}`);
  worksheet.getCell(`J${rowIndex}`).value = `單 位：新台幣元`;
  worksheet.getCell(`J${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
  worksheet.getCell(`J${rowIndex}`).font = { size: 18, name: fontName };
  rowIndex += 1;

  ['編號', '姓名', '身分證\n統一編號', '出生\n年月日', '服務日期', '身分別\n低收:1\n中低:2\n近貧:3',
    '供餐次數', '單價', '補助金額', '蓋章', '備註'].forEach((header, index) => {
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).value = header;
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).border = defaultBorder;
    worksheet.getRow(rowIndex).height = 84.75;
  });
  rowIndex += 1;
  const elderRowStart = rowIndex;
  elderRows.forEach((elderRow, index) => {
    worksheet.getCell(`A${rowIndex}`).value = index + 1;
    worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`B${rowIndex}`).value = elderRow['姓名'];
    worksheet.getCell(`B${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`B${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`B${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`C${rowIndex}`).value = elderRow['身分證字號'];
    worksheet.getCell(`C${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`C${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`C${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`D${rowIndex}`).value = elderRow['出生年月日'];
    worksheet.getCell(`D${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`D${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`D${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`E${rowIndex}`).value = elderRow['服務日期'];
    worksheet.getCell(`E${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    worksheet.getCell(`E${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`E${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`F${rowIndex}`).value = elderRow['身份別'];
    worksheet.getCell(`F${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`F${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`F${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`G${rowIndex}`).value = elderRow['供餐次數'];
    worksheet.getCell(`G${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`G${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`G${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`H${rowIndex}`).value = mealUnitPrice;
    worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`H${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`H${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`I${rowIndex}`).value = { formula: `=G${rowIndex}*H${rowIndex}` };
    worksheet.getCell(`I${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`I${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`I${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`J${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`J${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`J${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`K${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`K${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`K${rowIndex}`).border = defaultBorder;
    worksheet.getRow(rowIndex).height = 84.75;
    rowIndex += 1;
  });
  const elderRowEnd = rowIndex - 1;

  worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`B${rowIndex}:F${rowIndex}`);
  worksheet.getCell(`B${rowIndex}`).value = '合           計';
  worksheet.getCell(`B${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`B${rowIndex}`).font = { size: 20, name: fontName };
  worksheet.getCell(`B${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`G${rowIndex}`).value = { formula: `=SUM(G${elderRowStart}:G${elderRowEnd})` };
  worksheet.getCell(`G${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`G${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`G${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`H${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`H${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`I${rowIndex}`).value = { formula: `=SUM(I${elderRowStart}:I${elderRowEnd})` };
  worksheet.getCell(`I${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`I${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`I${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`J${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`J${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`J${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`K${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`K${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`K${rowIndex}`).border = defaultBorder;
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:K${rowIndex}`);
  // eslint-disable-next-line max-len
  worksheet.getCell(`A${rowIndex}`).value = `填表人：                                                                                                      主辦會計：                                                                                                                單位主管：`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`K${rowIndex}`).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  rowIndex += 3;

  worksheet.mergeCells(`A${rowIndex}:H${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `註：依支出憑證處理要點，如以指印或其他符號代替簽名者，經2人以上之簽名證明，亦與簽名生同等之效力`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };

  return worksheet;
}

function getWorksheetElderBySeason(worksheet, elders = [], orders = [], clientStatements = [], selectedYearMonth, clientName) {
  const elderRows = [];

  const fontName = 'Calibri';
  const range = 'A:AZ';
  const columnArray = buildColumnsArray(range);

  const [year, monthString] = selectedYearMonth.split('-');
  const ROCYear = parseInt(year) - 1911;
  const month = parseInt(monthString);

  [0, 1, 2].forEach((offset) => {
    const monthString = month - offset < 10 ? `0${month - offset}` : `${month - offset}`;
    const yearMonth = `${year}-${monthString}`;
    const clientStatement = clientStatements.find(({ month }) => month === yearMonth);
    if (clientStatement) {
      const { elderStatistics } = clientStatement;
      elderStatistics.forEach((elderStatistic) => {
        const {
          name, identificationCardId, birthday, disadvantagedTypes,
        } = elderStatistic;
        if (!name) {
          return;
        }
        const lunchDates = elderStatistic.lunchDates || [];
        const dinnerDates = elderStatistic.dinnerDates || [];
        if (lunchDates.length === 0 && dinnerDates.length === 0) {
          return;
        }
        const existingElderRow = elderRows.find(({ identificationCardId, disadvantagedTypes }) => (
          identificationCardId === elderStatistic.identificationCardId &&
          disadvantagedTypes === elderStatistic.disadvantagedTypes
        ));
        if (!existingElderRow) {
          elderRows.push({
            name,
            identificationCardId,
            birthday: birthday ? convertDateToZhFormat(birthday) : '',
            lunchDatesList: {
              [monthString]: lunchDates,
            },
            dinnerDatesList: {
              [monthString]: dinnerDates,
            },
            disadvantagedTypes,
          });
        } else {
          if (name) {
            existingElderRow.name = name || existingElderRow.name;
          }
          if (birthday) {
            existingElderRow.birthday = birthday ? convertDateToZhFormat(birthday) : '' || existingElderRow.birthday;
          }
          existingElderRow.lunchDatesList[monthString] = lunchDates;
          existingElderRow.dinnerDatesList[monthString] = dinnerDates;
        }
      });
    }
  });
  let rowIndex = 1;

  const columnWidth = [5.83, 17.83, 22.67, 14.67, 76, 10, 13, 13, 13, 7, 13, 38, 22.33];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  const defaultBorder = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

  let cell;
  worksheet.mergeCells(`A${rowIndex}:N${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = `${clientName}`;
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 18, name: fontName, underline: true };
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:N${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = `「臺北市政府社會局  ${ROCYear}  年度補助辦理失能者營養餐飲服務計畫」`;
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 18, name: fontName };
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:L${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value =
    `                                                          ${ROCYear}  年 ${month - 2}-${month} 月份請領清冊`;
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 18, name: fontName };
  worksheet.mergeCells(`M${rowIndex}:N${rowIndex}`);
  cell = worksheet.getCell(`M${rowIndex}`);
  cell.value = `單 位：新台幣元`;
  cell.alignment = { horizontal: 'right', vertical: 'middle' };
  cell.font = { size: 18, name: fontName };
  rowIndex += 1;

  ['編號', '姓名', '身分證\n統一編號', '出生\n年月日', '服務日期', '身分別\n低收:1\n中低:2\n近貧:3',
    `${month - 2}月\n供餐次數`, `${month - 1}月\n供餐次數`, `${month}月\n供餐次數`,
    '供餐次數', '單價', '補助金額', '蓋章', '備註'].forEach((header, index) => {
    cell = worksheet.getCell(`${columnArray[index]}${rowIndex}`);
    cell.value = header;
    cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    worksheet.getRow(rowIndex).height = 84.75;
  });
  rowIndex += 1;
  const elderRowStart = rowIndex;
  elderRows.forEach((elderRow, index) => {
    let serviceDates = '';
    const mealCount = [0, 0, 0];
    [2, 1, 0].forEach((offset, index) => {
      const monthString = month - offset < 10 ? `0${month - offset}` : `${month - offset}`;
      serviceDates += `${monthString}月午:`;
      serviceDates += (elderRow.lunchDatesList[monthString] || []).join(',');
      serviceDates += '\n';
      serviceDates += `${monthString}月晚:`;
      serviceDates += (elderRow.dinnerDatesList[monthString] || []).join(',');
      serviceDates += '\n';
      mealCount[index] = (elderRow.lunchDatesList[monthString] || []).length + (elderRow.dinnerDatesList[monthString] || []).length;
    });
    cell = worksheet.getCell(`A${rowIndex}`);
    cell.value = index + 1;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`B${rowIndex}`);
    cell.value = elderRow.name;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`C${rowIndex}`);
    cell.value = elderRow.identificationCardId;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`D${rowIndex}`);
    cell.value = elderRow.birthday;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`E${rowIndex}`);
    cell.value = serviceDates;
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cell.font = { size: 12, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`F${rowIndex}`);
    cell.value = typeMapping[elderRow.disadvantagedTypes] || '';
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`G${rowIndex}`);
    cell.value = mealCount[0];
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell.fill =
      { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F9CE9C' }, bgColor: { argb: 'F9CE9C' } };
    cell = worksheet.getCell(`H${rowIndex}`);
    cell.value = mealCount[1];
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell.fill =
      { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F9CE9C' }, bgColor: { argb: 'F9CE9C' } };
    cell = worksheet.getCell(`I${rowIndex}`);
    cell.value = mealCount[2];
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell.fill =
      { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F9CE9C' }, bgColor: { argb: 'F9CE9C' } };
    cell = worksheet.getCell(`J${rowIndex}`);
    cell.value = { formula: `=SUM(G${rowIndex}:I${rowIndex})` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell.fill =
      { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CBFFCC' }, bgColor: { argb: 'CBFFCC' } };
    cell = worksheet.getCell(`K${rowIndex}`);
    cell.value = mealUnitPrice;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell.fill =
      { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CBFFCC' }, bgColor: { argb: 'CBFFCC' } };
    cell = worksheet.getCell(`L${rowIndex}`);
    cell.value = { formula: `=J${rowIndex}*K${rowIndex}` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell.fill =
      { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CBFFCC' }, bgColor: { argb: 'CBFFCC' } };
    cell = worksheet.getCell(`M${rowIndex}`);
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    cell = worksheet.getCell(`N${rowIndex}`);
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 14, name: fontName };
    cell.border = defaultBorder;
    worksheet.getRow(rowIndex).height = 84.75;
    rowIndex += 1;
  });
  const elderRowEnd = rowIndex - 1;
  worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`B${rowIndex}:F${rowIndex}`);
  cell = worksheet.getCell(`B${rowIndex}`);
  cell.value = '合           計';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 20, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`G${rowIndex}`);
  cell.value = { formula: `=SUM(G${elderRowStart}:G${elderRowEnd})` };
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`H${rowIndex}`);
  cell.value = { formula: `=SUM(H${elderRowStart}:H${elderRowEnd})` };
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`I${rowIndex}`);
  cell.value = { formula: `=SUM(I${elderRowStart}:I${elderRowEnd})` };
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`J${rowIndex}`);
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`K${rowIndex}`);
  cell.value = { formula: `=SUM(K${elderRowStart}:K${elderRowEnd})` };
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`L${rowIndex}`);
  cell.value = { formula: `=SUM(L${elderRowStart}:L${elderRowEnd})` };
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`M${rowIndex}`);
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  cell = worksheet.getCell(`N${rowIndex}`);
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  cell.border = defaultBorder;
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:N${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  // eslint-disable-next-line max-len
  cell.value = `填表人：                                                                                                      主辦會計：                                                                                                                單位主管：`;
  cell.alignment = { horizontal: 'left', vertical: 'middle' };
  cell.font = { size: 14, name: fontName };
  worksheet.getCell(`K${rowIndex}`).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  rowIndex += 3;

  worksheet.mergeCells(`A${rowIndex}:H${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = `註：依支出憑證處理要點，如以指印或其他符號代替簽名者，經2人以上之簽名證明，亦與簽名生同等之效力`;
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 12, name: fontName };

  return worksheet;
}

function getWorksheetElder2(worksheet, elders = [], orders = [], selectedYearMonth) {
  const monthDates = getMonthDates(selectedYearMonth, 'dddd');
  const elderRowsByType = [[], []]; // 補助戶, 一般戶
  elders
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(({
      id,
      name,
      disadvantagedTypesSlot,
    }) => {
      const elderOrders = orders
        .filter(({ elderId }) => elderId === id);

      if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
        disadvantagedTypesSlot.forEach(({ start, end, type }) => {
          const filteredOrders = elderOrders.filter(({ date }) => date >= start && date < end);
          if (filteredOrders.length === 0) {
            return;
          }
          const lunchs = [];
          const dinners = [];
          filteredOrders.sort(sortBy('deliveryBy')).forEach(({ mealSlot, date }) => {
            const day = date.split('-')[2];
            if (mealSlot === 'lunch') {
              lunchs.push(parseInt(day));
            } else {
              dinners.push(parseInt(day));
            }
          });
          if (type === '一般') {
            elderRowsByType[1].push({
              name,
              disadvantagedType: type,
              lunchs,
              dinners,
            });
          } else {
            elderRowsByType[0].push({
              name,
              disadvantagedType: type,
              lunchs,
              dinners,
            });
          }
        });
      }
    });
  const fontName = 'Calibri';
  const range = 'A:AZ';
  const columnArray = buildColumnsArray(range);

  const [year, month] = selectedYearMonth.split('-');
  const ROCYear = parseInt(year) - 1911;

  let rowIndex = 1;

  worksheet.getColumn('B').width = 20;

  const defaultBorder = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  let cell;
  worksheet.mergeCells(`A${rowIndex}:${columnArray[monthDates.length + 2]}${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value =
    `${ROCYear}年${month}月份營養餐飲服務逐月明細表(補助個案)
說明：請在用餐日期內輸入長者當日用餐數量，若提供1餐請填1、若提供2餐請填2。`;
  cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
  cell.font = { size: 24, name: fontName };
  worksheet.getRow(rowIndex).height = 60;
  rowIndex += 1;

  elderRowsByType.forEach((elderRows, elderTypeIndex) => {
    worksheet.mergeCells(`A${rowIndex}:${columnArray[monthDates.length + 2]}${rowIndex}`);
    cell = worksheet.getCell(`A${rowIndex}`);
    cell.value = elderTypeIndex === 0 ? '補助戶' : '一般戶';
    cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    cell.font = { size: 24, name: fontName };
    cell.border = defaultBorder;
    rowIndex += 1;

    worksheet.mergeCells(`A${rowIndex}:B${rowIndex}`);
    cell = worksheet.getCell(`A${rowIndex}`);
    cell.value = '日期';
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 24, name: fontName };
    cell.border = defaultBorder;
    worksheet.mergeCells(`A${rowIndex + 1}:B${rowIndex + 1}`);
    cell = worksheet.getCell(`A${rowIndex + 1}`);
    cell.value = '星期';
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 24, name: fontName };
    cell.border = defaultBorder;
    monthDates.forEach(({ label }, index) => {
      cell = worksheet.getCell(`${columnArray[index + 2]}${rowIndex}`);
      cell.value = index + 1;
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 24, name: fontName };
      cell.border = defaultBorder;
      cell = worksheet.getCell(`${columnArray[index + 2]}${rowIndex + 1}`);
      cell.value = label.replace('星期', '週');
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 24, name: fontName };
      cell.border = defaultBorder;
    });
    worksheet.mergeCells(`${columnArray[monthDates.length + 2]}${rowIndex}:${columnArray[monthDates.length + 2]}${rowIndex + 1}`);
    cell = worksheet.getCell(`${columnArray[monthDates.length + 2]}${rowIndex}`);
    cell.value = '合計';
    cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    cell.font = { size: 24, name: fontName };
    cell.border = defaultBorder;
    rowIndex += 2;
    let totalMealCount = 0;
    const elderRowStart = rowIndex;
    elderRows.forEach((elderRow) => {
      cell = worksheet.getCell(`A${rowIndex}`);
      cell.value = '姓名';
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 24, name: fontName };
      cell.border = defaultBorder;
      cell = worksheet.getCell(`B${rowIndex}`);
      cell.value = elderRow.name;
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 24, name: fontName };
      cell.border = defaultBorder;
      monthDates.forEach((_, index) => {
        const lunchCount = elderRow.lunchs.includes(index + 1) ? 1 : 0;
        const dinnerCount = elderRow.dinners.includes(index + 1) ? 1 : 0;
        totalMealCount += lunchCount + dinnerCount;
        cell = worksheet.getCell(`${columnArray[index + 2]}${rowIndex}`);
        cell.value = lunchCount + dinnerCount || '';
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.font = { size: 24, name: fontName };
        cell.border = defaultBorder;
      });
      cell = worksheet.getCell(`${columnArray[monthDates.length + 2]}${rowIndex}`);
      cell.value = { formula: `=SUM(C${rowIndex}:${columnArray[monthDates.length + 1]}${rowIndex})` };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 24, name: fontName };
      cell.border = defaultBorder;
      rowIndex += 1;
    });

    const elderRowEnd = rowIndex - 1;

    worksheet.mergeCells(`A${rowIndex}:B${rowIndex}`);
    cell = worksheet.getCell(`A${rowIndex}`);
    cell.value = '小計';
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 24, name: fontName };
    cell.border = defaultBorder;
    monthDates.forEach((_, index) => {
      cell = worksheet.getCell(`${columnArray[index + 2]}${rowIndex}`);
      cell.value = { formula: `=SUM(${columnArray[index + 2]}${elderRowStart}:${columnArray[index + 2]}${elderRowEnd})` };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 24, name: fontName };
      cell.border = defaultBorder;
    });
    cell = worksheet.getCell(`${columnArray[monthDates.length + 2]}${rowIndex}`);
    cell.value = { formula: `=SUM(${columnArray[monthDates.length + 2]}${elderRowStart}:${columnArray[monthDates.length + 2]}${elderRowEnd})` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 24, name: fontName };
    cell.border = defaultBorder;
    rowIndex += 1;

    worksheet.mergeCells(`A${rowIndex}:${columnArray[monthDates.length + 2]}${rowIndex}`);
    cell = worksheet.getCell(`A${rowIndex}`);
    if (elderTypeIndex === 0) {
      cell.value = `補助戶:${totalMealCount}(份)*${mealUnitPrice}(元)=$${totalMealCount * mealUnitPrice}`;
    } else {
      cell.value = `一般戶:${totalMealCount}(份)*${mealUnitPrice}(元)=$${totalMealCount * mealUnitPrice}`;
    }
    cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    cell.font = { size: 24, name: fontName };
    cell.border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

    rowIndex += 3;
  });

  return worksheet;
}

function getWorksheetYear(worksheet, selectedYearMonth, clientName) {
  const fontName = 'Calibri';
  const range = 'A:AZ';
  const columnArray = buildColumnsArray(range);
  const ROCYear = getROCYear(selectedYearMonth);

  let rowIndex = 2;

  const defaultBorder = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

  worksheet.mergeCells(`A${rowIndex}:AO${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `${ROCYear}年補助辦理失能者營養餐飲服務月報表`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 16, name: fontName, bold: true };
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:Z${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `單位名稱:${clientName}`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 14, name: fontName };
  rowIndex += 2;

  worksheet.mergeCells(`A${rowIndex}:A${rowIndex + 2}`);
  worksheet.getCell(`A${rowIndex}`).value = `月份`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`B${rowIndex}:B${rowIndex + 2}`);
  worksheet.getCell(`B${rowIndex}`).value = `項目`;
  worksheet.getCell(`B${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`B${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`B${rowIndex}`).border = defaultBorder;
  ['低收入戶', '中低收入戶', '近貧', '一般戶'].forEach((header, index) => {
    const offset = 2 + index * 6;
    worksheet.mergeCells(`${columnArray[offset]}${rowIndex}:${columnArray[offset + 5]}${rowIndex}`);
    worksheet.getCell(`${columnArray[offset]}${rowIndex}`).value = header;
    worksheet.getCell(`${columnArray[offset]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${columnArray[offset]}${rowIndex}`).font = { size: 12, name: fontName, bold: true };
    worksheet.getCell(`${columnArray[offset]}${rowIndex}`).border = defaultBorder;
    ['輕度失能', '中度失能', '重度失能'].forEach((header, index2) => {
      const offset2 = offset + index2 * 2;
      worksheet.mergeCells(`${columnArray[offset2]}${rowIndex + 1}:${columnArray[offset2 + 1]}${rowIndex + 1}`);
      worksheet.getCell(`${columnArray[offset2]}${rowIndex + 1}`).value = header;
      worksheet.getCell(`${columnArray[offset2]}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${columnArray[offset2]}${rowIndex + 1}`).font = { size: 12, name: fontName };
      worksheet.getCell(`${columnArray[offset2]}${rowIndex + 1}`).border = defaultBorder;
      worksheet.getCell(`${columnArray[offset2]}${rowIndex + 2}`).value = '男';
      worksheet.getCell(`${columnArray[offset2]}${rowIndex + 2}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${columnArray[offset2]}${rowIndex + 2}`).font = { size: 12, name: fontName };
      worksheet.getCell(`${columnArray[offset2]}${rowIndex + 2}`).border = defaultBorder;
      worksheet.getCell(`${columnArray[offset2 + 1]}${rowIndex + 2}`).value = '女';
      worksheet.getCell(`${columnArray[offset2 + 1]}${rowIndex + 2}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${columnArray[offset2 + 1]}${rowIndex + 2}`).font = { size: 12, name: fontName };
      worksheet.getCell(`${columnArray[offset2 + 1]}${rowIndex + 2}`).border = defaultBorder;
    });
  });
  ['受補助戶\n(低收+中低+近貧)', '一般戶', '總計', '獨居\n(本欄請獨立計算)', '原住民\n(本欄請獨立計算)'].forEach((header, index) => {
    const offset = index * 3 + 26;
    worksheet.mergeCells(`${columnArray[offset]}${rowIndex}:${columnArray[offset + 2]}${rowIndex}`);
    worksheet.getCell(`${columnArray[offset]}${rowIndex}`).value = header;
    worksheet.getCell(`${columnArray[offset]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.getCell(`${columnArray[offset]}${rowIndex}`).font = { size: 12, name: fontName, bold: true };
    worksheet.getCell(`${columnArray[offset]}${rowIndex}`).border = defaultBorder;
    worksheet.mergeCells(`${columnArray[offset]}${rowIndex + 1}:${columnArray[offset + 2]}${rowIndex + 1}`);
    worksheet.getCell(`${columnArray[offset]}${rowIndex + 1}`).value = '小計';
    worksheet.getCell(`${columnArray[offset]}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${columnArray[offset]}${rowIndex + 1}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${columnArray[offset]}${rowIndex + 1}`).border = defaultBorder;
    worksheet.getCell(`${columnArray[offset]}${rowIndex + 2}`).value = '男';
    worksheet.getCell(`${columnArray[offset]}${rowIndex + 2}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${columnArray[offset]}${rowIndex + 2}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${columnArray[offset]}${rowIndex + 2}`).border = defaultBorder;
    worksheet.getCell(`${columnArray[offset + 1]}${rowIndex + 2}`).value = '女';
    worksheet.getCell(`${columnArray[offset + 1]}${rowIndex + 2}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${columnArray[offset + 1]}${rowIndex + 2}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${columnArray[offset + 1]}${rowIndex + 2}`).border = defaultBorder;
    worksheet.getCell(`${columnArray[offset + 2]}${rowIndex + 2}`).value = '合計';
    worksheet.getCell(`${columnArray[offset + 2]}${rowIndex + 2}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${columnArray[offset + 2]}${rowIndex + 2}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${columnArray[offset + 2]}${rowIndex + 2}`).border = defaultBorder;
  });
  worksheet.getRow(rowIndex).height = 38;
  rowIndex += 3;

  Array(12).fill('').map((_, i) => `${i + 1}月`).forEach((month) => {
    worksheet.mergeCells(`A${rowIndex}:A${rowIndex + 1}`);
    worksheet.getCell(`A${rowIndex}`).value = month;
    worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`B${rowIndex}`).value = '人數';
    worksheet.getCell(`B${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`B${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`B${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`B${rowIndex + 1}`).value = '人次';
    worksheet.getCell(`B${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`B${rowIndex + 1}`).font = { size: 12, name: fontName };
    worksheet.getCell(`B${rowIndex + 1}`).border = defaultBorder;
    [
      [`=SUM('1月'!AN:AN)`, `=SUM('1月'!BP:BP)`],
      [`=SUM('1月'!AO:AO)`, `=SUM('1月'!BQ:BQ)`],
      [`=SUM('1月'!AP:AP)`, `=SUM('1月'!BR:BR)`],
      [`=SUM('1月'!AQ:AQ)`, `=SUM('1月'!BS:BS)`],
      [`=SUM('1月'!AR:AR)`, `=SUM('1月'!BT:BT)`],
      [`=SUM('1月'!AS:AS)`, `=SUM('1月'!BU:BU)`],
      [`=SUM('1月'!AT:AT)`, `=SUM('1月'!BV:BV)`],
      [`=SUM('1月'!AU:AU)`, `=SUM('1月'!BW:BW)`],
      [`=SUM('1月'!AV:AV)`, `=SUM('1月'!BX:BX)`],
      [`=SUM('1月'!AW:AW)`, `=SUM('1月'!BY:BY)`],
      [`=SUM('1月'!AX:AX)`, `=SUM('1月'!BZ:BZ)`],
      [`=SUM('1月'!AY:AY)`, `=SUM('1月'!CA:CA)`],
      [`=SUM('1月'!AZ:AZ)`, `=SUM('1月'!CB:CB)`],
      [`=SUM('1月'!BA:BA)`, `=SUM('1月'!CC:CC)`],
      [`=SUM('1月'!BB:BB)`, `=SUM('1月'!CD:CD)`],
      [`=SUM('1月'!BC:BC)`, `=SUM('1月'!CE:CE)`],
      [`=SUM('1月'!BD:BD)`, `=SUM('1月'!CF:CF)`],
      [`=SUM('1月'!BE:BE)`, `=SUM('1月'!CG:CG)`],
      [`=SUM('1月'!BF:BF)`, `=SUM('1月'!CH:CH)`],
      [`=SUM('1月'!BG:BG)`, `=SUM('1月'!CI:CI)`],
      [`=SUM('1月'!BH:BH)`, `=SUM('1月'!CJ:CJ)`],
      [`=SUM('1月'!BI:BI)`, `=SUM('1月'!CK:CK)`],
      [`=SUM('1月'!BJ:BJ)`, `=SUM('1月'!CL:CL)`],
      [`=SUM('1月'!BK:BK)`, `=SUM('1月'!CM:CM)`],
      [`=C8+E8+G8+I8+K8+M8+O8+Q8+S8`, `=C9+E9+G9+I9+K9+M9+O9+Q9+S9`],
      [`=D8+F8+H8+J8+L8+N8+P8+R8+T8`, `=D9+F9+H9+J9+L9+N9+P9+R9+T9`],
      [`=SUM(C8:T8)`, `=SUM(C9:T9)`],
      [`=U8+W8+Y8`, `=U9+W9+Y9`],
      [`=V8+X8+Z8`, `=V9+X9+Z9`],
      [`=SUM(U8:Z8)`, `=SUM(U9:Z9)`],
      [`=AA8+AD8`, `=AA9+AD9`],
      [`=AB8+AE8`, `=AB9+AE9`],
      [`=SUM(C8:Z8)`, `=SUM(C9:Z9)`],
      [`=SUM('1月'!BL:BL)`, `=SUM('1月'!CN:CN)`],
      [`=SUM('1月'!BM:BM)`, `=SUM('1月'!CO:CO)`],
      [`=SUM(AJ8:AK8)`, `=SUM(AJ9:AK9)`],
      [`=SUM('1月'!BN:BN)`, `=SUM('1月'!BO:BO)`],
      [`=SUM('1月'!BO:BO)`, `=SUM('1月'!BQ:BQ)`],
      [`=SUM(AM8:AN8)`, `=SUM(AM9:AN9)`],
    ].forEach((value, index) => {
      const columnIndex = index + 2;
      worksheet.getCell(`${columnArray[columnIndex]}${rowIndex}`).value = { formula: value[0].replace(/8/g, rowIndex).replace('1月', month) };
      worksheet.getCell(`${columnArray[columnIndex]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${columnArray[columnIndex]}${rowIndex}`).font = { size: 12, name: fontName };
      worksheet.getCell(`${columnArray[columnIndex]}${rowIndex}`).border = defaultBorder;
      worksheet.getCell(`${columnArray[columnIndex]}${rowIndex + 1}`).value = { formula: value[1].replace(/9/g, rowIndex + 1).replace('1月', month) };
      worksheet.getCell(`${columnArray[columnIndex]}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${columnArray[columnIndex]}${rowIndex + 1}`).font = { size: 12, name: fontName };
      worksheet.getCell(`${columnArray[columnIndex]}${rowIndex + 1}`).border = defaultBorder;
      worksheet.getCell(`${columnArray[columnIndex]}${rowIndex + 1}`).fill =
          { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00' }, bgColor: { argb: 'FFFF00' } };
    });
    rowIndex += 2;
  });
  const lastRowBorder = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  worksheet.mergeCells(`A${rowIndex}:A${rowIndex + 1}`);
  worksheet.getCell(`A${rowIndex}`).value = '合計';
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`A${rowIndex}`).border = lastRowBorder;
  worksheet.getCell(`B${rowIndex}`).value = '人數';
  worksheet.getCell(`B${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`B${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`B${rowIndex}`).border = lastRowBorder;
  worksheet.getCell(`B${rowIndex + 1}`).value = '人次';
  worksheet.getCell(`B${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`B${rowIndex + 1}`).font = { size: 12, name: fontName };
  worksheet.getCell(`B${rowIndex + 1}`).border = lastRowBorder;
  Array(39).fill('').map((_, i) => i + 2).forEach((columnIndex) => {
    const column = columnArray[columnIndex];
    worksheet.getCell(`${column}${rowIndex}`).value = { formula: '=C8+C10+C12+C14+C16+C18+C20+C22+C24+C26+C28+C30'.replace(/C/g, column) };
    worksheet.getCell(`${column}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${column}${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${column}${rowIndex}`).border = lastRowBorder;
    worksheet.getCell(`${column}${rowIndex + 1}`).value = { formula: '=C9+C11+C13+C15+C17+C19+C21+C23+C25+C27+C29+C31'.replace(/C/g, column) };
    worksheet.getCell(`${column}${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${column}${rowIndex + 1}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${column}${rowIndex + 1}`).border = lastRowBorder;
  });

  return worksheet;
}

function getWorksheetElderByMonth(worksheet, clientStatement, clientName, yearMonth, monthLabel) {
  const fontName = 'Calibri';
  const range = 'A:CZ';
  const columnArray = buildColumnsArray(range);
  const ROCYear = getROCYear(yearMonth);

  let rowIndex = 1;

  worksheet.getColumn('C').width = 13;

  const defaultBorder = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

  worksheet.mergeCells(`B${rowIndex}:U${rowIndex}`);
  worksheet.getCell(`B${rowIndex}`).value = `${clientName} ${ROCYear} 年度 ${monthLabel}北市社會局失能者營養餐飲服務計畫名冊`;
  worksheet.getCell(`B${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`B${rowIndex}`).font = { size: 16, name: fontName, bold: true };
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:U${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `備註：
1.性別、失能程度、福利身份別、獨居與否及原住民身分請以「1」註記(以上所有類別均須依照個案情況填寫，不可整欄空白)，以利自動加總人數。
2.用餐數量為當月餐次加總。(該月無用餐或暫停送餐之長者不可填寫於名冊上)    
3.該月新增個案請於備註欄備註開始送餐日。 `;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getRow(rowIndex).height = 66;
  rowIndex += 1;

  ['序號', '姓名', '身份證\n統一編號', '出生年\n(民國)', '聯絡電話'].forEach((header, index) => {
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).value = header;
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).border = defaultBorder;
  });
  worksheet.mergeCells(`F${rowIndex}:G${rowIndex}`);
  worksheet.getCell(`F${rowIndex}`).value = '性別';
  worksheet.getCell(`F${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`F${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`F${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`H${rowIndex}:J${rowIndex}`);
  worksheet.getCell(`H${rowIndex}`).value = '失能程度';
  worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`H${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`H${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`K${rowIndex}:N${rowIndex}`);
  worksheet.getCell(`K${rowIndex}`).value = '福利身份';
  worksheet.getCell(`K${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`K${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`K${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`O${rowIndex}:Q${rowIndex}`);
  worksheet.getCell(`O${rowIndex}`).value = '獨居與否';
  worksheet.getCell(`O${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`O${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`O${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`R${rowIndex}`).value = '用餐\n數量';
  worksheet.getCell(`R${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
  worksheet.getCell(`R${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`R${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`S${rowIndex}:T${rowIndex}`);
  worksheet.getCell(`S${rowIndex}`).value = '原住民身分';
  worksheet.getCell(`S${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`S${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`S${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`U${rowIndex}`).value = '備註';
  worksheet.getCell(`U${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`U${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`U${rowIndex}`).border = defaultBorder;
  worksheet.getRow(rowIndex).height = 36;
  rowIndex += 1;

  ['', '', '', '', '', '男', '女', '輕', '中', '重',
    '低收', '中低', '近貧', '一般', '是', '否', '不詳', '', '是', '否', ''].forEach((header, index) => {
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).value = header;
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).border = defaultBorder;
  });
  rowIndex += 1;
  let elderIndex = 0;
  (clientStatement.elderStatistics || []).forEach((elderRow, index) => {
    if (elderRow.orderCount === 0) {
      return;
    }
    elderIndex += 1;
    worksheet.getCell(`A${rowIndex}`).value = elderIndex;
    worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`B${rowIndex}`).value = elderRow.name || '';
    worksheet.getCell(`B${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`B${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`B${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`C${rowIndex}`).value = elderRow.identificationCardId || '';
    worksheet.getCell(`C${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`C${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`C${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`D${rowIndex}`).value = getROCYear(elderRow.birthday) || '';
    worksheet.getCell(`D${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`D${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`D${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`E${rowIndex}`).value = elderRow.phoneNumber || '';
    worksheet.getCell(`E${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`E${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`E${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`F${rowIndex}`).value = elderRow.gender === 'male' ? 1 : '';
    worksheet.getCell(`F${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`F${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`F${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`G${rowIndex}`).value = elderRow.gender === 'female' ? 1 : '';
    worksheet.getCell(`G${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`G${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`G${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`H${rowIndex}`).value = ['2級', '3級'].includes(elderRow.cmsLevel) ? 1 : '';
    worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`H${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`H${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`I${rowIndex}`).value = ['4級', '5級', '6級'].includes(elderRow.cmsLevel) ? 1 : '';
    worksheet.getCell(`I${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`I${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`I${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`J${rowIndex}`).value = ['7級', '8級'].includes(elderRow.cmsLevel) ? 1 : '';
    worksheet.getCell(`J${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`J${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`J${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`K${rowIndex}`).value = elderRow.disadvantagedTypes === '低收' ? 1 : '';
    worksheet.getCell(`K${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`K${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`K${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`L${rowIndex}`).value = elderRow.disadvantagedTypes === '中低' ? 1 : '';
    worksheet.getCell(`L${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`L${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`L${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`M${rowIndex}`).value = elderRow.disadvantagedTypes === '近貧' ? 1 : '';
    worksheet.getCell(`M${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`M${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`M${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`N${rowIndex}`).value = elderRow.disadvantagedTypes === '一般' ? 1 : '';
    worksheet.getCell(`N${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`N${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`N${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`O${rowIndex}`).value = elderRow.isLivingAlone === 1 ? 1 : '';
    worksheet.getCell(`O${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`O${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`O${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`P${rowIndex}`).value = elderRow.isLivingAlone === 0 ? 1 : '';
    worksheet.getCell(`P${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`P${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`P${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`Q${rowIndex}`).value = elderRow.isLivingAlone === null || elderRow.isLivingAlone === undefined ? 1 : '';
    worksheet.getCell(`Q${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`Q${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`Q${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`R${rowIndex}`).value = elderRow.orderCount;
    worksheet.getCell(`R${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`R${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`R${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`S${rowIndex}`).value = elderRow.isIndigenous ? 1 : '';
    worksheet.getCell(`S${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`S${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`S${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`T${rowIndex}`).value = !elderRow.isIndigenous ? 1 : '';
    worksheet.getCell(`T${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`T${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`T${rowIndex}`).border = defaultBorder;
    worksheet.getCell(`U${rowIndex}`).value = '';
    worksheet.getCell(`U${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`U${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`U${rowIndex}`).border = defaultBorder;
    rowIndex += 1;
  });
  worksheet.getRow(rowIndex - 1).eachCell((cell) => {
    cell.border = { ...defaultBorder, bottom: { style: 'thin' } };
  });

  // 公式表格
  rowIndex = 3;
  worksheet.mergeCells(`X${rowIndex}:Y${rowIndex}`);
  worksheet.getCell(`X${rowIndex}`).value = '性別';
  worksheet.getCell(`X${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`X${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`X${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`Z${rowIndex}:AB${rowIndex}`);
  worksheet.getCell(`Z${rowIndex}`).value = '失能程度';
  worksheet.getCell(`Z${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`Z${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`Z${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`AC${rowIndex}:AF${rowIndex}`);
  worksheet.getCell(`AC${rowIndex}`).value = '福利身份';
  worksheet.getCell(`AC${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`AC${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`AC${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`AG${rowIndex}:AI${rowIndex}`);
  worksheet.getCell(`AG${rowIndex}`).value = '獨居與否';
  worksheet.getCell(`AG${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`AG${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`AG${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`AJ${rowIndex}`).value = '用餐\n數量';
  worksheet.getCell(`AJ${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
  worksheet.getCell(`AJ${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`AJ${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`AK${rowIndex}:AL${rowIndex}`);
  worksheet.getCell(`AK${rowIndex}`).value = '原住民身分';
  worksheet.getCell(`AK${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`AK${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`AK${rowIndex}`).border = defaultBorder;
  rowIndex += 1;
  ['男', '女', '輕', '中', '重', '低收', '中低', '近貧', '一般', '是', '否', '不詳', '', '是', '否'].forEach((header, index) => {
    worksheet.getCell(`${columnArray[index + 23]}${rowIndex}`).value = header;
    worksheet.getCell(`${columnArray[index + 23]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.getCell(`${columnArray[index + 23]}${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${columnArray[index + 23]}${rowIndex}`).border = defaultBorder;
  });
  rowIndex += 1;
  columnArray.slice(5, 20).forEach((column, index) => {
    worksheet.getCell(`${columnArray[index + 23]}${rowIndex}`).value = { formula: `=SUM(${column}:${column})` };
    worksheet.getCell(`${columnArray[index + 23]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${columnArray[index + 23]}${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${columnArray[index + 23]}${rowIndex}`).border =
      { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  });

  rowIndex = 2;
  // eslint-disable-next-line max-len
  ['低收入戶', '低收入戶', '低收入戶', '低收入戶', '低收入戶', '低收入戶', '中低收入戶', '中低收入戶', '中低收入戶', '中低收入戶', '中低收入戶', '中低收入戶', '近貧', '近貧', '近貧', '近貧', '近貧', '近貧', '一般戶', '一般戶', '一般戶', '一般戶', '一般戶', '一般戶', '獨居(本欄請獨立計算)', '獨居(本欄請獨立計算)', '原住民(本欄請獨立計算)', '原住民(本欄請獨立計算)', '低收入戶', '低收入戶', '低收入戶', '低收入戶', '低收入戶', '低收入戶', '中低收入戶', '中低收入戶', '中低收入戶', '中低收入戶', '中低收入戶', '中低收入戶', '近貧', '近貧', '近貧', '近貧', '近貧', '近貧', '一般戶', '一般戶', '一般戶', '一般戶', '一般戶', '一般戶', '獨居(本欄請獨立計算)', '獨居(本欄請獨立計算)', '原住民(本欄請獨立計算)', '原住民(本欄請獨立計算)']
    .forEach((header, index) => {
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).value = header;
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).font = { size: 12, name: fontName };
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).border = defaultBorder;
    });
  rowIndex += 1;
  // eslint-disable-next-line max-len
  ['輕度失能', '輕度失能', '中度失能', '中度失能', '重度失能', '重度失能', '輕度失能', '輕度失能', '中度失能', '中度失能', '重度失能', '重度失能', '輕度失能', '輕度失能', '中度失能', '中度失能', '重度失能', '重度失能', '輕度失能', '輕度失能', '中度失能', '中度失能', '重度失能', '重度失能', '小計', '小計', '小計', '小計', '輕度失能', '輕度失能', '中度失能', '中度失能', '重度失能', '重度失能', '輕度失能', '輕度失能', '中度失能', '中度失能', '重度失能', '重度失能', '輕度失能', '輕度失能', '中度失能', '中度失能', '重度失能', '重度失能', '輕度失能', '輕度失能', '中度失能', '中度失能', '重度失能', '重度失能', '小計', '小計', '小計', '小計']
    .forEach((header, index) => {
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).value = header;
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).font = { size: 12, name: fontName };
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).border = defaultBorder;
    });
  rowIndex += 1;
  // eslint-disable-next-line max-len
  ['男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女', '男', '女']
    .forEach((header, index) => {
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).value = header;
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).font = { size: 12, name: fontName };
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).border =
        { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
    });
  rowIndex += 1;
  (clientStatement.elderStatistics || []).forEach((_, index) => {
    [
      // eslint-disable-next-line max-len
      '=IF(AND(AND(F5<>"",H5<>""),K5<>""),1,0)', '=IF(AND(AND(G5<>"",H5<>""),K5<>""),1,0)', '=IF(AND(AND(F5<>"",I5<>""),K5<>""),1,0)', '=IF(AND(AND(G5<>"",I5<>""),K5<>""),1,0)', '=IF(AND(AND(F5<>"",J5<>""),K5<>""),1,0)', '=IF(AND(AND(G5<>"",J5<>""),K5<>""),1,0)',
      // eslint-disable-next-line max-len
      '=IF(AND(AND(F5<>"",H5<>""),L5<>""),1,0)', '=IF(AND(AND(G5<>"",H5<>""),L5<>""),1,0)', '=IF(AND(AND(F5<>"",I5<>""),L5<>""),1,0)', '=IF(AND(AND(G5<>"",I5<>""),L5<>""),1,0)', '=IF(AND(AND(F5<>"",J5<>""),L5<>""),1,0)', '=IF(AND(AND(G5<>"",J5<>""),L5<>""),1,0)',
      // eslint-disable-next-line max-len
      '=IF(AND(AND(F5<>"",H5<>""),M5<>""),1,0)', '=IF(AND(AND(G5<>"",H5<>""),M5<>""),1,0)', '=IF(AND(AND(F5<>"",I5<>""),M5<>""),1,0)', '=IF(AND(AND(G5<>"",I5<>""),M5<>""),1,0)', '=IF(AND(AND(F5<>"",J5<>""),M5<>""),1,0)', '=IF(AND(AND(G5<>"",J5<>""),M5<>""),1,0)',
      // eslint-disable-next-line max-len
      '=IF(AND(AND(F5<>"",H5<>""),N5<>""),1,0)', '=IF(AND(AND(G5<>"",H5<>""),N5<>""),1,0)', '=IF(AND(AND(F5<>"",I5<>""),N5<>""),1,0)', '=IF(AND(AND(G5<>"",I5<>""),N5<>""),1,0)', '=IF(AND(AND(F5<>"",J5<>""),N5<>""),1,0)', '=IF(AND(AND(G5<>"",J5<>""),N5<>""),1,0)',
      '=IF(AND(F5<>"",O5<>""),1,0)', '=IF(AND(G5<>"",O5<>""),1,0)', ' =IF(AND(F5<>"",S5<>""),1,0)', '=IF(AND(G5<>"",S5<>""),1,0)',
      // eslint-disable-next-line max-len
      '=IF(AND(AND(F5<>"",H5<>""),K5<>""),R5,0)', '=IF(AND(AND(G5<>"",H5<>""),K5<>""),R5,0)', '=IF(AND(AND(F5<>"",I5<>""),K5<>""),R5,0)', '=IF(AND(AND(G5<>"",I5<>""),K5<>""),R5,0)', '=IF(AND(AND(F5<>"",J5<>""),K5<>""),R5,0)', '=IF(AND(AND(G5<>"",J5<>""),K5<>""),R5,0)',
      // eslint-disable-next-line max-len
      '=IF(AND(AND(F5<>"",H5<>""),L5<>""),R5,0)', '=IF(AND(AND(G5<>"",H5<>""),L5<>""),R5,0)', '=IF(AND(AND(F5<>"",I5<>""),L5<>""),R5,0)', '=IF(AND(AND(G5<>"",I5<>""),L5<>""),R5,0)', '=IF(AND(AND(F5<>"",J5<>""),L5<>""),R5,0)', '=IF(AND(AND(G5<>"",J5<>""),L5<>""),R5,0)',
      // eslint-disable-next-line max-len
      '=IF(AND(AND(F5<>"",H5<>""),M5<>""),R5,0)', '=IF(AND(AND(G5<>"",H5<>""),M5<>""),R5,0)', '=IF(AND(AND(F5<>"",I5<>""),M5<>""),R5,0)', '=IF(AND(AND(G5<>"",I5<>""),M5<>""),R5,0)', '=IF(AND(AND(F5<>"",J5<>""),M5<>""),R5,0)', '=IF(AND(AND(G5<>"",J5<>""),M5<>""),R5,0)',
      // eslint-disable-next-line max-len
      '=IF(AND(AND(F5<>"",H5<>""),N5<>""),R5,0)', '=IF(AND(AND(G5<>"",H5<>""),N5<>""),R5,0)', '=IF(AND(AND(F5<>"",I5<>""),N5<>""),R5,0)', '=IF(AND(AND(G5<>"",I5<>""),N5<>""),R5,0)', '=IF(AND(AND(F5<>"",J5<>""),N5<>""),R5,0)', '=IF(AND(AND(G5<>"",J5<>""),N5<>""),R5,0)',
      '=IF(AND(F5<>"",O5<>""),R5,0)', '=IF(AND(G5<>"",O5<>""),R5,0)', ' =IF(AND(F5<>"",S5<>""),R5,0)', '=IF(AND(G5<>"",S5<>""),R5,0)',
    ].forEach((formula, index) => {
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).value = { formula: formula.replace(/5/g, rowIndex) };
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${columnArray[index + 39]}${rowIndex}`).font = { size: 12, name: fontName };
    });
    rowIndex += 1;
  });

  return worksheet;
}

function getWorksheetDeliveryStaff(worksheet, deliveryStaffs = [], orders = [], clientName, yearMonth, monthLabel) {
  const deliveryStaffRows = [];
  deliveryStaffs
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(({
      id,
      name,
      identificationCardId,
      address,
    }) => {
      const deliveryStaffOrders = orders
        .filter(({ deliveryStaffId }) => deliveryStaffId === id);
      if (deliveryStaffOrders.length === 0) {
        return;
      }
      const lunchs = [];
      const dinners = [];
      deliveryStaffOrders.sort(sortBy('deliveryBy')).forEach(({ mealSlot, date }) => {
        const day = date.split('-')[2];
        if (mealSlot === 'lunch') {
          if (!lunchs.includes(day)) {
            lunchs.push(day);
          }
        } else {
          if (!dinners.includes(day)) {
            dinners.push(day);
          }
        }
      });
      deliveryStaffRows.push({
        姓名: name,
        身分證字號: identificationCardId,
        戶籍地址: formatAddress(address, { includeZipCode: false }),
        供餐次數: deliveryStaffOrders.length,
        服務日期: { lunchs, dinners },
      });
    });

  const fontName = 'Calibri';
  const range = 'A:AZ';
  const columnArray = buildColumnsArray(range);

  const ROCYear = getROCYear(yearMonth);

  let rowIndex = 1;

  const columnWidth = [6, 10, 14, 36, 4.67, 40, 5.33, 11.83, 11.5, 31.83];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  const defaultBorder = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

  worksheet.mergeCells(`A${rowIndex}:J${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `${clientName}`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName, underline: true };
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:J${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `「臺北市政府社會局 ${ROCYear} 年度補助辦理失能者營養餐飲服務計畫」`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:J${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `${ROCYear}年${monthLabel}份送餐人員誤餐交通費請領清冊`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
  rowIndex += 1;

  ['編號', '姓名', '身分證統一編號', '戶籍地址(含里鄰)', '時段', '服務日期',
    '次數', '金額', '總金額', '蓋章'].forEach((header, index) => {
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).value = header;
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`${columnArray[index]}${rowIndex}`).border = defaultBorder;
  });

  rowIndex += 1;
  deliveryStaffRows.forEach((deliveryStaff, index) => {
    worksheet.mergeCells(`A${rowIndex}:A${rowIndex + 1}`);
    worksheet.getCell(`A${rowIndex}`).value = index + 1;
    worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
    worksheet.mergeCells(`B${rowIndex}:B${rowIndex + 1}`);
    worksheet.getCell(`B${rowIndex}`).value = deliveryStaff['姓名'];
    worksheet.getCell(`B${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`B${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`B${rowIndex}`).border = defaultBorder;
    worksheet.mergeCells(`C${rowIndex}:C${rowIndex + 1}`);
    worksheet.getCell(`C${rowIndex}`).value = deliveryStaff['身分證字號'];
    worksheet.getCell(`C${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`C${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`C${rowIndex}`).border = defaultBorder;
    worksheet.mergeCells(`D${rowIndex}:D${rowIndex + 1}`);
    worksheet.getCell(`D${rowIndex}`).value = deliveryStaff['戶籍地址'];
    worksheet.getCell(`D${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.getCell(`D${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`D${rowIndex}`).border = defaultBorder;
    let total = 0;
    if (deliveryStaff['服務日期'].lunchs.length !== 0 && deliveryStaff['服務日期'].dinners.length !== 0) {
      worksheet.getCell(`E${rowIndex}`).value = '午';
      worksheet.getCell(`E${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      worksheet.getCell(`E${rowIndex}`).font = { size: 12, name: fontName };
      worksheet.getCell(`E${rowIndex}`).border = defaultBorder;
      worksheet.getCell(`F${rowIndex}`).value = deliveryStaff['服務日期'].lunchs.join(',');
      worksheet.getCell(`F${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getCell(`F${rowIndex}`).font = { size: 8, name: fontName };
      worksheet.getCell(`F${rowIndex}`).border = defaultBorder;
      worksheet.getCell(`G${rowIndex}`).value = deliveryStaff['服務日期'].lunchs.length;
      worksheet.getCell(`G${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`G${rowIndex}`).font = { size: 14, name: fontName };
      worksheet.getCell(`G${rowIndex}`).border = defaultBorder;
      worksheet.getCell(`H${rowIndex}`).value = deliveryStaff['服務日期'].lunchs.length * deliveryStaffFeePerTrip;
      total += deliveryStaff['服務日期'].lunchs.length * deliveryStaffFeePerTrip;
      worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`H${rowIndex}`).font = { size: 14, name: fontName };
      worksheet.getCell(`H${rowIndex}`).border = defaultBorder;
      worksheet.getCell(`E${rowIndex + 1}`).value = '晚';
      worksheet.getCell(`E${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      worksheet.getCell(`E${rowIndex + 1}`).font = { size: 12, name: fontName };
      worksheet.getCell(`E${rowIndex + 1}`).border = defaultBorder;
      worksheet.getCell(`F${rowIndex + 1}`).value = deliveryStaff['服務日期'].dinners.join(',');
      worksheet.getCell(`F${rowIndex + 1}`).alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getCell(`F${rowIndex + 1}`).font = { size: 8, name: fontName };
      worksheet.getCell(`F${rowIndex + 1}`).border = defaultBorder;
      worksheet.getCell(`G${rowIndex + 1}`).value = deliveryStaff['服務日期'].dinners.length;
      worksheet.getCell(`G${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`G${rowIndex + 1}`).font = { size: 14, name: fontName };
      worksheet.getCell(`G${rowIndex + 1}`).border = defaultBorder;
      worksheet.getCell(`H${rowIndex + 1}`).value = deliveryStaff['服務日期'].dinners.length * deliveryStaffFeePerTrip;
      total += deliveryStaff['服務日期'].dinners.length * deliveryStaffFeePerTrip;
      worksheet.getCell(`H${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`H${rowIndex + 1}`).font = { size: 14, name: fontName };
      worksheet.getCell(`H${rowIndex + 1}`).border = defaultBorder;
    } else {
      worksheet.mergeCells(`E${rowIndex}:E${rowIndex + 1}`);
      worksheet.getCell(`E${rowIndex}`).value = deliveryStaff['服務日期'].lunchs.length !== 0 ? '午' : '晚';
      worksheet.getCell(`E${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      worksheet.getCell(`E${rowIndex}`).font = { size: 12, name: fontName };
      worksheet.getCell(`E${rowIndex}`).border = defaultBorder;
      worksheet.mergeCells(`F${rowIndex}:F${rowIndex + 1}`);
      worksheet.getCell(`F${rowIndex}`).value = deliveryStaff['服務日期'].lunchs.length !== 0 ?
        deliveryStaff['服務日期'].lunchs.join(',') : deliveryStaff['服務日期'].dinners.join(',');
      worksheet.getCell(`F${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getCell(`F${rowIndex}`).font = { size: 8, name: fontName };
      worksheet.getCell(`F${rowIndex}`).border = defaultBorder;
      worksheet.mergeCells(`G${rowIndex}:G${rowIndex + 1}`);
      worksheet.getCell(`G${rowIndex}`).value = deliveryStaff['服務日期'].lunchs.length !== 0 ?
        deliveryStaff['服務日期'].lunchs.length : deliveryStaff['服務日期'].dinners.length;
      worksheet.getCell(`G${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`G${rowIndex}`).font = { size: 14, name: fontName };
      worksheet.getCell(`G${rowIndex}`).border = defaultBorder;
      total = deliveryStaff['服務日期'].lunchs.length !== 0 ?
        deliveryStaff['服務日期'].lunchs.length * deliveryStaffFeePerTrip :
        deliveryStaff['服務日期'].dinners.length * deliveryStaffFeePerTrip;
      worksheet.mergeCells(`H${rowIndex}:H${rowIndex + 1}`);
      worksheet.getCell(`H${rowIndex}`).value = total;
      worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`H${rowIndex}`).font = { size: 14, name: fontName };
      worksheet.getCell(`H${rowIndex}`).border = defaultBorder;
    }
    worksheet.mergeCells(`I${rowIndex}:I${rowIndex + 1}`);
    worksheet.getCell(`I${rowIndex}`).value = total;
    worksheet.getCell(`I${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`I${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`I${rowIndex}`).border = defaultBorder;
    worksheet.mergeCells(`J${rowIndex}:J${rowIndex + 1}`);
    worksheet.getCell(`J${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`J${rowIndex}`).font = { size: 14, name: fontName };
    worksheet.getCell(`J${rowIndex}`).border = defaultBorder;
    rowIndex += 2;
  });

  worksheet.mergeCells(`A${rowIndex}:F${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = '合           計';
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`G${rowIndex}`).value = { formula: `=SUM(G5:G${deliveryStaffRows.length * 2 - 1 + 5})` };
  worksheet.getCell(`G${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`G${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`G${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`H${rowIndex}`).value = { formula: `=SUM(H5:H${deliveryStaffRows.length * 2 - 1 + 5})` };
  worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`H${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`H${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`I${rowIndex}`).value = { formula: `=SUM(I5:I${deliveryStaffRows.length * 2 - 1 + 5})` };
  worksheet.getCell(`I${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`I${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`I${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`J${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`J${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`J${rowIndex}`).border = defaultBorder;
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:J${rowIndex}`);
  // eslint-disable-next-line max-len
  worksheet.getCell(`A${rowIndex}`).value = `填表人：                                    主辦會計：                                                單位主管：`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`A${rowIndex}`).border = { top: { style: 'thin' } };

  return worksheet;
}
