import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { request } from 'utilities/graph';
import Issue from './Issue';
import {
  createIssue,
  createIssueComment,
  updateIssue,
  updateDeliveryStaffOrder,
} from 'graphql/mutations';
import cache from 'utilities/cache';
import { issueStatus as allIssueStatus } from '@silvergatedelivery/constants';

export default function DeliveryStaffOrderIssueDialog({
  data: inData,
  buttonStyle = {},
  buttonSize = 'medium',
  onUpdate,
  disabled = false,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [issue, setIssue] = useState(inData.issue.items[0]);
  const [issueDescription, setIssueDescription] = useState('');
  const [commentText, setCommentText] = useState('');
  const [issueStatus, setIssueStatus] = useState(allIssueStatus[0].value);

  useEffect(() => {
    setIssue(inData.issue.items[0]);
    if (inData.issue.items[0]) {
      setIssueStatus(inData.issue.items[0].status);
    }
  }, [inData]);

  const handleCreateIssue = async () => {
    setIsLoading(true);
    const username = cache.get('app:username');
    const userName = cache.get('app:userName');

    const issueInput = {
      priority: 1,
      status: issueStatus,
      reportedBy: userName,
      county: inData.county,
      orderId: inData.orderId,
      elderId: inData.elderId,
      clientId: inData.clientId,
      deliveryStaffId: inData.deliveryStaffId,
      deliveryStaffOrderId: inData.id,
      type: '送餐關懷追蹤',
      summary: '送餐關懷追蹤',
      createdBy: username,
      updatedBy: username,
      expirationUnixTime: Math.round(Date.now() / 1000) + 365 * 86400,
      description: issueDescription,
    };

    const input = {
      id: inData.id,
      hasIssue: true,
      updatedBy: username,
    };

    const [{ data: { createIssue: updatedIssue } }] = await Promise.all([
      request(createIssue, { input: issueInput }),
      request(updateDeliveryStaffOrder, { input }),
    ]);

    onUpdate({ ...inData, hasIssue: true, issue: { items: [updatedIssue] } });

    setIsLoading(false);
    handleClose();
  };

  const handleUpdateIssue = async () => {
    setIsLoading(true);
    const username = cache.get('app:username');
    const userName = cache.get('app:userName');
    const now = new Date().toISOString();

    if (commentText) {
      const comment = {
        issueId: issue.id,
        username: userName,
        content: commentText,
        expirationUnixTime: Math.round(Date.now() / 1000) + 180 * 86400,
        createdAt: now,
        createdBy: username,
        updatedAt: now,
        updatedBy: username,
      };

      await request(createIssueComment, { input: comment });
    }
    if (issueStatus !== issue.status) {
      const now = new Date().toISOString();
      const updatedIssue = {
        id: issue.id,
        status: issueStatus,
        updatedAt: now,
        updatedBy: username,
      };
      await request(updateIssue, { input: updatedIssue });

      const newIssue = Object.assign(issue, updatedIssue);
      onUpdate({ ...inData, issue: { items: [newIssue] } });
    }
    setIsLoading(false);
    handleClose();
  };

  const handleClose = () => {
    setCommentText('');
    setIssueDescription('');
    setIssueStatus(allIssueStatus[0].value);
    setOpen(false);
  };

  const issueColor = ({ status }) => (allIssueStatus.find(({ value }) => value === status) || {}).color;
  const tooltipTitle = () => {
    let label;
    if (issue) {
      label = (allIssueStatus.find(({ value }) => value === issue.status) || {}).label;
    }
    return label ? `追蹤(${label})`: '追蹤';
  };

  return (
    <Fragment>
      <Tooltip title={tooltipTitle()} style={{}}>
        <IconButton
          data-testid={'edit'}
          aria-label={'edit'}
          onClick={() => setOpen(true)}
          size={buttonSize}
          disabled={disabled}
        >
          { issue ?
            <BookmarkIcon fontSize='inherit' style={{ color: issueColor(issue) }} /> :
            <BookmarkBorderIcon fontSize='inherit' />
          }
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={(e, reason)=>{
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('送餐關懷')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            {issue ?
              <Grid item>
                <Issue
                  issue={issue}
                  onUpdateComment={(commentText) => setCommentText(commentText)}
                  onUpdateIssueStatus={(issueStatus) => setIssueStatus(issueStatus)}
                />
              </Grid> :
              <>
                <Grid item>
                  <FormControl>
                    <InputLabel>狀態</InputLabel>
                    <Select
                      value={issueStatus}
                      onChange={(event) => setIssueStatus(event.target.value)}
                    >
                      {allIssueStatus.map(({ label, value }) => (
                        <MenuItem key={label} value={value} >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    margin="dense"
                    id="diary"
                    label="追蹤事項"
                    type="text"
                    value={issueDescription}
                    fullWidth
                    required
                    multiline={true}
                    minRows={5}
                    onChange={(e) => setIssueDescription(e.target.value) }
                    style={{ width: 360 }}
                  />
                </Grid>
              </>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" disabled={isLoading}>
            取消
          </Button>
          {issue ?
            <Button onClick={handleUpdateIssue} color="primary" variant="contained"
              disabled={isLoading || (!commentText && issue.status === issueStatus)}>
              送出
            </Button> :
            <Button onClick={handleCreateIssue} color="primary" variant="contained" disabled={isLoading || !issueDescription}>
              送出
            </Button>
          }
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

DeliveryStaffOrderIssueDialog.propTypes = {
  data: PropTypes.object,
  buttonStyle: PropTypes.object,
  buttonSize: PropTypes.string,
  onUpdate: PropTypes.func,
  disabled: PropTypes.bool,
};
