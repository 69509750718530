import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import LabelWithToolTip from '../LabelWithToolTip';
import {
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import { asyncListAll, request } from 'utilities/graph';

import byDistrict from './workbooks/byDistrict';

import {
  getEldersByClientByStatus,
  getOrdersByClientByDate,
} from '../queries';

import { processShareOrder, processDisadvantagedTypes } from '../CountyPrsReport/workbooks/helpers';

const calculations = [{
  label: '依送餐地區',
  func: byDistrict,
}];

const monthOptions = Array(10).fill('').map((x, index) => {
  return moment().add(-index, 'months').format('YYYY-MM');
});

export default function DeliveryStaffSalary({ clientOptions = [] }) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);
  const [selectedCalculation, setSelectedCalculation] = useState(calculations[0].label);
  const [selectedClientId, setSelectedClientId] = useState();
  const [includeInactiveElders, setIncludeInactiveElders] = useState(false);
  const [includeNonDisElders, setIncludeNonDisElders] = useState(false);
  const [includeUndeliveredOrders, setIncludeUndeliveredOrders] = useState(false);
  const [excludeNonFoodDeliveryOrders, setExcludeNonFoodDeliveryOrders] = useState(false);

  const collectData = async () => {
    const from = moment(selectedMonth).tz(TIME_ZONE).startOf('month');
    const to = moment(selectedMonth).tz(TIME_ZONE).endOf('month');
    const fromDate = from.format('YYYY-MM-DD');
    const toDate = to.format('YYYY-MM-DD');
    const fromISOString = from.toISOString();
    const toISOString = to.toISOString();
    // const monthString = from.format('YYYY/MM');
    const period = `${fromDate}__${toDate}`;
    const {
      id: clientId,
      name,
      facilitySettings: { dba },
    } = clientOptions.find(({ id }) => id === selectedClientId);

    const clientName = dba || name;

    global.logger.debug({
      selectedClientId,
      period,
      selectedMonth,
      fromDate,
      toDate,
      fromISOString,
      toISOString,
    });

    const [
      elders,
      orders,
    ] = await Promise.all([
      asyncListAll(getEldersByClientByStatus, {
        clientId,
        status: includeInactiveElders ? undefined : { eq: '使用中' },
        filter: includeNonDisElders ? undefined : {
          isDisadvantaged: {
            eq: 1,
          },
        },
        limit: 1000,
      }),
      asyncListAll(getOrdersByClientByDate, {
        clientId,
        date: {
          between: [fromDate, toDate],
        },
        limit: 1000,
      }),
    ]);

    const deliveryStaffIds = [];
    orders.forEach(({ deliveryStaffId, elderId }) => {
      if (deliveryStaffId && !deliveryStaffIds.includes(deliveryStaffId)) {
        deliveryStaffIds.push(deliveryStaffId);
      }
      const elder = elders.find(({ id }) => id === elderId);
      if (elder?.defaultDeliveryStaffId && !deliveryStaffIds.includes(elder.defaultDeliveryStaffId)) {
        deliveryStaffIds.push(elder.defaultDeliveryStaffId);
      }
    });

    const deliveryStaffs = await Promise.all(deliveryStaffIds.map(async (id) => {
      const { data: { getDeliveryStaff: data } } = await request( /* GraphQL */ `
        query GetDeliveryStaff($id: ID!) {
          getDeliveryStaff(id: $id) {
            id
            name
            username
            identificationCardId
            birthday
            address {
              county
              district
              street
            }
          }
        }
      `, { id });

      return data;
    }));

    processShareOrder(elders, orders);
    processDisadvantagedTypes(selectedMonth, elders);

    const matched = calculations.find(({ label }) => label === selectedCalculation);
    if (matched) {
      await matched.func({ elders, orders, deliveryStaffs, selectedMonth, clientName,
        includeUndeliveredOrders, excludeNonFoodDeliveryOrders });
    }
    return;
  };

  const triggerDownload = async () => {
    try {
      setMessage('');
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (clientOptions[0]) {
      setSelectedClientId(clientOptions[0].id);
    }
  }, [clientOptions]);

  return (
    <div>
      <h3>送餐大使薪資表</h3>
      <div>
        <FormControl style={{ minWidth: 360, marginRight: 16 }}>
          <AutoSelect
            id="selected-facility"
            options={clientOptions.map(({ name }) => name)}
            values={clientOptions.map(({ id }) => id)}
            required={true}
            value={selectedClientId}
            label="機構"
            onChange={(value) => {
              setSelectedClientId(value);
            }}
          />
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-month">月份</InputLabel>
          <Select
            labelId="selected-month-label"
            id="selected-month"
            value={selectedMonth}
            onChange={(event) => setSelectedMonth(event.target.value)}
            disabled={isLoading}
          >
            {monthOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-calculation">薪資計算方法</InputLabel>
          <Select
            labelId="selected-calculation-label"
            id="selected-calculation"
            value={selectedCalculation}
            onChange={(event) => setSelectedCalculation(event.target.value)}
            disabled={isLoading}
          >
            {calculations.map(({ label }) => (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeNonDisElders}
              name={'includeNonDisElders'}
              value={includeNonDisElders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeNonDisElders(e.target.checked);
              }}
            />
          }
          label={<LabelWithToolTip
            label='包含自費戶'
            tooltip='若送餐對象設定中 "弱勢長輩(非自費戶)" 選擇 "否" 則為自費戶'
          />}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={includeInactiveElders}
              name={'includeInactiveElders'}
              value={includeInactiveElders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeInactiveElders(e.target.checked);
              }}
            />
          }
          label={'包含已停用的個案'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={includeUndeliveredOrders}
              name={'includeUndeliveredOrders'}
              value={includeUndeliveredOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeUndeliveredOrders(e.target.checked);
              }}
            />
          }
          label={'包含未送達的訂單'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={excludeNonFoodDeliveryOrders}
              name={'excludeNonFoodDeliveryOrders'}
              value={excludeNonFoodDeliveryOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setExcludeNonFoodDeliveryOrders(e.target.checked);
              }}
            />
          }
          label={'排除非送餐的訂單'}
        />
      </div>

      <Button
        variant="outlined"
        onClick={triggerDownload}
        disabled={!selectedClientId || isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
      </Button>
      <p>
        {message}
      </p>
    </div>
  );
}

DeliveryStaffSalary.propTypes = {
  clientOptions: PropTypes.array,
};
