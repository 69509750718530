const uiSchema = {
  'status': {
    'ui:width': '40%',
  },
  'category': {
    'ui:width': '60%',
  },
};

export default uiSchema;
