import React from 'react';
import EditButton from 'forms/components/EditButton';
import FormForm from './FormForm';

export default function FormEditButton({ ...props }) {
  return (
    <EditButton
      title='表單'
      form={FormForm}
      {...props}
    />);
}
