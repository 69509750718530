import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import {
  createElderForm,
} from 'graphql/mutations';
import uiSchema from './uiSchema';
import FileUploader from 'views/FacilityAdmin/components/Forms/FileUploader';
import cache from 'utilities/cache';
import { toastr } from 'react-redux-toastr';
import moment from 'moment-timezone';

export default function ElderFormUploader({ formData: inFormData, onUpdate, elderId, ...props }) {
  // load here for translation purpose
  const { default: schema } = useMemo(() => require('./schema.js'), []);
  const [currentFileS3Key, setCurrentFileS3Key] = useState();
  const [newFileS3Key, setNewFileS3Key] = useState();

  const createFunc = async (newData) => {
    console.log(newData);
    console.log(newFileS3Key);
    if (!newFileS3Key) {
      toastr.error('請選擇要上傳的檔案');
      throw new Error('abort');
    }
    const now = moment().toISOString();
    const username = cache.get('app:username');
    const input = {
      elderId,
      name: newData.name,
      fileS3Key: newFileS3Key,
      createdAt: now,
      createdBy: username,
      updatedAt: now,
      updatedBy: username,
    };
    const { data: { createElderForm: data } } = await request(createElderForm, { input });
    onUpdate && onUpdate(data);
  };

  const updateFunc = async (data) => {
  };

  useEffect(() => {
    if (inFormData && inFormData.id) {
      setCurrentFileS3Key(inFormData.fileS3Key);
      setNewFileS3Key(inFormData.fileS3Key);
    }
  }, [inFormData]);

  const now = moment().toISOString();

  return (
    <div>
      <DataForm
        schema={schema}
        uiSchema={uiSchema}
        createFunc={createFunc}
        updateFunc={updateFunc}
        formData={inFormData}
        dirty={true}
        {...props}
      >
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          <FileUploader
            s3Prefix={`elders/${elderId}/${now}`}
            s3Key={currentFileS3Key}
            onUpdateS3Key={setNewFileS3Key}
          />
        </Grid>
      </DataForm>
    </div>
  );
}

ElderFormUploader.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onUpdate: PropTypes.func,
  elderId: PropTypes.string,
};
