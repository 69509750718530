export default [
  {
    label: '無',
    value: null,
  },
  {
    label: '送餐大使收',
    value: '送餐大使收',
  },
  {
    label: '居服員收',
    value: '居服員收',
  },
  {
    label: '匯款-督導通知',
    value: '匯款-督導通知',
  },
  {
    label: '匯款-簡訊通知',
    value: '匯款-簡訊通知',
  },
  {
    label: '中心繳費',
    value: '中心繳費',
  },
];
