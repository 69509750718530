import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { Storage } from 'aws-amplify';
// import { v1 as uuidv1 } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
// import Tooltip from '@material-ui/core/Tooltip';
// import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
// import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import { Colors } from '@silvergatedelivery/constants';
import PersonPinIcon from '@material-ui/icons/PersonPin';
// import PersonIcon from '@material-ui/icons/Person';
// import PublicIcon from '@material-ui/icons/Public';
// import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import Tooltip from '@material-ui/core/Tooltip';
import UserAvatar from 'components/UserAvatar';
import LinkButton from 'components/Table/LinkButton';
import {
  formatDatetime,
  formatNote,
  formatTime,
} from 'utilities/format';
import { updateDeliveryStaffOrder } from 'graphql/mutations';
import { request } from 'utilities/graph';
import DeliveryStaffOrderPhoto from 'components/DeliveryStaffOrderPhoto';
import DeliveryStaffOrderDiaryCardDialog from './DeliveryStaffOrderDiaryCardDialog';
import DeliveryStaffOrderIssueDialog from './DeliveryStaffOrderIssueDialog';
import { deliveryResult } from '@silvergatedelivery/constants';
import cache from 'utilities/cache';
import Issue from './Issue';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  imgContainer: {
    textAlign: 'center',
    // backgroundColor: Colors.background.light,
  },
  img: {
    minHeight: 150,
    height: 150,
    width: 'auto',
    cursor: 'pointer',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    // marginRight: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function DeliveryStaffOrderDiaryCard({
  deliveryStaffOrder: inDeliveryStaffOrder,
  hideNames = false,
  onUpdate,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [deliveryStaffOrder, setDeliveryStaffOrder] = useState();
  const visitorMode = !!deliveryStaffOrder?.visitorMode;

  const { deliveryResult: inDeliveryResult } = deliveryStaffOrder || {};
  const result = (deliveryResult.find(({ value }) => value === inDeliveryResult) || {}).label;
  const checklists = [
    {
      label: `${t('送餐')}完成${result ? '(' + result + ')' : ''}`,
      key: 'checkListIsComplete',
    },
    {
      label: t('生活提醒(氣候、保暖、補充水份)'),
      key: 'checkListReminder',
    },
    {
      label: t('跟長輩聊天關心(身體、生活)'),
      key: 'checkListChat',
    },
  ];

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // const handlePublish = async () => {
  //   if (!window.confirm(`確定要公開發布此篇${t('送餐關懷')}？`)) return;

  //   const username = cache.get('app:username');
  //   const now = new Date().toISOString();

  //   const {
  //     id: deliveryStaffOrderId,
  //     deliveryStaffId,
  //     photoS3Keys,
  //     diary: content,
  //     checkListIsComplete,
  //     checkListReminder,
  //     checkListTransfer,
  //     checkListChat,
  //   } = deliveryStaffOrder;

  //   const {
  //     county,
  //     elder,
  //   } = order;

  //   const id = uuidv1();

  //   const newPhotoS3Keys = photoS3Keys;

  //   const blogData = {
  //     id,
  //     status: 'published',
  //     title: `${county}${t('送餐關懷')} - ${elder.nickname || (elder.name[0] + 'ＸＸ')}`,
  //     content,
  //     url: `/blogs/${deliveryStaffOrderId}`,
  //     photoS3Keys: newPhotoS3Keys,
  //     deliveryStaffId,
  //     deliveryStaffOrderId,
  //     checkListIsComplete,
  //     checkListReminder,
  //     checkListTransfer,
  //     checkListChat,
  //     createdAt: now,
  //     createdBy: username,
  //     updatedAt: now,
  //     updatedBy: username,
  //   };

  //   await request(createBlog, { input: blogData });

  //   const toUpdateDeliveryStaffOrderData = {
  //     id: deliveryStaffOrderId,
  //     isPublished: true,
  //     blogId: id,
  //   };

  //   await request(updateDeliveryStaffOrder, {
  //     input: toUpdateDeliveryStaffOrderData,
  //   });

  //   setDeliveryStaffOrder({
  //     ...deliveryStaffOrder,
  //     ...toUpdateDeliveryStaffOrderData,
  //   });
  // };

  useEffect(() => {
    setDeliveryStaffOrder(inDeliveryStaffOrder);
  }, [inDeliveryStaffOrder]);

  if (!deliveryStaffOrder) return null;

  const updateFavorite = async () => {
    const newDeliverStaffOrder = JSON.parse(JSON.stringify(deliveryStaffOrder));
    newDeliverStaffOrder.favorite = !deliveryStaffOrder.favorite;
    const username = cache.get('app:username');

    const { id, favorite } = newDeliverStaffOrder;
    const input = {
      id,
      favorite,
      updatedBy: username,
    };

    // update deliveryStaffOrder
    await request(updateDeliveryStaffOrder, { input });
    setDeliveryStaffOrder(newDeliverStaffOrder);
    onUpdate && onUpdate(newDeliverStaffOrder);
  };

  const {
    deliveryStartedAt,
    deliveryDeliveredAt,
    deliveryCompletedAt,
    deliveryStaff,
    deliveryStaffId,
    diary,
    orderId,
    elderId,
    // clientId,
    // isPublished,
    photoS3Keys,
    // populated field
    thumbnail,
    order,
    elderDementiaSurvey,
    favorite,
    issue,
  } = deliveryStaffOrder;
  const { username, name } = deliveryStaff;
  global.logger.debug(deliveryStaffOrder);
  const deliveryStaffName = hideNames ? (name[0] + `姓${t('送餐大使')}`) : name;
  const elderName = hideNames ? (order.elder.name[0] + '姓長輩') : order.elder.name;

  const refreshImage = () => {
    global.logger.debug('refreshImage?', deliveryStaffOrder);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <UserAvatar
            username={username}
            name={deliveryStaffName}
          />
        }
        action={
          <div style={{ display: 'flex', flexDirection: 'column' }} className='no-print' >
            {!visitorMode && <DeliveryStaffOrderDiaryCardDialog
              data={deliveryStaffOrder}
              onUpdate={setDeliveryStaffOrder}
            />}
            <Tooltip title={'收藏'} style={{}}>
              <IconButton
                data-testid={'favorite'}
                aria-label={'favorite'}
                onClick={updateFavorite}
                size={'medium'}
                disabled={visitorMode}
              >
                {favorite ?
                  <FavoriteIcon fontSize={'inherit'} color='secondary' /> :
                  <FavoriteBorderIcon fontSize={'inherit'} />
                }
              </IconButton>
            </Tooltip>
            <DeliveryStaffOrderIssueDialog
              data={deliveryStaffOrder}
              onUpdate={(newDeliverStaffOrder) => {
                setDeliveryStaffOrder(newDeliverStaffOrder);
                onUpdate && onUpdate(newDeliverStaffOrder);
              }}
              disabled={visitorMode}
            />
          </div>
        }
        title={deliveryStaffName}
        subheader={formatDatetime(deliveryCompletedAt)}
        style={{ maxHeight: 72 }}
      />
      <div className={classes.imgContainer}>
        <img src={thumbnail} className={classes.img} onClick={refreshImage}/>
      </div>
      {/* <CardMedia
        className={classes.media}
        image={imgUri}
        title={'照片'}
      /> */}
      <CardContent>
        <Typography variant="body1" color="textPrimary" component="p" style={{ marginBottom: 8 }}>
          {order.county} - {elderName}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {t('送餐')}時間 {formatTime(deliveryStartedAt)} - {formatTime(deliveryDeliveredAt)}
        </Typography>
        {checklists.map(({ label, key })=>(
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }} key={key}>
            {deliveryStaffOrder[key] ? <CheckBoxIcon color="secondary" /> :
              <CheckBoxOutlineBlankIcon />}
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginLeft: 10 }}>{label}</Typography>
          </div>
        ))}
        {elderDementiaSurvey && elderDementiaSurvey.items && elderDementiaSurvey.items.map(({ response, dementiaQuestionnaire }, index) => {
          const question = dementiaQuestionnaire.items[0].question;
          if (response === 'completed') {
            return (
              <div style={{ display: 'flex', marginTop: 10, flexDirection: 'column' }} key={`dementia_${index}`}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckBoxIcon color="secondary" />
                  <Typography variant="body2" color="textSecondary" component="p" style={{ marginLeft: 10 }}>失智友善宣導</Typography>
                </div>
                <Typography variant="caption" color="textSecondary" component="p" style={{ marginLeft: 40 }}>{question}</Typography>
              </div>
            );
          }
        })}
        <Typography variant="body1" color="textPrimary" component="p" style={{ marginTop: 10 }}>
          日誌
        </Typography>
        {formatNote(diary || '無')}
      </CardContent>
      {!visitorMode &&
      <CardActions disableSpacing>
        <LinkButton
          path={`/order/${orderId}`}
          label="開啟訂單頁面"
          newTab={true}
          icon={<ListAltIcon />}
          size="medium"
        />
        <LinkButton
          path={`/deliveryStaff/${deliveryStaffId}?tab=${t('送餐關懷')}`}
          label={`開啟${t('送餐大使')}頁面`}
          newTab={true}
          icon={<MotorcycleIcon />}
          size="medium"
        />
        <LinkButton
          path={`/elder/${elderId}?tab=${t('送餐關懷')}`}
          label={`開啟${t('送餐對象')}頁面`}
          newTab={true}
          icon={<PersonPinIcon />}
          size="medium"
        />
        {/* <LinkButton
          path={`/client/${clientId}?tab=${t('送餐關懷')}`}
          label="開啟客戶頁面"
          newTab={true}
          icon={<PersonIcon />}
          size="large"
        /> */}
        {/* <Tooltip
          title={isPublished ? '已公開' : '設定為公開'}
          placement="bottom"
        >
          <IconButton
            onClick={handlePublish}
            disabled={isPublished}
          >
            {isPublished ? <PublicIcon /> : <OpenInBrowserIcon /> }
          </IconButton>
        </Tooltip> */}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid container>
          {
            expanded && (photoS3Keys || []).map((s3Key)=>(
              <Grid item xs={4} key={s3Key} style={{ height: 100 }}>
                <DeliveryStaffOrderPhoto s3Key={s3Key} />
              </Grid>
            ))
          }
          { issue.items[0] &&
          <Grid item xs={12}>
            <Issue
              issue={issue.items[0]}
              timelineOnly={true}
            />
          </Grid>
          }
        </Grid>
      </Collapse>
    </Card>
  );
}

DeliveryStaffOrderDiaryCard.propTypes = {
  deliveryStaffOrder: PropTypes.object,
  hideNames: PropTypes.bool,
  onUpdate: PropTypes.func,
};
