import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
// import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import zhLocale from '@fullcalendar/core/locales/zh-tw';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import OrderDialog from 'components/Order/OrderDialog';
import { getOrderEvents, getRestaurantScheduleEvents } from './helpers';
import cache from 'utilities/cache';
import moment from 'moment';
import { orderStatus } from '@silvergatedelivery/constants';

import './FullCalendar.css';

// tutorial
// https://github.com/fullcalendar/fullcalendar-example-projects/blob/master/react/src/DemoApp.jsx

const fullCalendarPlugins = [
  dayGridPlugin, timeGridPlugin, listPlugin,
  interactionPlugin,
  momentTimezonePlugin,
  // resourceTimelinePlugin, // https://fullcalendar.io/docs/premium
];

// event obj
// https://fullcalendar.io/docs/event-object
const sampleEvents = [
  {
    title: '洛杉磯下午2點 台灣早上5點',
    date: '2020-10-02T21:00:00.000Z',
  },
  {
    title: 'event 1',
    date: '2020-10-05',
    extendedProps: {
      status: 'done',
    },
  },
  {
    title: 'event 2',
    date: '2020-10-08',
    display: 'background',
  },
  {
    title: 'event 3',
    date: '2020-10-15',
    backgroundColor: 'red',
  },
];

// 先請小蝸測試
const trialFacilityIds = [
  '2e5880c0-f8c2-11ee-a3dc-e704347f3a01', // justin's 銀色大門
  '16793623-8a2b-4dca-9a1d-8fd4adf70297', // 銀色大門嘉義縣長照(新)
  'e281a800-8ae3-11ec-823f-bbd2f0442830', // 銀色大門-苗栗
];

export default function ScheduleCalendar({
  orders,
  elders = [],
  restaurantSchedules,
  onDateChange,
}) {
  const [events, setEvents] = useState(sampleEvents);

  const [orderId, setOrderId] = useState();
  const facilityId = cache.get('app:facilityId');

  const handleEventClick = ({ event }) => {
    const { extendedProps } = event._def;
    if (extendedProps.type === 'order') {
      setOrderId(extendedProps.data.id);
    }
  };

  useEffect(() => {
    const orderEvents = getOrderEvents(orders);
    const restaurantScheduleEvents = getRestaurantScheduleEvents(restaurantSchedules);

    setEvents([
      ...orderEvents,
      ...restaurantScheduleEvents,
    ]);
  }, [orders, restaurantSchedules]);

  const renderDayCellContent = (dayCellInfo) => {
    const date = moment(dayCellInfo.date).format('YYYY-MM-DD');
    const week = moment(dayCellInfo.date).day();
    const eventCountByStatus = {
      waitingForDeliveryStaff: 0,
      reMatchingDeliveryStaff: 0,
      ready: 0,
      readyForPickup: 0,
      delivering: 0,
      delivered: 0,
      completed: 0,
      cancelled: 0,
    };
    events.forEach((event) => {
      const eventStartDate = moment(event.start).format('YYYY-MM-DD');
      if (eventStartDate === date && event.extendedProps && event.extendedProps.data) {
        const orderStatus = event.extendedProps.data.status;
        eventCountByStatus[orderStatus] += 1;
      }
    });

    const isWithin30Days = (dateString) => {
      const targetDate = moment(dateString);
      const today = moment();
      return targetDate.isBetween(today.clone().subtract(1, 'days'), today.clone().add(30, 'days'), null, []);
    };

    const missingLunchOrder = [];
    const missingDinnerOrder = [];
    if (isWithin30Days(date) && trialFacilityIds.includes(facilityId)) {
      elders.forEach(({ lunchRepeatOn, dinnerRepeatOn, status, name }) => {
        if (status !== '使用中') return;
        if (lunchRepeatOn && lunchRepeatOn.length !== 0) {
          if (lunchRepeatOn.includes(week)) {
            if (!events.find((event) => (
              event.extendedProps?.data?.elder?.name === name &&
              event.extendedProps?.data?.mealSlot === 'lunch' &&
              event.extendedProps?.data?.date === date &&
              event.extendedProps?.data?.status !== 'cancelled'
            ))) {
              missingLunchOrder.push(name);
            }
          }
        }
        if (dinnerRepeatOn && dinnerRepeatOn.length !== 0) {
          if (dinnerRepeatOn.includes(week)) {
            if (!events.find((event) => (
              event.extendedProps?.data?.elder?.name === name &&
              event.extendedProps?.data?.mealSlot === 'dinner' &&
              event.extendedProps?.data?.date === date &&
              event.extendedProps?.data?.status !== 'cancelled'
            ))) {
              missingDinnerOrder.push(name);
            }
          }
        }
      });
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {(missingLunchOrder.length !== 0 || missingDinnerOrder.length !== 0) &&
          <Tooltip
            title={
              <>
                {missingLunchOrder.length !== 0 && (<>缺少午餐：{missingLunchOrder.sort().join('、')}<br /></>)}
                {missingDinnerOrder.length !== 0 && (<>缺少晚餐：{missingDinnerOrder.sort().join('、')}</>)}
              </>}
            placement='left-start'
          >
            <div>❗&nbsp;&nbsp;&nbsp;</div>
          </Tooltip>}
        <div>
          {orderStatus.map(({ value, color }) => {
            if (eventCountByStatus[value] !== 0) {
              return <span key={value} style={{ color, fontSize: '0.8em' }}>#{eventCountByStatus[value]}&nbsp;</span>;
            }
          })}
        </div>
        <span key='date'>&nbsp;&nbsp;&nbsp;{dayCellInfo.dayNumberText}</span>
      </div>
    );
  };

  return (
    <React.Fragment>
      {orderId && <OrderDialog id={orderId} open={true} onClose={()=> setOrderId(null)} />}
      <FullCalendar
        // themeSystem={'bootstrap'}
        height={'calc(100vh - 100px)'}
        locale={zhLocale}
        buttonText={{
          listDay: '列表:天',
          listWeek: '列表:週',
          listMonth: '列表:月',
        }}
        timeZone={'Asia/Taipei'} // Asia/Taipei
        plugins={fullCalendarPlugins}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: [
            'timeGridDay',
            'timeGridWeek',
            'dayGridMonth',
            'listDay',
            'listWeek',
            'listMonth',
          ].join(','),
        }}
        initialView={cache.get('app:scheduleView') || 'listMonth'} // dayGridMonth resourceTimeline listMonth
        nowIndicator={true}
        weekNumbers={true}
        navLinks={true}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={20}
        dayCellContent={renderDayCellContent}
        weekends={true}
        viewDidMount={({ view })=>{
          cache.set('app:scheduleView', view.type);
        }}
        // eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: true }}
        eventDisplay="block" // https://github.com/fullcalendar/fullcalendar/issues/5533
        events={events}
        eventOrder={''}
        eventContent={renderEventContent}
        // select={handleDateSelect}
        eventClick={handleEventClick}
        // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
        eventAdd={function(){}}
        eventChange={function(){}}
        eventRemove={function(){}}
        */
        datesSet={(dateInfo)=>{
          if (onDateChange) {
            onDateChange(dateInfo);
          }
        }}
        // 送餐時段 // https://fullcalendar.io/docs/businessHours
        businessHours={[
          {
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            startTime: '11:00',
            endTime: '15:00',
          },
          {
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            startTime: '17:00',
            endTime: '20:00',
          },
        ]}
      />
    </React.Fragment>
  );
}

function renderEventContent(eventInfo) {
  // global.logger.debug(eventInfo);
  const mealName = eventInfo.event.extendedProps ? eventInfo.event.extendedProps.mealName : null;
  const style = {
    paddingLeft: 1,
    backgroundColor: eventInfo.backgroundColor,
    color: '#fff',
  };
  const timeText = eventInfo.timeText
    .split('-')[0]
    .replace(/上午/g, '')
    .replace(/下午/g, '')
    .replace(/ /g, '');

  return (
    <div style={style}>
      {mealName || ''} {timeText}
      &nbsp;
      <b>{eventInfo.event.title}</b>
      {/* <br/>
      {eventInfo.event.extendedProps.description} */}
    </div>
  );
}

ScheduleCalendar.propTypes = {
  orders: PropTypes.array,
  elders: PropTypes.array,
  restaurantSchedules: PropTypes.array,
  onDateChange: PropTypes.func,
};
