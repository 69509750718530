import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import { elderPaymentMethod } from '@silvergatedelivery/constants';
import {
  counties,
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import { asyncListAll, request } from 'utilities/graph';
import {
  getEldersByClientByStatus,
  getOrdersByClientByDate,
  getPandagoOrdersByOrderByCreatedAt,
} from './queries';
import { getClient } from 'graphql/queries';
import { processShareOrder, processDisadvantagedTypes, buildColumnsArray } from './CountyPrsReport/workbooks/helpers';
import JSZip from 'jszip';
import { download } from 'utilities/file';
import ExcelJS from 'exceljs';
import { toastr } from 'react-redux-toastr';
import LabelWithToolTip from './LabelWithToolTip';

const monthOptions = Array(10).fill('').map((x, index) => {
  return moment().add(-index, 'months').format('YYYY-MM');
});

async function writeReport({
  county: inCounty,
  elders: allElders,
  orders: allOrders,
  deliveryStaffs,
  selectedMonth: selectedYearMonth = 'YYYY-MM',
  client,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
  selectedPaymentMethod = 'all',
}) {
  let elders = allElders.filter(({ address }) => address.county === inCounty);
  if (selectedPaymentMethod !== 'all') {
    if (selectedPaymentMethod === 'none') {
      elders = elders.filter(({ paymentMethod }) => paymentMethod === null);
    } else {
      elders = elders.filter(({ paymentMethod }) => paymentMethod === selectedPaymentMethod);
    }
  }
  if (elders.length == 0) {
    toastr.error('沒有符合設定條件的送餐對象');
  }
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }
  if (orders.length === 0) {
    toastr.error('沒有符合設定條件的訂單');
  }
  const zip = new JSZip();

  const workbook = new ExcelJS.Workbook();
  const [selectedYear, selectedMonth] = selectedYearMonth.split('-');
  const ROCYear = parseInt(selectedYear) - 1911;
  const worksheet = workbook.addWorksheet(`${ROCYear}${selectedMonth}會計對賬與簡訊表`);
  getWorksheetElder(worksheet, client, elders, orders, deliveryStaffs, selectedMonth);

  try {
    if (workbook && workbook.worksheets && workbook.worksheets.length > 0) {
      const bufferDataByDistrict = await workbook.xlsx.writeBuffer();
      zip.file(`${ROCYear}${selectedMonth}會計對賬與簡訊表.xlsx`, bufferDataByDistrict, { binary: true });
    }
  } catch (e) {
    console.error(e);
  }

  const files = Object.keys(zip.files);
  if (files.length !== 0) {
    const zipContent = zip.generate({ type: 'blob' });
    download(`${ROCYear}${selectedMonth}會計對賬與簡訊表.zip`, zipContent);
  }
}

function getWorksheetElder(worksheet, client, elders, orders = [], deliveryStaffs, selectedMonth) {
  let clientCopaymentSetting;
  if (client?.facilitySettings?.receiptSettings) {
    const { originalPrice = 0, copaymentSettings = [] } = client?.facilitySettings?.receiptSettings;
    if (originalPrice && copaymentSettings.length !== 0) {
      clientCopaymentSetting = {
        originalPrice,
      };
      copaymentSettings.forEach(({ disadvantagedType, disadvantagedLabel, copayment }) => {
        clientCopaymentSetting[disadvantagedType] = {
          label: disadvantagedLabel,
          value: copayment,
        };
      });
    }
  }

  if (!clientCopaymentSetting) {
    toastr.error('請先新增收據費用設定');
    return;
  }

  const fontName = 'Calibri';
  const range = 'A:Z';
  const columnArray = buildColumnsArray(range);

  const columnWidth = [9, 12, 15, 9, 9, 12, 12, 12, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 70];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  let rowIndex = 1;

  let cell;
  ['姓名', '身份證字號', '繳費方式', '身份別', '長照等級', '聯絡電話', '午餐送餐員', '晚餐送餐員', '居服員', '午餐次數',
    '晚餐次數', '用餐總次數', '補助次數', '自購次數', '部分負擔', '自購費用', '耗材費', '加飯菜費', '總費用', '匯款簡訊']
    .forEach((header, index) => {
      cell = worksheet.getCell(`${columnArray[index]}${rowIndex}`);
      cell.value = header;
      cell.alignment = { horizontal: 'left', vertical: 'middle' };
      cell.font = { size: 11, name: fontName };
    });
  rowIndex += 1;

  elders.sort((a, b) => a.name > b.name ? 1 : -1).forEach((elder) => {
    const {
      id,
      name,
      identificationCardId,
      disadvantagedTypesSlot,
      cmsLevel,
      phoneNumber,
      paymentMethod,
    } = elder;
    const elderOrders = orders.filter(({ elderId }) => elderId === id);

    if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
      disadvantagedTypesSlot.forEach(({ start, end, type }) => {
        const filteredOrders = elderOrders.filter(({ date }) => date >= start && date < end);
        if (filteredOrders.length === 0) {
          return;
        }
        if ((!clientCopaymentSetting || clientCopaymentSetting[type] === undefined) && type !== '一般' && type !== '一般戶') {
          toastr.warning(`${elder.name}的"${type}"身份沒有對應的收據費用設定`);
          console.error(`${elder.name}的"${type}"身份沒有對應的收據費用設定`);
          return;
        }

        const copayment = clientCopaymentSetting[type]?.value || 0;
        const lunchCount = filteredOrders.filter(({ mealSlot }) => mealSlot === 'lunch').length;
        const dinnerCount = filteredOrders.filter(({ mealSlot }) => mealSlot === 'dinner').length;
        const lunchOrderWithDeliveryStaff = filteredOrders.find(({ mealSlot, deliveryStaffId }) => mealSlot === 'lunch' && deliveryStaffId);
        let lunchDeliveryStaff;
        if (lunchOrderWithDeliveryStaff) {
          lunchDeliveryStaff = deliveryStaffs.find(({ id }) => id === lunchOrderWithDeliveryStaff.deliveryStaffId);
        }
        const dinnerOrderWithDeliveryStaff = filteredOrders.find(({ mealSlot, deliveryStaffId }) => mealSlot === 'dinner' && deliveryStaffId);
        let dinnerDeliveryStaff;
        if (dinnerOrderWithDeliveryStaff) {
          dinnerDeliveryStaff = deliveryStaffs.find(({ id }) => id === dinnerOrderWithDeliveryStaff.deliveryStaffId);
        }
        let selfPayTotalPrice = 0;
        let nonSelfPayTotalPrice = 0;
        let paperBoxTotalPrice = 0;
        let addFoodTotalPrice = 0;
        if (['一般', '一般戶'].includes(type)) {
          filteredOrders.forEach(({ mealItems }) => {
            mealItems.forEach(({ price, surcharges }) => {
              selfPayTotalPrice += price;
              if (surcharges?.length) {
                const paperBox = surcharges.find(({ name }) => name === '紙盒');
                if (paperBox) {
                  selfPayTotalPrice -= paperBox.price;
                  paperBoxTotalPrice += paperBox.price;
                }
                const addFood = surcharges.find(({ name }) => name === '加飯菜');
                if (addFood) {
                  selfPayTotalPrice -= addFood.price;
                  addFoodTotalPrice += addFood.price;
                }
              }
            });
          });
          nonSelfPayTotalPrice = 0;
        } else {
          filteredOrders.forEach(({ mealItems }) => {
            mealItems.forEach(({ surcharges }) => {
              if (surcharges?.length) {
                const paperBox = surcharges.find(({ name }) => name === '紙盒');
                if (paperBox) {
                  paperBoxTotalPrice += paperBox.price;
                }
                const addFood = surcharges.find(({ name }) => name === '加飯菜');
                if (addFood) {
                  addFoodTotalPrice += addFood.price;
                }
              }
            });
          });
          selfPayTotalPrice = 0;
          nonSelfPayTotalPrice = (lunchCount + dinnerCount) * copayment;
        }
        let smsMessage = '';
        if (paymentMethod === '匯款-簡訊通知') {
          const total = selfPayTotalPrice + nonSelfPayTotalPrice + paperBoxTotalPrice + addFoodTotalPrice;
          let mealDetail = `共${lunchCount + dinnerCount}餐`;
          if (paperBoxTotalPrice) {
            mealDetail += `、紙盒費 ${paperBoxTotalPrice} 元`;
          }
          if (addFoodTotalPrice) {
            mealDetail += `、加飯菜費 ${addFoodTotalPrice} 元`;
          }
          smsMessage = `您好，通知長輩 ${name} ${selectedMonth}月份送餐費用共 ${total}元 (${mealDetail}）\n\
煩請在15日前匯款完成，謝謝。\n\
匯款帳戶：合作金庫溪湖分行 (006) 1184-717-038026\n\
戶名：財團法人彰化縣私立慈恩社會福利慈善事業基金會附設彰化縣私立慈恩居家式服務類長期照顧服務機構\n\
有任何問題可來電：04-8683143慈恩送餐`;
        }
        const elderRowValue = [
          name, identificationCardId || '', paymentMethod || '',
          ['一般', '一般戶'].includes(type) ? '全自購' : type,
          cmsLevel || '',
          phoneNumber || '',
          lunchDeliveryStaff?.name || '', // 午餐送餐員 // 慈恩說可以略過，不過還是先放某張訂單的大使
          dinnerDeliveryStaff?.name || '', // 晚餐餐送餐員 // 慈恩說可以略過，不過還是先放某張訂單的大使
          '', // 居服員 // 慈恩說不用放
          lunchCount,
          dinnerCount,
          lunchCount + dinnerCount,
          ['一般', '一般戶'].includes(type) ? 0: lunchCount + dinnerCount, // TODO 補助次數, 有些非自購卻填0
          !['一般', '一般戶'].includes(type) ? 0: lunchCount + dinnerCount, // TODO 自購次數, 有些非自購戶卻有自購
          nonSelfPayTotalPrice, // TODO 部分負擔, 有些非自購卻填0
          selfPayTotalPrice, // TODO 自購費用, 有些非自購戶卻有自購
          paperBoxTotalPrice, // 耗材費
          addFoodTotalPrice, // 加飯費
          selfPayTotalPrice + nonSelfPayTotalPrice + paperBoxTotalPrice + addFoodTotalPrice, // 總費用
          smsMessage,
        ];
        elderRowValue.forEach((value, index) => {
          cell = worksheet.getCell(`${columnArray[index]}${rowIndex}`);
          cell.value = value;
          cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
          cell.font = { size: 11, name: fontName };
        });

        rowIndex += 1;
      });
    }
  });

  return worksheet;
}

export default function AccountingSMS({ clientOptions = [] }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);
  const [selectedCounty, setSelectedCounty] = useState(counties[0]);
  const [selectedClientId, setSelectedClientId] = useState();
  const [includeInactiveElders, setIncludeInactiveElders] = useState(false);
  const [includeNonDisElders, setIncludeNonDisElders] = useState(false);
  const [includeUndeliveredOrders, setIncludeUndeliveredOrders] = useState(false);
  const [excludeNonFoodDeliveryOrders, setExcludeNonFoodDeliveryOrders] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('all');

  const collectData = async () => {
    const from = moment(selectedMonth).tz(TIME_ZONE).startOf('month');
    const to = moment(selectedMonth).tz(TIME_ZONE).endOf('month');
    const fromDate = from.format('YYYY-MM-DD');
    const toDate = to.format('YYYY-MM-DD');
    const fromISOString = from.toISOString();
    const toISOString = to.toISOString();
    // const monthString = from.format('YYYY/MM');
    const period = `${fromDate}__${toDate}`;
    const { id: clientId } = clientOptions.find(({ id }) => id === selectedClientId);

    global.logger.debug({
      selectedClientId,
      period,
      selectedMonth,
      fromDate,
      toDate,
      fromISOString,
      toISOString,
    });

    const [
      elders,
      orders,
      { data: { getClient: client } },
    ] = await Promise.all([
      asyncListAll(getEldersByClientByStatus, {
        clientId,
        status: includeInactiveElders ? undefined : { eq: '使用中' },
        filter: includeNonDisElders ? undefined : {
          isDisadvantaged: {
            eq: 1,
          },
        },
        limit: 1000,
      }),
      asyncListAll(getOrdersByClientByDate, {
        clientId,
        date: {
          between: [fromDate, toDate],
        },
        limit: 1000,
      }),
      request(getClient, { id: clientId }),
    ]);

    const deliveryStaffIds = [];
    orders.forEach(({ deliveryStaffId }) => {
      if (deliveryStaffId && !deliveryStaffIds.includes(deliveryStaffId)) {
        deliveryStaffIds.push(deliveryStaffId);
      }
    });

    let pandagoDeliveryStaffId;
    const deliveryStaffs = await Promise.all(deliveryStaffIds.map(async (id) => {
      const { data: { getDeliveryStaff: data } } = await request( /* GraphQL */ `
        query GetDeliveryStaff($id: ID!) {
          getDeliveryStaff(id: $id) {
            id
            name
            username
            identificationCardId
          }
        }
      `, { id });

      if (data.username === 'pandago') {
        pandagoDeliveryStaffId = id;
      }

      return data;
    }));

    const pandagoOrders = await Promise.all(orders
      .filter(({ deliveryStaffId }) => deliveryStaffId === pandagoDeliveryStaffId)
      .map(async ({ id: orderId }) => {
        const [pandagoOrder] = await asyncListAll(getPandagoOrdersByOrderByCreatedAt, {
          orderId,
          limit: 1,
        });

        return pandagoOrder;
      }));

    processShareOrder(elders, orders);
    processDisadvantagedTypes(selectedMonth, elders);

    writeReport({
      county: selectedCounty,
      elders, orders, deliveryStaffs, pandagoOrders, selectedMonth, client, includeUndeliveredOrders, excludeNonFoodDeliveryOrders,
      selectedPaymentMethod,
    });
  };

  const triggerDownload = async () => {
    try {
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };


  const updateCounty = (client) => {
    if (client && client.county) {
      const targetCounty = counties.find((county) => county === client.county);
      if (targetCounty) {
        setSelectedCounty(targetCounty);
      }
    }
  };

  useEffect(() => {
    if (clientOptions[0]) {
      setSelectedClientId(clientOptions[0].id);
      updateCounty(clientOptions[0]);
    }
  }, [clientOptions]);

  return (
    <div>
      <h3>會計對賬與簡訊表</h3>
      <div>
        <FormControl style={{ minWidth: 360, marginRight: 16 }}>
          <AutoSelect
            id="selected-restaurant"
            options={clientOptions.map(({ name }) => name)}
            values={clientOptions.map(({ id }) => id)}
            required={true}
            value={selectedClientId}
            label="機構"
            onChange={(value) => {
              setSelectedClientId(value);
              if (value) {
                const client = clientOptions.find(({ id }) => id === value);
                updateCounty(client);
              }
            }}
          />
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-month">月份</InputLabel>
          <Select
            labelId="selected-month-label"
            id="selected-month"
            value={selectedMonth}
            onChange={(event) => setSelectedMonth(event.target.value)}
            disabled={isLoading}
          >
            {monthOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-county">縣市</InputLabel>
          <Select
            labelId="selected-county-label"
            id="selected-county"
            value={selectedCounty}
            onChange={(event) => setSelectedCounty(event.target.value)}
            disabled={isLoading}
          >
            {counties.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-payment-method">收費方式</InputLabel>
          <Select
            labelId="selected-payment-method-label"
            id="selected-payment-method"
            value={selectedPaymentMethod}
            onChange={(event) => setSelectedPaymentMethod(event.target.value)}
            disabled={isLoading}
          >
            <MenuItem key={'all'} value={'all'}>
              全部
            </MenuItem>
            {/* value是'null', 會無法顯示'無', 用'none'處理 */}
            {elderPaymentMethod.map(({ value, label }) => (
              <MenuItem key={label} value={value || 'none'}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeNonDisElders}
              name={'includeNonDisElders'}
              value={includeNonDisElders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeNonDisElders(e.target.checked);
              }}
            />
          }
          label={<LabelWithToolTip
            label='包含自費戶'
            tooltip='若送餐對象設定中 "弱勢長輩(非自費戶)" 選擇 "否" 則為自費戶'
          />}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={includeInactiveElders}
              name={'includeInactiveElders'}
              value={includeInactiveElders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeInactiveElders(e.target.checked);
              }}
            />
          }
          label={'包含已停用的個案'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={includeUndeliveredOrders}
              name={'includeUndeliveredOrders'}
              value={includeUndeliveredOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeUndeliveredOrders(e.target.checked);
              }}
            />
          }
          label={'包含未送達的訂單'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={excludeNonFoodDeliveryOrders}
              name={'excludeNonFoodDeliveryOrders'}
              value={excludeNonFoodDeliveryOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setExcludeNonFoodDeliveryOrders(e.target.checked);
              }}
            />
          }
          label={'排除非送餐的訂單'}
        />
      </div>

      <Button
        variant="outlined"
        onClick={triggerDownload}
        disabled={!selectedClientId || isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
      </Button>
    </div>
  );
}

AccountingSMS.propTypes = {
  clientOptions: PropTypes.array,
};
