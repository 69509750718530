import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { pdfjs } from 'react-pdf';
import Button from '@material-ui/core/Button';
import { Storage } from 'aws-amplify';

export default function PdfViewer({ fileS3Key }) {
  const [pdf, setPdf] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const renderPDF = async () => {
      if (!fileS3Key) return;

      const url = await Storage.get(fileS3Key);

      const loadingTask = pdfjs.getDocument(url);
      const pdf = await loadingTask.promise;
      setPdf(pdf);
    };

    renderPDF();
  }, [fileS3Key]);

  const renderPage = async (pageNumber, canvas) => {
    if (!pdf) return;
    const page = await pdf.getPage(pageNumber);
    const viewport = page.getViewport({ scale: 1 });
    const context = canvas.getContext('2d');

    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const renderContext = {
      canvasContext: context,
      viewport,
    };
    await page.render(renderContext).promise;
  };

  const printForm = async () => {
    setIsLoading(true);
    const url = await Storage.get(fileS3Key);
    const pdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    iframe.src = blobUrl;

    document.body.appendChild(iframe);

    iframe.onload = () => {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
    };

    setIsLoading(false);
  };

  return (
    <div style={{
      marginTop: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      overflowX: 'auto' }}
    >
      {pdf && Array.from({ length: pdf.numPages }, (_, i) => (
        <div key={i} style={{
          marginBottom: '20px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
          <canvas
            ref={(canvas) => {
              if (canvas) renderPage(i + 1, canvas);
            }}
            style={{ border: '1px solid black' }}
          />
        </div>
      ))}
      <Button
        type="submit"
        color="primary"
        variant="contained"
        style={{ marginTop: 16 }}
        onClick={printForm}
        disabled={isLoading}
      >
        列印
      </Button>
    </div>
  );
}

PdfViewer.propTypes = {
  fileS3Key: PropTypes.string,
};
