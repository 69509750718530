import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AutoSizeDialog from 'components/AutoSizeDialog';
import PaymentInput from './PaymentInput';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButton: {
    width: '80%',
    borderRadius: '20px',
    color: 'white',
  },
}));

const defaultPayment = { type: 'linepay' };

export default function PaymentDialog({
  label,
  formData,
  onUpdate,
  hideCloseButton = false,
  fullScreen = true,
  maxWidth = 'md',
}) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [payment, setPayment] = useState(formData?.type ? JSON.parse(JSON.stringify(formData)) : defaultPayment);

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    setShowDialog(false);
    // reset data
    setPayment(formData?.type ? JSON.parse(JSON.stringify(formData)) : defaultPayment);
  };

  const onPaymentChange = (payment) => {
    setPayment(payment);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} direction='row' justifyContent='space-between' style={{ padding: 4 }}
        onClick={() => {
          // setShowDialog(true);
        }}
      >
        <Grid item>
          <Typography variant='body1' color='textPrimary' align='left' style={{ margin: 4, whiteSpace: 'pre-line' }}>
            {label || ''}
          </Typography>
        </Grid>
        {/* <Grid item>
          <IconButton
            style={{ padding: 4 }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid> */}
      </Grid>
      <AutoSizeDialog
        open={showDialog}
        title='選擇付款方式'
        onClose={handleClose}
      >
        <DialogContent className={classes.content} dividers>
          <Grid container>
            <Grid item xs={12}>
              <PaymentInput
                data={payment}
                onChange={onPaymentChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent='flex-end' alignItems='center'>
            <Grid xs={12} item container justifyContent='center' alignItems='center'>
              <Button
                variant='contained'
                className={classes.actionButton}
                style={{ backgroundColor: '#00913A' }}
                onClick={() => {
                  onUpdate && onUpdate(payment);
                  setShowDialog(false);
                }}
              >
                確認
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </AutoSizeDialog>
    </React.Fragment>
  );
}

PaymentDialog.propTypes = {
  label: PropTypes.string,
  formData: PropTypes.object,
  onUpdate: PropTypes.func,
  fullScreen: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  maxWidth: PropTypes.string,
};
