import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'components/Table/DataTable';
import { getElderFormsByElderByCreatedAt, getFormsByClientByStatus } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import ElderFormFormEditButton from 'forms/ElderFormForm/ElderFormFormEditButton';
import cache from 'utilities/cache';

export default function ElderFormTable({
  title = '送餐對象表單',
  elder,
  ...props
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [forms, setForms] = useState([]);

  const clientId = cache.get('app:facilityId');

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'name',
      label: '表單名稱',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'fileS3Key',
      label: 'fileS3Key',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const forms = await asyncListAll(getFormsByClientByStatus, {
        clientId: clientId,
        status: { eq: '使用中' },
      });
      setForms(forms);
    })();

    setIndexes([
      {
        name: 'getElderFormsByElderByCreatedAt',
        label: '按長輩查詢',
        partitionKey: 'elderId',
        sortKey: 'createdAt',
        fields: [{
          label: '長輩',
          key: 'elderId',
          type: 'select',
          options: [{ value: elder.id, label: elder.name }],
          disabled: true,
        }],
        operation: getElderFormsByElderByCreatedAt,
      },
    ]);
    setQueryParams({
      elderId: elder.id,
    });
  }, []);

  const editButton = (props) => {
    return <ElderFormFormEditButton
      {...props}
      forms={forms}
      elder={elder}
    />;
  };

  if (!indexes || !queryParams) return null;

  return (
    <React.Fragment>
      <DataTable
        indexes={indexes}
        queryDefaultParams={queryParams}
        title={title}
        columns={columns}
        editButton={editButton}
        {...props}
      />
    </React.Fragment>);
}

ElderFormTable.propTypes = {
  title: PropTypes.string,
  elder: PropTypes.object,
};
