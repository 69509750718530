import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import Backdrop from '@material-ui/core/Backdrop';
import FormDialog from 'components/FormDialog';
import AdminRecurringOrderForm from './AdminRecurringOrderForm';
import { formatDatetime } from 'utilities/format';
import { Semaphore } from 'async-mutex';
import { asyncListAll, request } from 'utilities/graph';
import {
  getRecurringOrder,
  getRecurringOrderLogByRecurringOrderIdByDate,
  getOrder,
} from 'graphql/queries';
import { processNewRecurringOrderSetting } from './helpers';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import {
  createRecurringOrder,
  createRecurringOrderLog,
  adminCreateOrder,
  adminUpdateOrder,
  updateRecurringOrder,
} from 'graphql/mutations';
import cache from 'utilities/cache';
import moment from 'moment';

export default function AdminRecurringOrderEditButton({
  title,
  mode = 'add',
  item,
  onUpdate,
  open: inOpen = false,
  exitConfirm = false,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(inOpen);
  const [editItem, setEditItem] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState('group');
  const [toCreateOrders, setToCreateOrders] = useState([]);
  const [elders, setElders] = useState([]);
  const [toCreateRecurringOrder, setToCreateRecurringOrder] = useState();
  const [toUpdateRecurringOrder, setToUpdateRecurringOrder] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createResults, setCreateResults] = useState([]);
  const [createErrors, setCreateErrors] = useState([]);
  const [orderCreated, setOrderCreated] = useState(false);
  const [cancelOrdersProgress, setCancelOrdersProgress] = useState(0);
  const [toCancelOrders, setToCancelOrders] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const handleButtonClick = (event) => {
    if (mode === 'add') {
      setAnchorEl(event.currentTarget);
    } else {
      if (item.elderId) {
        setEditMode('single');
      } else {
        setEditMode('group');
      }
      setOpen(true);
      setEditItem(item);
    }
  };

  const handleResetButtonClick = async () => {
    setShowBackdrop(true);
    const { toCreateOrders, elders, toCreateRecurringOrder } = await processNewRecurringOrderSetting(item);
    const recurringOrderLogs = await asyncListAll(getRecurringOrderLogByRecurringOrderIdByDate,
      { recurringOrderId: toCreateRecurringOrder.id,
        date: { ge: moment().tz(TIME_ZONE).format('YYYY-MM-DD') } });
    const orderIds = recurringOrderLogs.map(({ orderIds }) => orderIds).flat();
    const orders = await Promise.all(orderIds.map(async (id) => {
      const { data: { getOrder: order } } = await request(getOrder, { id });
      return order;
    }));
    const ordersUncancelled = orders.filter((order) => !['completed', 'delivered', 'cancelled'].includes(order.status));
    setCancelOrdersProgress(0);
    setToCancelOrders(ordersUncancelled);
    setToCreateOrders(toCreateOrders);
    setElders(elders);
    setToUpdateRecurringOrder(toCreateRecurringOrder);
    setShowBackdrop(false);
    setOpenConfirmDialog(true);
  };

  const handleMenuClick = (editMode) => () => {
    if (!open) {
      setEditMode(editMode);
      setIsLoading(false);
      setOrderCreated(false);
      setOpen(true);
      setEditItem(item);
    }
    setAnchorEl(null);
  };

  const getRecurringOrderUpdated = async (recurringOrderId) => {
    const { data: { getRecurringOrder: data } } = await request(getRecurringOrder, { id: recurringOrderId });
    return data;
  };

  const cancelOrders = async () => {
    let orderProcessed = 0;
    const toUpdateData = [];
    const ordersUncancelled = toCancelOrders.filter((order) => !['completed', 'delivered', 'cancelled'].includes(order.status));

    const s = new Semaphore(10);
    const orderGrouped = {};
    ordersUncancelled.forEach((order) => {
      const groupId = order.orderGroupId || order.id;
      orderGrouped[groupId] = orderGrouped[groupId] || [];
      orderGrouped[groupId].push(order);
    });

    await Promise.all(Object.keys(orderGrouped).map((groupId) =>
      s.runExclusive(async () => {
        const data = orderGrouped[groupId].map((order) => (
          {
            id: order.id,
            restaurantId: order.restaurantId,
            deliveryBy: order.deliveryBy,
            status: 'cancelled',
            tier: order.tier,
            cancellationReason: 'others',
            cancellationNote: '重置週期性排單',
          }
        ));
        try {
          await request(adminUpdateOrder, { input: { orders: data } });
          toUpdateData.push(...data);
        } catch (e) {
          console.error(e.errors[0].message);
        }
        orderProcessed += data.length;
        setCancelOrdersProgress(orderProcessed * 100 / ordersUncancelled.length);
      }),
    ));
  };

  const handleComplete = async () => {
    if (orderCreated) {
      setOpen(false);
      setOrderCreated(false);
      setOpenConfirmDialog(false);
      return;
    }
    setIsLoading(true);

    await cancelOrders();

    let results = [];
    const errors = [];
    const elderNameInErrors = [];
    if (toCreateOrders.length !== 0) {
      const s = new Semaphore(10);
      await Promise.all(toCreateOrders.map((order) =>
        s.runExclusive(async () => {
          try {
            const res = await request(adminCreateOrder, { input: { orders: [order] } });
            const { data: { adminCreateOrder: { data } } } = res;
            results = [...results, ...data];
            setCreateResults(results);
          } catch (e) {
            global.logger.debug(e);
            let message = '';
            if (e.errors && e.errors[0]) {
              switch (e.errors[0].message) {
                case 'The string supplied did not seem to be a phone number':
                  message = `熊貓外送: ${t('送餐對象')}電話不完整或有誤`;
                  break;
                case 'Unable to process order\norder is outside deliverable range':
                  message = `熊貓外送: ${t('送餐對象')}地址不在服務範圍內`;
                  break;
                default:
                  message = e.errors[0].message;
              }
            }
            errors.push({
              elderId: order.elderId,
              message,
            });
            const elderName = (elders.find(({ id })=> id === order.elderId) || {}).name;
            if (elderName) {
              elderNameInErrors.push(elderName);
            }
          }
        }),
      ));
    }

    const now = moment().toISOString();
    let recurringOrderId;
    const username = cache.get('app:username');
    if (toCreateRecurringOrder) {
      Object.assign(toCreateRecurringOrder, {
        lastExecutedAt: now,
        lastExecutedStatus: errors.length === 0 ? 'success' : 'failed',
        lastExecutedStatusMessage: elderNameInErrors.length === 0 ? '' : `新增訂單失敗: ${elderNameInErrors.join(' ')}`,
      });
      try {
        const { data: { createRecurringOrder: { id } } } = await request(createRecurringOrder, { input: toCreateRecurringOrder });
        recurringOrderId = id;
      } catch (e) {
        console.log(e);
      }
    } else if (toUpdateRecurringOrder) {
      recurringOrderId = toUpdateRecurringOrder.id;
      try {
        await request(updateRecurringOrder, { input: {
          id: toUpdateRecurringOrder.id,
          lastExecutedAt: now,
          lastExecutedStatus: errors.length === 0 ? 'success' : 'failed',
          lastExecutedStatusMessage: elderNameInErrors.length === 0 ? '' : `新增訂單失敗: ${elderNameInErrors.join(' ')}`,
          updatedAt: now,
          updatedBy: username,
        } });
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const resultsGroupedByDeliveryBy = results.reduce((acc, order) => {
        const key = order.deliveryBy;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(order);
        return acc;
      }, {});
      const toCreateRecurringOrderLog = [];
      Object.keys(resultsGroupedByDeliveryBy).map((deliveryBy) => {
        toCreateRecurringOrderLog.push({
          orderIds: resultsGroupedByDeliveryBy[deliveryBy].map(({ id }) => id),
          recurringOrderId,
          deliveryBy: (toCreateRecurringOrder || toUpdateRecurringOrder).deliveryBy,
          date: deliveryBy.slice(0, 10),
          createdAt: now,
          createdBy: username,
          updatedAt: now,
          updatedBy: username,
        });
      });
      if (toCreateRecurringOrderLog.length !== 0) {
        await Promise.all(toCreateRecurringOrderLog.map((recurringOrderLog) => {
          request(createRecurringOrderLog, { input: recurringOrderLog });
        }));
      }
    } catch (e) {
      console.log(e);
    }
    const recurringOrder = await getRecurringOrderUpdated(recurringOrderId); // in order to get the data in the table joined
    onUpdate && onUpdate(recurringOrder);

    setCreateErrors(errors);
    setOrderCreated(true);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      { mode === 'edit'?
        <Grid container direction='row'>
          {item?.status === '使用中' &&
          <Grid xs={6} item>
            <Tooltip title='重置訂單'>
              <IconButton
                aria-label={'reset'}
                size={'small'}
                onClick={handleResetButtonClick}
              >
                <SettingsBackupRestoreIcon />
              </IconButton>
            </Tooltip>
          </Grid>}
          <Grid xs={6} item>
            <Tooltip title='修改資料'>
              <IconButton
                aria-label={mode}
                size={'small'}
                onClick={handleButtonClick}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid> :
        <Tooltip title='新增資料'>
          <IconButton
            aria-label={mode}
            size={'small'}
            onClick={handleButtonClick}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      }
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={handleMenuClick('group')}>新增送餐群組週期性排單</MenuItem>
        <MenuItem onClick={handleMenuClick('single')}>新增單一送餐對象週期性排單</MenuItem>
      </Menu>
      {open &&
        <FormDialog
          title={title}
          openOnInit={true}
          onClose={() => {
            setOpen(false);
            setEditItem();
          }}
          exitConfirm={exitConfirm}
        >
          <AdminRecurringOrderForm
            formData={editItem}
            onComplete={(data) => {
              if (mode === 'add') {
                const { toCreateOrders, elders, toCreateRecurringOrder } = data;
                setToCreateOrders(toCreateOrders);
                setElders(elders);
                setToCreateRecurringOrder(toCreateRecurringOrder);
                setOpenConfirmDialog(true);
              } else {
                setOpen(false);
                setEditItem();
                onUpdate && onUpdate(data);
              }
            }}
            editMode={editMode}
            mode={mode}
          />
        </FormDialog>}
      {openConfirmDialog &&
        <FormDialog
          title={'訂單列表'}
          openOnInit={true}
          fullScreen={true}
          hideCloseButton={true}
        >
          <Grid container spacing={2}>
            <Grid item container spacing={1}>
              {toCancelOrders.length !== 0 &&
                <div>
                  <Grid item xs={12} container direction='row' alignItems='center'>
                    <WarningIcon style={{ marginRight: 4 }}/>
                    <Typography variant="h6">
                      {`此週期性排單30天內有${toCancelOrders.length}張訂單，按下確定後會取消這些訂單`}
                    </Typography>
                  </Grid>
                  {cancelOrdersProgress !== 0 &&
                  <Grid item xs={12}>
                    <div style={{ width: 600 }}>
                      <LinearProgressWithLabel value={cancelOrdersProgress}/>
                    </div>
                  </Grid>}
                </div>
              }
              {toCreateOrders.length !== 0 &&
                <Grid item xs={12} container direction='row' alignItems='center'>
                  <ListAltIcon style={{ marginRight: 4 }}/>
                  <Typography variant="h6">
                    請檢閱後按下確定新增下列訂單
                  </Typography>
                </Grid>}
              {toCreateOrders.length === 0 &&
                <Grid item xs={12} container direction='row' alignItems='center'>
                  <Typography variant="h6">
                    此週期性排單在30天內沒有需要新增的訂單，請確認送餐對象與此排單的送餐週期設定是否正確，若仍要新增此週期性排單設定請按確定。
                  </Typography>
                </Grid>}
              {
                toCreateOrders.map((order, index) => (
                  <Grid item container xs={12} key={index} spacing={1} style={{ marginLeft: 20 }}>
                    <Grid item xs={12}>
                      <Typography variant="h6" component="p">
                        {(elders.find(({ id })=> id === order.elderId) || {}).name || ''}
                      </Typography>
                    </Grid>
                    {order.deliveryDatetimes.map((datetime) => {
                      const matched = createResults.find(({ elderId, deliveryBy }) => elderId === order.elderId && deliveryBy === datetime);
                      return (
                        <Grid item sm={4} md={3} lg={2} container align="center" key={datetime}>
                          <Grid item xs={12} container align="center">
                            {isLoading && !matched && <CircularProgress color="primary" size={24} />}
                            {(isLoading || orderCreated) && matched && <CheckIcon color="primary" />}
                            {(!isLoading && orderCreated) && !matched && <CancelIcon color="secondary" />}
                            <Typography style={{ marginTop: 2, marginLeft: 4 }}>
                              {formatDatetime(datetime, { weekday: true })}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid item xs={12}>
                      <Typography variant="h5" component="p" color="secondary">
                        {(createErrors.find(({ elderId })=> elderId === order.elderId) || {}).message || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              }
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              {!orderCreated &&
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenConfirmDialog(false);
                }}
                style={{ marginRight: 16 }}
                disabled={isLoading}
              >
                取消
              </Button>}
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={handleComplete}
              >
                {orderCreated ? '關閉' : '確定'}
                {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
              </Button>
            </Grid>
          </Grid>
        </FormDialog>
      }
      {showBackdrop && <Backdrop open={true} style={{ position: 'absolute', opacity: 0.6, zIndex: 200 }}>
        <CircularProgress color="primary" size="1.5rem" />
      </Backdrop>}
    </React.Fragment>
  );
}

AdminRecurringOrderEditButton.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.string,
  item: PropTypes.object,
  onUpdate: PropTypes.func,
  open: PropTypes.bool,
  exitConfirm: PropTypes.bool,
};
