import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FormDialog from 'components/FormDialog';
import DataTable from 'components/Table/DataTable';
import { getFormsByClientByStatus } from 'graphql/queries';
import FormEditButton from './FormEditButton';
import cache from 'utilities/cache';
import { download } from 'utilities/file';
import { Storage } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useCache } from 'CacheProvider';
import PdfViewer from './PdfViewer';

export default function FormTable({
  title = '表單',
  description,
  ...props
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFileS3Key, setSelectedFileS3Key] = useState('');
  const { isAdmin } = useCache();

  const clientName = cache.get('app:name');
  const clientId = cache.get('app:facilityId');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fileDownload = async (fileS3Key, name) => {
    try {
      const url = await Storage.get(fileS3Key);
      const fileResponse = await fetch(url);
      if (!fileResponse.ok) {
        throw new Error('下載檔案失敗');
      }
      const blob = await fileResponse.blob();
      download(`${name}.pdf`, blob);
    } catch (e) {
      console.error(e.message);
      toastr.error(e.message);
    }
  };

  const openFilePreview = (fileS3Key, name) => {
    setSelectedFileName(name);
    setSelectedFileS3Key(fileS3Key);
    setOpenPreview(true);
  };

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'name',
      label: '表單名稱',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fileS3Key',
      label: 'fileS3Key',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'category',
      label: '類別',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'subcategory',
      label: '子類別',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'note',
      label: '備註',
      options: {
        display: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fileS3Key',
      label: ' ',
      options: {
        display: 'always',
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender(fileS3Key, tableMeta) {
          const name = tableMeta.rowData[1];
          return (
            <>
              <Tooltip
                title={'下載'}
                placement="bottom"
              >
                <IconButton
                  onClick={async () => {
                    await fileDownload(fileS3Key, name);
                  }}
                >
                  {<CloudDownloadIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip
                title={'檢視'}
                placement="bottom"
              >
                <IconButton
                  onClick={() => {
                    openFilePreview(fileS3Key, name);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  useEffect(() => {
    setIndexes([
      {
        name: 'getFormsByClientByStatus',
        label: '按狀態查詢',
        partitionKey: 'clientId',
        sortKey: 'status',
        fields: [{
          label: '機構',
          key: 'clientId',
          type: 'select',
          options: [{ value: clientId, label: clientName }],
        }, {
          label: '狀態',
          key: 'status',
          type: 'select',
          options: ['使用中', '已停用'].map((item) => {
            return { value: item, label: item };
          }),
        }],
        operation: getFormsByClientByStatus,
      },
    ]);
    setQueryParams({
      clientId,
      status: '使用中',
    });
  }, []);


  if (!indexes || !queryParams) return null;

  return (
    <React.Fragment>
      <DataTable
        indexes={indexes}
        queryDefaultParams={queryParams}
        title={title}
        description={description}
        columns={columns}
        editButton={isAdmin ? FormEditButton : undefined}
        {...props}
      />
      {openPreview &&
        <FormDialog
          title={selectedFileName}
          openOnInit={true}
          onClose={() => {
            setSelectedFileName('');
            setSelectedFileS3Key('');
            setOpenPreview(false);
          }}
          fullScreen={fullScreen}
        >
          <PdfViewer fileS3Key={selectedFileS3Key} />
        </FormDialog>}
    </React.Fragment>);
}

FormTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
