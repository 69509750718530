const schema = {
  'type': 'object',
  'required': [
    'name',
  ],
  'properties': {
    'name': {
      'type': 'string',
      'title': '名稱',
    },
  },
};

export default schema;
