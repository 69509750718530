import JSZip from 'jszip';
import { download } from 'utilities/file';
import ExcelJS from 'exceljs';
import { sortBy } from 'utilities/sorting';
import { buildColumnsArray, getROCYear, getMonthDates } from '../../CountyPrsReport/workbooks/helpers';

// TODO: 目前只能兩組
const districtPrice = [{
  districts: ['大城鄉', '竹塘鄉', '員林市', '永靖鄉'],
  price: 30,
}, {
  districts: ['二林鎮', '北斗鎮', '田尾鄉', '芳苑鄉', '埔心鄉', '埔鹽鄉', '埤頭鄉', '溪湖鎮'],
  price: 25,
}];

export default async function byDistrict({
  elders,
  orders: allOrders,
  deliveryStaffs,
  selectedMonth: selectedYearMonth = 'YYYY-MM',
  clientName,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
  applyDefaultDeliveryStaff = false,
}) {
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }

  const [selectedYear, selectedMonth] = selectedYearMonth.split('-');
  const ROCYear = parseInt(selectedYear) - 1911;

  const zip = new JSZip();

  const workbookDeliveryStaff = new ExcelJS.Workbook();
  const worksheet = workbookDeliveryStaff.addWorksheet(`${ROCYear}${selectedMonth}送餐大使薪資表`);
  getWorksheetDeliveryStaff(worksheet, deliveryStaffs, orders, elders, selectedYearMonth, selectedMonth, applyDefaultDeliveryStaff);
  const worksheetSetting = workbookDeliveryStaff.addWorksheet('費用設定');
  getWorksheetSetting(worksheetSetting);
  try {
    if (workbookDeliveryStaff && workbookDeliveryStaff.worksheets && workbookDeliveryStaff.worksheets.length > 0) {
      const bufferDataByDistrict = await workbookDeliveryStaff.xlsx.writeBuffer();
      zip.file(`${ROCYear}${selectedMonth}送餐大使薪資表.xlsx`, bufferDataByDistrict, { binary: true });
    }
  } catch (e) {
    console.error(e);
  }

  const files = Object.keys(zip.files);
  if (files.length !== 0) {
    const zipContent = zip.generate({ type: 'blob' });
    download(`${clientName}__${selectedYearMonth}送餐大使薪資表.zip`, zipContent);
  }
}

function getWorksheetDeliveryStaff(worksheet, deliveryStaffs = [], orders = [], elders, selectedYearMonth, selectedMonth, applyDefaultDeliveryStaff) {
  const monthDates = getMonthDates(selectedYearMonth, 'dddd');
  const deliveryStaffRows = [];
  deliveryStaffs
    .sort((a, b) => a.name > b.name ? 1 : - 1)
    .map(({ id, name }) => {
      const deliveryStaffOrders = orders.filter(({ deliveryStaffId }) => deliveryStaffId === id);
      if (deliveryStaffOrders.length === 0) {
        return;
      }
      const statistics = [];
      deliveryStaffOrders.sort(sortBy('deliveryBy')).forEach(({ mealSlot, date, elderId }) => {
        const elder = elders.find(({ id }) => id === elderId);
        if (!elder) return;
        const elderDistrict = elder.fixedDeliveryStaffFee === 0 ? `${elder.address.district}(0元)`: elder.address.district;
        const matched = statistics.find((item) => item.date === date && item.mealSlot === mealSlot && item.district === elderDistrict);
        if (matched) {
          matched.mealCount += 1;
        } else {
          statistics.push({
            date,
            mealSlot,
            district: elderDistrict,
            mealCount: 1,
          });
        }
      });
      deliveryStaffRows.push({ name, statistics });
    });

  const fontName = 'Calibri';
  const range = 'A:CZ';
  const columnArray = buildColumnsArray(range);

  const ROCYear = getROCYear(selectedYearMonth);

  let rowIndex = 1;

  const defaultBorder = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

  let cell;
  worksheet.mergeCells(`A${rowIndex}:S${rowIndex}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = `${ROCYear}年${selectedMonth}月送餐大使薪資表`;
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 48, name: fontName };
  rowIndex += 1;

  // column A
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = '';
  cell.border = defaultBorder;
  cell = worksheet.getCell(`A${rowIndex + 1}`);
  cell.value = '';
  cell.border = defaultBorder;
  cell = worksheet.getCell(`A${rowIndex + 2}`);
  cell.value = '日期';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 16, name: fontName };
  cell.border = defaultBorder;
  monthDates.forEach(({ label }, index) => {
    cell = worksheet.getCell(`A${rowIndex + 3 + index}`);
    cell.value = `${index + 1}(${label.replace('星期', '')})`;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    cell.border = defaultBorder;
  });
  ['小計', '送餐員合計', '當月總計',
    { formula: `= 費用設定!A2 & "元數量"` },
    { formula: `= 費用設定!A3 & "元數量"` },
    '0元數量', '合計薪資'].forEach((value, index) => {
    cell = worksheet.getCell(`A${rowIndex + 3 + monthDates.length + index}`);
    cell.value = value;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    cell.border = defaultBorder;
  });

  worksheet.getColumn('A').width = 20;

  let columnIndex = 1;
  deliveryStaffRows.forEach(({ name, statistics }) => {
    const mealCountByPriceCells = JSON.parse(JSON.stringify(districtPrice));
    const freeCells = [];
    let currentRowIndex = rowIndex;
    const lunchDistricts = new Set(
      statistics.filter(({ mealSlot }) => mealSlot === 'lunch').map(({ district }) => district),
    );
    const lunchDistrictsCount = lunchDistricts.size;
    const dinnerDistricts = new Set(
      statistics.filter(({ mealSlot }) => mealSlot === 'dinner').map(({ district }) => district),
    );
    const dinnerDistrictsCount = dinnerDistricts.size;
    if (lunchDistrictsCount + dinnerDistrictsCount > 1) {
      worksheet.mergeCells(
        `${columnArray[columnIndex]}${currentRowIndex}:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex}`,
      );
    }
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = name;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    cell.border = defaultBorder;
    currentRowIndex += 1;
    if (lunchDistrictsCount) {
      if (lunchDistrictsCount > 1) {
        worksheet.mergeCells(
          `${columnArray[columnIndex]}${currentRowIndex}:${columnArray[columnIndex + lunchDistrictsCount - 1]}${currentRowIndex}`,
        );
      }
      cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
      cell.value = '午餐';
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 16, name: fontName };
      cell.border = defaultBorder;
    }
    if (dinnerDistrictsCount) {
      if (dinnerDistrictsCount > 1) {
        worksheet.mergeCells(
          // eslint-disable-next-line max-len
          `${columnArray[columnIndex + lunchDistrictsCount]}${currentRowIndex}:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex}`,
        );
      }
      cell = worksheet.getCell(`${columnArray[columnIndex + lunchDistrictsCount]}${currentRowIndex}`);
      cell.value = '晚餐';
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 16, name: fontName };
      cell.border = defaultBorder;
    }
    currentRowIndex += 1;

    if (lunchDistrictsCount) {
      Array.from(lunchDistricts).sort().forEach((district, districtIndex) => {
        cell = worksheet.getCell(`${columnArray[columnIndex + districtIndex]}${currentRowIndex}`);
        cell.value = district;
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.font = { size: 16, name: fontName };
        cell.border = defaultBorder;
        monthDates.forEach(({ date }, dateIndex) => {
          const matched = statistics.find((item) => item.date === date && item.mealSlot === 'lunch' && item.district === district);
          let value = '';
          if (matched) {
            value = matched.mealCount;
          }
          cell = worksheet.getCell(`${columnArray[columnIndex + districtIndex]}${currentRowIndex + dateIndex + 1}`);
          cell.value = value;
          cell.alignment = { horizontal: 'center', vertical: 'middle' };
          cell.font = { size: 16, name: fontName };
          cell.border = defaultBorder;
        });
        const currentCell = `${columnArray[columnIndex + districtIndex]}${currentRowIndex + monthDates.length + 1}`;
        cell = worksheet.getCell(currentCell);
        // eslint-disable-next-line max-len
        cell.value = { formula: `=SUM(${columnArray[columnIndex + districtIndex]}5:${columnArray[columnIndex + districtIndex]}${currentRowIndex + monthDates.length})` };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.font = { size: 16, name: fontName };
        cell.border = defaultBorder;
        worksheet.getColumn(columnArray[columnIndex + districtIndex]).width = 15;

        let isFreeCell = true;
        mealCountByPriceCells.forEach((item, index) => {
          if (item.districts.includes(district)) {
            if (!mealCountByPriceCells[index].cells) {
              mealCountByPriceCells[index].cells = [];
            }
            mealCountByPriceCells[index].cells.push(currentCell);
            isFreeCell = false;
          }
        });
        if (isFreeCell) {
          freeCells.push(currentCell);
        }
      });
    }
    if (dinnerDistrictsCount) {
      Array.from(dinnerDistricts).sort().forEach((district, districtIndex) => {
        cell = worksheet.getCell(`${columnArray[columnIndex + lunchDistrictsCount + districtIndex]}${currentRowIndex}`);
        cell.value = district;
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.font = { size: 16, name: fontName };
        cell.border = defaultBorder;
        monthDates.forEach(({ date }, dateIndex) => {
          const matched = statistics.find((item) => item.date === date && item.mealSlot === 'dinner' && item.district === district);
          let value = '';
          if (matched) {
            value = matched.mealCount;
          }
          cell = worksheet.getCell(`${columnArray[columnIndex + lunchDistrictsCount + districtIndex]}${currentRowIndex + dateIndex + 1}`);
          cell.value = value;
          cell.alignment = { horizontal: 'center', vertical: 'middle' };
          cell.font = { size: 16, name: fontName };
          cell.border = defaultBorder;
        });
        const currentCell = `${columnArray[columnIndex + lunchDistrictsCount + districtIndex]}${currentRowIndex + monthDates.length + 1}`;
        cell = worksheet.getCell(currentCell);
        // eslint-disable-next-line max-len
        cell.value = { formula: `=SUM(${columnArray[columnIndex + lunchDistrictsCount + districtIndex]}5:${columnArray[columnIndex + lunchDistrictsCount + districtIndex]}${currentRowIndex + monthDates.length})` };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.font = { size: 16, name: fontName };
        cell.border = defaultBorder;
        worksheet.getColumn(columnArray[columnIndex + lunchDistrictsCount + districtIndex]).width = 15;

        let isFreeCell = true;
        mealCountByPriceCells.forEach((item, index) => {
          if (item.districts.includes(district)) {
            if (!mealCountByPriceCells[index].cells) {
              mealCountByPriceCells[index].cells = [];
            }
            // eslint-disable-next-line max-len
            mealCountByPriceCells[index].cells.push(currentCell);
            isFreeCell = false;
          }
        });
        if (isFreeCell) {
          freeCells.push(currentCell);
        }
      });
    }
    currentRowIndex += monthDates.length + 2;
    if (lunchDistrictsCount + dinnerDistrictsCount > 1) {
      worksheet.mergeCells(
        `${columnArray[columnIndex]}${currentRowIndex}:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex}`,
      );
    }
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    // eslint-disable-next-line max-len
    cell.value = { formula: `=SUM(${columnArray[columnIndex]}${currentRowIndex - 1}:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex - 1})` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    cell.border = defaultBorder;
    currentRowIndex += 1;
    if (lunchDistrictsCount + dinnerDistrictsCount > 1) {
      worksheet.mergeCells(
        `${columnArray[columnIndex]}${currentRowIndex}:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex}`,
      );
    }
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = '';
    cell.border = defaultBorder;

    currentRowIndex += 1;

    if (lunchDistrictsCount + dinnerDistrictsCount > 1) {
      worksheet.mergeCells(
        `${columnArray[columnIndex]}${currentRowIndex}:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex}`,
      );
    }
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = { formula: `=SUMPRODUCT((ISNUMBER(MATCH(\
${columnArray[columnIndex]}4:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}4,\
費用設定!B2:Z2, 0))) *\
(${columnArray[columnIndex]}${currentRowIndex - 3}:\
${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex - 3}))` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    cell.border = defaultBorder;
    currentRowIndex += 1;

    if (lunchDistrictsCount + dinnerDistrictsCount > 1) {
      worksheet.mergeCells(
        `${columnArray[columnIndex]}${currentRowIndex}:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex}`,
      );
    }
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = { formula: `=SUMPRODUCT((ISNUMBER(MATCH(\
${columnArray[columnIndex]}4:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}4,\
費用設定!B3:Z3, 0))) *\
(${columnArray[columnIndex]}${currentRowIndex - 4}:\
${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex - 4}))` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    cell.border = defaultBorder;
    currentRowIndex += 1;

    if (lunchDistrictsCount + dinnerDistrictsCount > 1) {
      worksheet.mergeCells(
        `${columnArray[columnIndex]}${currentRowIndex}:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex}`,
      );
    }
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = { formula: `=SUMPRODUCT((\
ISNA(MATCH(\
${columnArray[columnIndex]}4:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}4,\
費用設定!B2:Z2, 0)) *\
ISNA(MATCH(\
${columnArray[columnIndex]}4:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}4,\
費用設定!B3:Z3, 0))) *\
(${columnArray[columnIndex]}${currentRowIndex - 5}:\
${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex - 5}))` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    cell.border = defaultBorder;
    currentRowIndex += 1;

    if (lunchDistrictsCount + dinnerDistrictsCount > 1) {
      worksheet.mergeCells(
        `${columnArray[columnIndex]}${currentRowIndex}:${columnArray[columnIndex + lunchDistrictsCount + dinnerDistrictsCount - 1]}${currentRowIndex}`,
      );
    }
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = { formula: `=\
${columnArray[columnIndex]}${currentRowIndex - 3} * 費用設定!A2 +\
${columnArray[columnIndex]}${currentRowIndex - 2} * 費用設定!A3` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    cell.border = defaultBorder;

    columnIndex += lunchDistrictsCount + dinnerDistrictsCount;
  });

  // day total column
  {
    let currentRowIndex = rowIndex;
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = '當天總數';
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    cell.border = defaultBorder;
    currentRowIndex += 1;
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = '';
    cell.border = defaultBorder;
    currentRowIndex += 1;
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = '';
    cell.border = defaultBorder;
    currentRowIndex += 1;
    monthDates.forEach((_, dateIndex) => {
      cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex + dateIndex}`);
      let value = '';
      if (columnIndex > 1) {
        value = { formula: `=SUM(B${currentRowIndex + dateIndex}:${columnArray[columnIndex - 1]}${currentRowIndex + dateIndex})` };
      }
      cell.value = value;
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 16, name: fontName };
      cell.border = defaultBorder;
    });
    currentRowIndex += monthDates.length;
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = '';
    cell.border = defaultBorder;
    currentRowIndex += 1;
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = '';
    cell.border = defaultBorder;
    currentRowIndex += 1;
    cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
    cell.value = { formula: `=SUM(${columnArray[columnIndex]}5:${columnArray[columnIndex]}${monthDates.length + 4})` };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    cell.border = defaultBorder;
    currentRowIndex += 1;
    let count = 0;
    while (count < 4) {
      cell = worksheet.getCell(`${columnArray[columnIndex]}${currentRowIndex}`);
      let value = '';
      if (columnIndex > 1) {
        value = { formula: `=SUM(B${currentRowIndex}:${columnArray[columnIndex - 1]}${currentRowIndex})` };
      }
      cell.value = value;
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 16, name: fontName };
      cell.border = defaultBorder;
      currentRowIndex += 1;
      count++;
    }
    worksheet.getColumn(columnArray[columnIndex]).width = 13;
  }

  rowIndex += monthDates.length + 10;
  worksheet.mergeCells(`A${rowIndex}:S${rowIndex + 4}`);
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = '業務承辦：         業務主管：            主任：            董事長：';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 36, name: fontName };

  return worksheet;
}

function getWorksheetSetting(worksheet) {
  const fontName = 'Calibri';
  const range = 'A:Z';
  const columnArray = buildColumnsArray(range);
  let rowIndex = 1;

  let cell;
  cell = worksheet.getCell(`A${rowIndex}`);
  cell.value = '費用';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 16, name: fontName };
  cell = worksheet.getCell(`B${rowIndex}`);
  cell.value = '鄉鎮市區';
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.font = { size: 16, name: fontName };
  rowIndex += 1;

  worksheet.getColumn('A').width = 13;
  districtPrice.forEach(({ price, districts }) => {
    cell = worksheet.getCell(`A${rowIndex}`);
    cell.value = price;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: 16, name: fontName };
    districts.forEach((district, index) => {
      cell = worksheet.getCell(`${columnArray[index + 1]}${rowIndex}`);
      cell.value = district;
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: 16, name: fontName };
      worksheet.getColumn(columnArray[index + 1]).width = 13;
    });
    rowIndex += 1;
  });

  return worksheet;
}
